import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CustomForm from "../form";

import { rqModel, pathname } from './settings';

const ExpensesModal = ({ isOpen, toggle, _id, propertyId, refDate, refetchData }) => {
    const [loaded, setLoaded] = useState(false);
    const [success, setSuccess] = useState(false); //! TEMP

    const [expensesModalForm, setForm] = useState([
        {
            colSize: "3",
            label: "Valor",
            name: "value",
            id: "value",
            type: "number",
            required: true,
            requiredMessage: "Este campo é obrigatório",
            step: 0.01,
        },
        {
            colSize: "3",
            label: "Tipo",
            name: "tag",
            id: "tag",
            type: "select",
            multi: false,
            required: true,
            requiredMessage: "Este campo é obrigatório",
            optionLabel: "label",
            optionValue: "value",
            options: [
                {
                    value: " ",
                    label: "Despesa",
                },
                {
                    value: "refund",
                    label: "Reembolso",
                },
                {
                    value: "captivation",
                    label: "Cativação",
                },
                {
                    value: "pp",
                    label: "Pagamento Proprietário",
                },
                {
                    value: "fee",
                    label: "Fee",
                },
            ],
        },
        {
            colSize: "3",
            label: "Data da Despesa",
            name: "date",
            id: "date",
            type: "datepicker",
            showYears: true,
            required: true,
            requiredMessage: "Este campo é obrigatório",
        },
        {
            colSize: "3",
            label: "Data do Pagamento",
            name: "paymentDate",
            id: "paymentDate",
            type: "datepicker",
            showYears: true,
        },
        {
            colSize: "4",
            label: "Ticket",
            name: "ticket",
            id: "ticket",
            type: "select",
            multi: false,
            optionLabel: "subject",
            optionValue: "id",
            options: null,
            api: {
                route: "/api/v2/tickets",
                redux: null,
                type: "freshdesk",
            },
        },
        {
            colSize: "4",
            label: "Tag",
            name: "type",
            id: "type",
            type: "select",
            multi: false,
            optionLabel: "label",
            optionValue: "value",
            options: [
                {
                    value: "repair",
                    label: "Manutenção/Reparação",
                },
                {
                    value: "condominio",
                    label: "Condominio",
                },
                {
                    value: "seguro",
                    label: "Seguro",
                },
                {
                    value: "impostos",
                    label: "Impostos",
                }
            ],
            controller: [
                {
                    onSelect: "repair",
                    show: "repair",
                },
                {
                    onSelect: "condominio",
                    show: null,
                },
                {
                    onSelect: "seguro",
                    show: "insurance",
                },
                {
                    onSelect: "impostos",
                    show: null,
                },
            ],
        },
        {
            hidden: true,
            colSize: "6",
            label: "Reparação",
            name: "repair",
            id: "repair",
            type: "select",
            options: [
                {
                    _id: null,
                    name: "Nenhuma",
                },
            ],
            optionLabel: "name",
            optionValue: "_id",
        },
        {
            hidden: true,
            colSize: "6",
            label: "Seguro",
            name: "insurance",
            id: "insurance",
            type: "select",
            options: [
                {
                    _id: null,
                    insurer: "Nenhuma",
                },
            ],
            optionLabel: "insurer",
            optionValue: "_id",
        },
        {
            colSize: "6",
            label: "Cativação Associada",
            name: "related.transactionId",
            id: "related.transactionId",
            type: "select",
            multi: false,
            optionLabel: "name",
            optionValue: "_id",
            options: [
                {
                    _id: null,
                    name: "Nenhuma",
                },
            ],
            defaultValue: "null"
        },
        {
            type: "component",
            colSize: "6",
            name: "col",
            id: "col",
        },
        {
            colSize: "12",
            label: "Nome",
            name: "name",
            id: "name",
            type: "text",
            required: true,
            requiredMessage: "Este campo é obrigatório",
        },
        {
            colSize: "12",
            label: "Descrição",
            name: "description",
            id: "description",
            type: "textarea",
        },
    ]);

    useEffect(() => {
        if (isOpen && _id) {
            axios.get(`/api/transaction_sheets?contract=${_id}`)
                .then((results) => {
                    const optionsToFill = expensesModalForm.find((field) => field.name === 'related.transactionId')

                    if (optionsToFill) {
                        results.data.results.forEach((sheet) => {
                            sheet.transactions.forEach((transaction) => {
                                if (!optionsToFill.options.find((expense) => expense._id === transaction._id)) {
                                    if (transaction.tag === 'captivation') optionsToFill.options.push(transaction);
                                }
                            })
                        });
                    }


                    axios.get(`/api/properties/${propertyId}`)
                        .then((results) => {
                            let optionsToFill2 = expensesModalForm.find((field) => field.name === 'repair')
                            if (optionsToFill2) {
                                if (results.data && results.data.repairs) {
                                    results.data.repairs.forEach((repair) => {
                                        optionsToFill2.options.push(repair);
                                    });
                                } else {
                                    optionsToFill2.options = [
                                        {
                                            _id: null,
                                            name: "Nenhuma",
                                        },
                                    ]
                                }
                            }

                            let optionsToFill3 = expensesModalForm.find((field) => field.name === 'insurance')
                            if (optionsToFill3) {
                                if (results.data && results.data.insurance) {
                                    results.data.insurance.forEach((ins) => {
                                        optionsToFill3.options.push(ins);
                                    });
                                } else {
                                    optionsToFill3.options = [
                                        {
                                            _id: null,
                                            insurer: "Nenhuma",
                                        },
                                    ]
                                }
                            }

                            setLoaded(true);
                            setForm(expensesModalForm);
                        })
                        .catch((error) => {
                            setLoaded(true);
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [_id, isOpen])

    const createSuccess = () => {
        if (!success) {
            toast.success(`Despesa do Mês criada!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        if (refetchData) refetchData();
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle('expenses', _id)}>
            <ModalHeader toggle={() => toggle('expenses', _id)}>
                {"Despesas Mês"}
            </ModalHeader>
            <ModalBody>
                {loaded && (
                    <CustomForm
                        data={{ propertyId, date: refDate }}
                        toggleTable={() => toggle()}
                        schema={expensesModalForm}
                        rqModel={rqModel}
                        route={`/api/contracts/${_id}/transactions`}
                        createSuccess={createSuccess}
                        editSuccess={null}
                        pathname={pathname}
                        page={"transactionModal"}
                    />
                )}
            </ModalBody>
        </Modal>
    )
}

export default ExpensesModal