import React, { Fragment, useRef, useState } from 'react';
import ReactToPrint from "react-to-print";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Button } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import moment from 'moment';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import axios from 'axios';

import ProfileTitle from '../../../layout/profileTitle';

import BalanceRow from "../BalanceRow";
import Geral from './tabs/geral';
import Despesas from './tabs/despesas';
import Pagamentos from './tabs/pagamentos';
import Documentos from './tabs/documentos';

import { FunctionalComponentToPrint } from './componentToPrint';

import ViewTransactionModal from '../transactions/ViewTransactionModal';
import EditTransactionModal from '../transactions/EditTransanctionModal';
import PayDirectModal from '../transactions/PayDirectModal';

import ExpensesModal from '../../contracts/ExpensesModal';
import MonthlyPayModal from '../../contracts/MonthlyPayModal';
import PropertyPayModal from '../../contracts/PropertyPayModal';

import UpdateRentFee from '../UpdateRentFee';

import { useGetOne } from '../../../hooks';

registerLocale("pt", pt);

// function getMonthDifference(startDate, endDate) {
//     return (
//         endDate.getMonth() -
//         startDate.getMonth() +
//         12 * (endDate.getFullYear() - startDate.getFullYear())
//     );
// }

const BalanceProfile = (props) => {
    const history = useHistory();

    const [activeTab, setActiveTab] = useState('1');

    const { data, status, refetch } = useGetOne(`/api/contracts/${props.match.params && props.match.params.id && props.match.params.id}?moment=${localStorage.getItem('fakeMoment')}`, props.match.params && props.match.params.id && props.match.params.id, 'balances');
    const transaction_sheets = useGetOne(`/api/transaction_sheets/?contract=${props.match.params && props.match.params.id && props.match.params.id}`, props.match.params && props.match.params.id && props.match.params.id, 'transaction_sheets');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    //! REPORT
    const componentRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [reportData, setReportData] = useState(null);

    const [fileName, setFileName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const closeModal = () => {
        setModal(false);

        // Reset Form
        setFileName("");
        setStartDate(null);
        setEndDate(null);
    }

    const refetchData = () => {
        refetch();
        transaction_sheets.refetch();
    }

    const generateReport = (startDate, endDate) => {
        const start = moment(startDate).format("YYYYMM");
        const end = moment(endDate).format("YYYYMM");


        if (startDate && endDate) {
            const result = transaction_sheets.data.results.filter((element) => {
                const date = moment(element.monthAndYear)._d;
                if (startDate <= date && endDate >= date) {
                    return element;
                }
            });

            setReportData(result);
        } else if (startDate) {
            setReportData(transaction_sheets.data.results.filter((element) => element.monthAndYear === start));
        } else {
            setReportData(null);
            return null
        }
    }

    const [currentModalId2, setCurrentModalId2] = useState(null);

    const [currentModalId3, setCurrentModalId3] = useState(null);
    const [isOpenRent, setIsOpenRent] = useState(false);

    const [expensesModal, setExpensesModal] = useState(false);
    const [monthlyPayModal, setMonthlyPayModal] = useState(false);
    const [propertyPay, setPropertyPay] = useState(false);
    const [payModal, setPayModal] = useState(false);

    const [refDate, setRefDate] = useState('');

    const [currentModalId, setCurrentModalId] = useState(null);

    const [currentTransaction, setTransaction] = useState(null);
    const [currentSheet, setSheet] = useState(null);

    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);

    const [optState, setOpt] = useState(null); // listagem de pagamentos
    const [uploadId, setUploadId] = useState(null); // listagem de pagamentos

    const toggleModal = (type, _id, thisTransaction, thisSheet, opt) => {
        if (type && _id) {
            if (opt) {
                setOpt(opt);
            } else {
                setOpt(null);
            }

            if (type === 'view') {
                setViewModal(!viewModal);
            } else if (type === 'edit') {
                setEditModal(!editModal);
            } else if (type === 'upload') {
                setUploadModal(!uploadModal);
            } else if (type === 'pay') {
                setPayModal(!payModal);
            }

            setTransaction(thisTransaction);
            setSheet(thisSheet);
            setCurrentModalId(_id);
        } else {
            // After form submission
            setOpt(null);

            setViewModal(false);
            setEditModal(false);
            setUploadModal(false);
            setPayModal(false);

            setTransaction(null);
            setSheet(null);
            setCurrentModalId(null);
        }
    }

    const toggleModal2 = (type, _id, date) => {
        if (type && _id) {
            if (date) {
                const today = new Date();

                const year = date.substring(0, 4);
                const month = date.substring(4, 6);
                const day = today.getDate();

                const newDate = new Date(year, parseInt(month) - 1, day);
                setRefDate(newDate);
            }

            setCurrentModalId2(_id);

            if (type === 'expenses') {
                setExpensesModal(!expensesModal);
            } else if (type === 'monthlyPay') {
                setMonthlyPayModal(!monthlyPayModal);
                if (transaction_sheets && date) {
                    const foundSheet = transaction_sheets.data.results.find((sheet) => sheet.monthAndYear === date);
                    const foundTransactionId = foundSheet.transactions.find((transaction) => transaction.tag === "rent")._id;
                    setUploadId(foundTransactionId);
                }
            } else if (type === 'propertyPay') {
                setPropertyPay(!propertyPay);
                if (transaction_sheets && date) {
                    const foundSheet = transaction_sheets.data.results.find((sheet) => sheet.monthAndYear === date);
                    const foundTransactionId = foundSheet._id
                    setUploadId(foundTransactionId);
                }
            }
        } else {
            // After form submission

            setCurrentModalId2(_id);
            setUploadId(null);
            setRefDate('');
            setExpensesModal(false);
            setMonthlyPayModal(false);
            setPropertyPay(false);
        }
    }

    const deleteTransaction = (type, _id, thisTransaction, thisSheet) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a transação?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    let deleteUrl;

                    if (thisTransaction.type === 'repair') {
                        deleteUrl = `/api/transaction_sheets/${thisSheet._id}/transactions/${thisTransaction._id}?propertyId=${data && data.contract && data.contract.property._id}&type=${thisTransaction.type || null}&repair=${thisTransaction.repair || null}`
                    } else if (thisTransaction.type === 'seguro') {
                        deleteUrl = `/api/transaction_sheets/${thisSheet._id}/transactions/${thisTransaction._id}?propertyId=${data && data.contract && data.contract.property._id}&type=${thisTransaction.type || null}&insurance=${thisTransaction.insurance || null}`
                    } else {
                        deleteUrl = `/api/transaction_sheets/${thisSheet._id}/transactions/${thisTransaction._id}`
                    }

                    axios.delete(deleteUrl)
                        .then((result) => {
                            // const newDocuments = transaction.payments.filter((doc) => doc._id !== payment._id);
                            // transaction.payments = newDocuments;

                            refetchData();

                            toast.success("Transação apagada!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error("Ocorreu um erro ao apagar a transação", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const toggleModal3 = (_id) => {
        if (_id) {
            setIsOpenRent(!isOpenRent);
            setCurrentModalId3(_id);
        } else {
            setIsOpenRent(false);
            setCurrentModalId3(null);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <ProfileTitle route={`${process.env.PUBLIC_URL}/balancos`} title={`${data.contract.rentalNumber} - ${moment(data.sheet.monthAndYear, 'YYYYMM').format('MMMM YYYY')}`} />
                        {/* <BalanceRow contract={data.contract} sheet={data.sheet} profile /> */}
                        <div className="user-profile social-app-profile">
                            <Col md="12" className="project-list" style={{ padding: 0 }}>
                                <Card>
                                    <Row>
                                        <Col sm="12">
                                            <Nav tabs className="border-tab">
                                                <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}>{"Conta Corrente"}</NavLink></NavItem>
                                                <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}>{"Despesas"}</NavLink></NavItem>
                                                <NavItem><NavLink style={{ cursor: 'pointer' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}>{"Pagamentos"}</NavLink></NavItem>
                                                <NavItem>
                                                    <NavLink style={{ cursor: 'pointer' }} className={activeTab === "4" ? "active" : ''} onClick={() => setActiveTab("4")}>{"Documentos"}</NavLink>
                                                </NavItem>
                                                {activeTab === "1" && (
                                                    <NavItem style={{ marginLeft: 'auto' }}>
                                                        <NavLink style={{ cursor: 'pointer' }} onClick={() => setModal(true)}>{"Gerar Report"}</NavLink>
                                                    </NavItem>
                                                )}
                                                <NavItem style={{ marginLeft: activeTab !== "1" ? 'auto' : 0 }}>
                                                    <Button className="ml-1" color="primary" onClick={() => toggleModal3(props.match.params.id, data)}>Alterar renda/fee</Button>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <TabContent activeTab={activeTab} className="tab-content">
                                <TabPane tabId="1">
                                    <Geral
                                        data={data}
                                        contractId={props.match.params.id}
                                        transaction_sheets={transaction_sheets}
                                        toggleModal={toggleModal}
                                        toggleModal2={toggleModal2}
                                        deleteTransaction={deleteTransaction}
                                        refetchData={refetchData}
                                        openReport={setModal}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <Despesas
                                        data={data}
                                        contractId={props.match.params.id}
                                        transaction_sheets={transaction_sheets}
                                        toggleModal={toggleModal}
                                        deleteTransaction={deleteTransaction}
                                    />
                                </TabPane>
                                <TabPane tabId="3">
                                    <Pagamentos
                                        data={data}
                                        contractId={props.match.params.id}
                                        transaction_sheets={transaction_sheets}
                                        toggleModal={toggleModal}
                                        toggleModal2={toggleModal2}
                                        deleteTransaction={deleteTransaction}
                                    />
                                </TabPane>
                                <TabPane tabId="4">
                                    <Documentos
                                        data={data}
                                        contractId={props.match.params.id}
                                        transaction_sheets={transaction_sheets}
                                        refetchData={refetchData}
                                    />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
                <FunctionalComponentToPrint ref={componentRef} reportData={reportData} />
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Gerar Report"}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={`12 mb-2`}>
                            <Label className="col-form-label">{'Nome do Ficheiro'}</Label>
                            <Input className="form-control" type="text" onChange={(ev) => setFileName(ev.target.value)} value={fileName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={`6 mb-2`}>
                            <Label className="col-form-label">{'Data de Início'}</Label>
                            <DatePicker
                                showYearDropdown
                                locale="pt"
                                utcOffset={1}
                                autoComplete="off"
                                className="form-control digits"
                                selected={startDate !== null && startDate !== "" ? moment(startDate)._d : startDate ? startDate : ""}
                                onCalendarOpen={() => setStartDate("")}
                                onChange={(date) => {
                                    if (date) {
                                        setStartDate(date);
                                    }
                                }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </Col>
                        {startDate && (
                            <Col md={`6 mb-2`}>
                                <Label className="col-form-label">{'Data de Fim'}</Label>
                                <DatePicker
                                    showYearDropdown
                                    locale="pt"
                                    utcOffset={1}
                                    autoComplete="off"
                                    className="form-control digits"
                                    selected={endDate !== null && endDate !== "" ? moment(endDate)._d : endDate ? endDate : ""}
                                    onCalendarOpen={() => setEndDate("")}
                                    onChange={(date) => {
                                        if (date) {
                                            setEndDate(date);
                                        }
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            </Col>
                        )}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <ReactToPrint
                        documentTitle={fileName}
                        onBeforeGetContent={async () => await generateReport(startDate, endDate)}
                        trigger={() => <Button color="primary">Download PDF</Button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            setModal(false);
                            generateReport();
                        }}
                    />
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Fechar"}
                    </Button>
                </ModalFooter>
            </Modal>

            <ViewTransactionModal refetchData={refetchData} _id={currentModalId} setTransaction={setTransaction} transaction={currentTransaction} setSheet={setSheet} currentSheet={currentSheet} isOpen={viewModal} toggle={(type, _id) => toggleModal(type, _id)} opt={optState} />
            <EditTransactionModal refetchData={refetchData} _id={currentModalId} propertyId={data && data.contract && data.contract.property._id} data={{ ...currentTransaction, currentSheet }} isOpen={editModal} toggle={(type, _id) => toggleModal(type, _id)} />
            <PayDirectModal refetchData={refetchData} _id={currentModalId} data={{ ...currentTransaction, currentSheet }} isOpen={payModal} toggle={(type, _id) => toggleModal(type, _id)} />

            <ExpensesModal refetchData={refetchData} _id={currentModalId2} propertyId={data && data.contract && data.contract.property._id} isOpen={expensesModal} toggle={toggleModal2} refDate={refDate} />
            <MonthlyPayModal refetchData={refetchData} _id={currentModalId2} isOpen={monthlyPayModal} toggle={toggleModal2} refDate={refDate} uploadId={uploadId} />
            <PropertyPayModal refetchData={refetchData} _id={currentModalId2} isOpen={propertyPay} toggle={toggleModal2} refDate={refDate} uploadId={uploadId} />

            <UpdateRentFee refetchData={refetchData} _id={currentModalId3} isOpen={isOpenRent} toggle={(_id) => toggleModal3(_id)} data={data} />
        </Fragment>
    );
}

export default BalanceProfile;