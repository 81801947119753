import React, { Fragment, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import moment from 'moment';
// import SweetAlert from 'sweetalert2';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';

import DataTableExtensions from 'react-data-table-component-extensions';

// import { useDelete } from '../../hooks/';

import CustomForm from "../form";
import { getWithFilters } from "../../api/";
import { apiUrl, rqModel, pathname, schema } from "./settings";

import ExpensesModal from './ExpensesModal';
import MonthlyPayModal from './MonthlyPayModal';
import PropertyPayModal from './PropertyPayModal';

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.condoWarnDate = el.condoWarnDate ? moment(el.condoWarnDate).format('DD/MM/YYYY') : null
        el.endDate = el.endDate ? moment(el.endDate).format('DD/MM/YYYY') : null
        el.startDate = el.startDate ? moment(el.startDate).format('DD/MM/YYYY') : null

        el.feeBase = el.fees && el.fees.base ? el.fees.base.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.feeCondo = el.fees && el.fees.condo ? el.fees.base.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.feeHonor = el.fees && el.fees.honor ? el.fees.base.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.feeInitial = el.fees && el.fees.initial ? el.fees.base.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.feeRealEstate = el.fees && el.fees.realEstate ? el.fees.base.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null

        el.rentInitial = el.rent && el.rent.initial ? el.rent.initial.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.rentLiquidValue = el.rent && el.rent.liquidValue ? el.rent.liquidValue.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.rentRetention = el.rent && el.rent.retention ? el.rent.retention.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.rentValue = el.rent && el.rent.value ? el.rent.value.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null

        el.owners = el.owners && el.owners.length > 0 ? el.owners[0].name : null
        el.tenants = el.tenants && el.tenants.length > 0 ? el.tenants[0].name : null
        el.property = el.property && el.property.idPM ? el.property.idPM : null

        el.rentalNumber = el.rentalNumber.replace('#', '');
    })

    data.forEach((el) => {
        delete el.GA
        delete el.rent
        delete el.fees
        delete el.PoAPM
        delete el.contactPerson
        delete el.deadline
        delete el.documents
        delete el.housing
        delete el.numberInitialRent
        delete el.ownerAuthorization
        delete el.renewalHistory
        delete el.rentUpdates
        delete el.subLease
        delete el.updateRentFee
        delete el.guarantors
        delete el._id
        delete el.sheet
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += Math.abs(item.value);
            } else {
                result += item[key] ? item[key] : 'null';
            }

            ctr++;
        });

        result += lineDelimiter;
    });

    return result;
}

const Contracts = () => {
    const history = useHistory();

    const clients = useSelector((content) => content.User.clients);

    const [currentContract, setContract] = useState(null);
    const [viewType, setViewType] = useState(null);
    // const [contractId] = useState(null);

    const [terminated, setTerminated] = useState("false");

    const [currentModalId, setCurrentModalId] = useState(null);
    const [currentModalInfo, setCurrentModalInfo] = useState(null);
    const [expensesModal, setExpensesModal] = useState(false);
    const [monthlyPayModal, setMonthlyPayModal] = useState(false);
    const [propertyPay, setPropertyPay] = useState(false);

    // const deleteData = useDelete(apiUrl, rqModel, contractId)

    const getContracts = (terminated) => getWithFilters({ apiUrl: '/api/contracts', filters: { terminated, moment: localStorage.getItem('fakeMoment') } });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel, terminated], () => getContracts(terminated), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const toggleTable = () => {
        setViewType(null);
        setContract(null);
    }

    const createView = () => {
        setViewType("form");
        setContract(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (contract) => {
        setViewType("form");
        setContract(contract);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }


    const toggleModal = (type, _id, propertyId) => {
        if (type && _id) {
            setCurrentModalId(_id);
            setCurrentModalInfo(propertyId)
            if (type === 'expenses') {
                setExpensesModal(!expensesModal);
            } else if (type === 'monthlyPay') {
                setMonthlyPayModal(!monthlyPayModal);
            } else if (type === 'propertyPay') {
                setPropertyPay(!propertyPay);
            }
        } else {
            // After form submission

            setCurrentModalId(null);
            setExpensesModal(false);
            setMonthlyPayModal(false);
            setPropertyPay(false);
        }
    }

    const createSuccess = (data) => {
        toast.success(`Contrato ${data.rentalNumber} criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Contrato ${data.rentalNumber} editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    // const deleteRequest = async (_id) => {
    //     await setContractId(_id);
    //     SweetAlert.fire({
    //         title: 'Atenção!',
    //         text: 'Confirma que pretende apagar o Contrato?',
    //         type: 'warning',
    //         confirmButtonText: 'Sim',
    //         showCancelButton: true,
    //         cancelButtonText: 'Cancelar'
    //     })
    //         .then(async (result) => {
    //             if (result.value) {
    //                 await deleteData.mutate();
    //                 if (deleteData.isIdle) {
    //                     toast.success("Contrato apagado!", {
    //                         position: toast.POSITION.TOP_RIGHT
    //                     });
    //                 }
    //             }
    //         })
    // }

    const hasReceipt = (contract) => {
        const myTransaction = contract.sheet && contract.sheet.transactions.find((el) => el.tag === 'rent');
        if (myTransaction && myTransaction.documents.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const getIdClients = (name) => {
        const foundOwner = clients.find((el) => el.name === name);
        if (foundOwner) {
            return foundOwner._id
        } else {
            return null
        }
    }

    const columns = [
        {
            name: "Ref",
            selector: "rentalNumber",
            sortable: true,
            center: true,
        },
        {
            name: "Renda",
            selector: "sheet",
            sortable: true,
            center: true,
        },
        {
            name: "Recibo",
            selector: "receipt",
            sortable: false,
            center: true,
            cell: d => <Badge color={hasReceipt(d.receipt) ? 'primary' : 'danger'}>{hasReceipt(d.receipt) ? 'Emitido' : 'Não Emitido'}</Badge>
        },
        {
            name: "Proprietário",
            selector: "owner",
            sortable: true,
            center: true,
            cell: d => d.owner ? <a style={{ color: "#0047ab" }} href={`${process.env.PUBLIC_URL}/cliente/${getIdClients(d.owner)}`}>{d.owner}</a> : 'N/A'
        },
        {
            name: "Arrendatário",
            selector: "tenant",
            sortable: true,
            center: true,
            cell: d => <a style={{ color: "#0047ab" }} href={`${process.env.PUBLIC_URL}/cliente/${getIdClients(d.tenant)}`}>{d.tenant}</a>
        },
        {
            name: "Ações",
            selector: "action",
            sortable: false,
            center: true,
            grow: 2,
        },
    ];

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data.results);
        console.log(JSON.parse(copyArray))
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });

        if (csv == null) return;

        filename = args.filename || 'export_contracts_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <Fragment>
            <Breadcrumb title="Contratos" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                {viewType === "form" && (
                                    <CardHeader>
                                        <span>{viewType === "form" && !currentContract ? "Criar Contrato" : viewType === "form" && currentContract && "Editar Contrato"}</span>
                                    </CardHeader>
                                )}
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && (
                                                            <>
                                                                <span className="f-w-600 m-r-5">{"Estado"}</span>
                                                                <div
                                                                    style={{ marginRight: 25 }}
                                                                    className="select2-drpdwn-product select-options d-inline-block"
                                                                    onChange={(e) => {
                                                                        setTerminated(e.target.value)
                                                                    }}
                                                                >
                                                                    <select className="form-control btn-square" name="select" defaultValue={terminated}>
                                                                        <option value="">{"Todos"}</option>
                                                                        <option value="false">{"Ativos"}</option>
                                                                        <option value="true">{"Inativos"}</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        )}
                                                        {viewType !== "form" && <Button className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>}
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm
                                                data={currentContract}
                                                toggleTable={toggleTable}
                                                schema={schema}
                                                rqModel={rqModel}
                                                route={apiUrl}
                                                createSuccess={createSuccess}
                                                editSuccess={editSuccess}
                                                pathname={pathname}
                                                page="contract"
                                            />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={data && data.results.map((contract) => ({
                                                        rentalNumber: `${contract.rentalNumber}`,
                                                        sheet: `${contract.sheet && contract.sheet.paid.value >= contract.rent.liquidValue ? 'Pago' : contract.sheet && contract.sheet.paid.value > 0 && contract.sheet.paid.value < contract.rent.liquidValue ? 'Pago Parcialmente' : 'Não Pago'}`,
                                                        receipt: contract,
                                                        owner: contract.owners && contract.owners.length > 0 ? contract.owners[0].name : null,
                                                        tenant: contract.tenants && contract.tenants.length > 0 ? contract.tenants[0].name : null,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(contract)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('expenses', contract._id, contract.property._id)}><i className="fa fa-wrench"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('monthlyPay', contract._id)}><i className="fa fa-level-down"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('propertyPay', contract._id)}><i className="fa fa-level-up"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`${process.env.PUBLIC_URL}/balanco/${contract._id}`)}>Balanço</Button>
                                                            {/* <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(contract._id)}><i className="fa fa-trash"></i></Button> */}
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        noHeader
                                                        columns={columns}
                                                        defaultSortField="rentalNumber"
                                                        defaultSortAsc={true}
                                                        data={data && data.results.map((contract) => ({
                                                            rentalNumber: `${contract.rentalNumber}`,
                                                            sheet: `${contract.sheet && contract.sheet.paid.value >= contract.rent.liquidValue ? 'Pago' : contract.sheet && contract.sheet.paid.value > 0 && contract.sheet.paid.value < contract.rent.liquidValue ? 'Pago Parcialmente' : 'Não Pago'}`,
                                                            receipt: contract,
                                                            owner: contract.owners && contract.owners.length > 0 ? contract.owners[0].name : null,
                                                            tenant: contract.tenants && contract.tenants.length > 0 ? contract.tenants[0].name : null,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(contract)}><i className="fa fa-pencil"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('expenses', contract._id)}><i className="fa fa-wrench"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('monthlyPay', contract._id)}><i className="fa fa-level-down"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => toggleModal('propertyPay', contract._id)}><i className="fa fa-level-up"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => history.push(`${process.env.PUBLIC_URL}/balanco/${contract._id}`)}><i className="fa fa-exchange"></i></Button>
                                                                {/* <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(contract._id)}><i className="fa fa-trash"></i></Button> */}
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                        noDataComponent={<NoResults />}
                                                        onRowClicked={(row, event) => {
                                                            const findRowId = data.results.find((contract) => contract.rentalNumber === row.rentalNumber)
                                                            history.push(`${process.env.PUBLIC_URL}/contrato/${findRowId._id}`)
                                                        }}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
                <ExpensesModal _id={currentModalId} propertyId={currentModalInfo} isOpen={expensesModal} toggle={toggleModal} />
                <MonthlyPayModal _id={currentModalId} isOpen={monthlyPayModal} toggle={toggleModal} />
                <PropertyPayModal _id={currentModalId} isOpen={propertyPay} toggle={toggleModal} />
            </Container>
        </Fragment>
    )
}

export default Contracts