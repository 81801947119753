import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CustomForm from "../form";

import { finishContractForm, rqModel, pathname } from './settings';

const FinishContract = ({ isOpen, toggle, _id }) => {
    const [success, setSuccess] = useState(false); //! TEMP

    const createSuccess = () => {
        if (!success) {
            toast.success(`Contrato Terminado!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);

            window.location.reload();
        }


        // if (refetchData) refetchData();
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {"Terminar Contrato"}
            </ModalHeader>
            <ModalBody>
                <CustomForm
                    toggleTable={toggle}
                    schema={finishContractForm}
                    rqModel={rqModel}
                    route={`/api/contracts/${_id}/cease`}
                    createSuccess={createSuccess}
                    editSuccess={null}
                    pathname={pathname}
                    page={"finishContractModal"}
                />
            </ModalBody>
        </Modal>
    )
}

export default FinishContract