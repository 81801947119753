import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Col, Button } from 'reactstrap';

import CustomForm from "../form";

import Filepond from "../filepond";

import { propertyPayForm, rqModel, pathname } from './settings';

const PropertyPayModal = ({ isOpen, toggle, _id, refDate, refetchData, uploadId }) => {
    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const [success, setSuccess] = useState(false); //! TEMP

    const createSuccess = () => {
        if (!success) {
            toast.success(`Pagamento ao Proprietário criado!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        if (refetchData) refetchData();
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/transaction_sheets/${uploadId}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    refetchData();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle('propertyPay', _id)}>
            <ModalHeader toggle={() => toggle('propertyPay', _id)}>
                {"Pagamento ao Proprietário"}
            </ModalHeader>
            <ModalBody>
                <CustomForm
                    data={{ dateMonthRef: refDate }}
                    toggleTable={toggle}
                    schema={propertyPayForm}
                    rqModel={rqModel}
                    route={`/api/contracts/${_id}/proprietary_payments`}
                    createSuccess={createSuccess}
                    editSuccess={null}
                    pathname={pathname}
                    page={"transactionModal"}
                />
                {uploadId && (
                    <>

                        <hr />
                        <Col sm="12" className="mt-4">
                            <h5>{'Upload de Ficheiros'}</h5>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </>
                )}
            </ModalBody>
        </Modal>
    )
}

export default PropertyPayModal