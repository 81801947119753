import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Col, Button } from 'reactstrap';
import moment from 'moment';

import CustomForm from "../../form";

import Filepond from "../../filepond";

import { rqModel, pathname } from '../../contracts/settings';

const expensesModalForm = [
    {
        colSize: "3",
        label: "Data do Pagamento",
        name: "paymentDate",
        id: "paymentDate",
        type: "datepicker",
        showYears: true,
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "12",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "description",
        id: "description",
        type: "textarea",
    },
]

const PayDirectModal = ({ isOpen, toggle, _id, data, refetchData }) => {
    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const [loaded, setLoaded] = useState(false);
    const [success, setSuccess] = useState(false); //! TEMP

    useEffect(() => {
        if (isOpen && _id) {
            axios.get(`/api/transaction_sheets?contract=${_id}`)
                .then((results) => {
                    const optionsToFill = expensesModalForm.find((field) => field.name === 'related.transactionId')
                    results.data.results.forEach((sheet) => {
                        sheet.transactions.forEach((transaction) => {
                            if (!optionsToFill.options.find((expense) => expense._id === transaction._id)) {
                                if (transaction.tag === 'captivation') optionsToFill.options.push(transaction);
                            }
                        })
                    });
                    setLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [_id, isOpen])

    const editSuccess = (data) => {
        if (!success) {
            toast.success(`Transação editada!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        toggle();
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/transactions/${data._id}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    refetchData();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return data ? (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle('pay', _id)}>
            <ModalHeader toggle={() => toggle('pay', _id)}>
                {data.name} {data && data.value && Math.abs(data.value).toFixed(2)} € || {moment(data.date).format('MMMM YYYY')}
            </ModalHeader>
            <ModalBody>
                {loaded && (
                    <CustomForm data={{ ...data, value: Math.abs(data.value) }} toggleTable={toggle} refetchData={refetchData} schema={expensesModalForm} rqModel={rqModel} route={data && data.currentSheet ? `/api/transaction_sheets/${data.currentSheet._id}/transactions` : null} createSuccess={null} editSuccess={editSuccess} pathname={pathname} />
                )}
                <hr />
                <Col sm="12" className="mt-4">
                    <h5>{'Upload de Ficheiros'}</h5>
                    <Filepond
                        name={"mass_upload"}
                        label={"Carregar Ficheiro"}
                        multi={false}
                        files={files}
                        setFiles={setCustomFiles}
                    />
                    {files && (
                        <>
                            <Button color="primary" onClick={uploadDocument}>
                                {fileLoading ? (
                                    <i className="fa fa-spin fa-spinner" />
                                ) : fileError ? (
                                    fileError
                                ) : (
                                    "Upload"
                                )}
                            </Button>
                        </>
                    )}
                </Col>
            </ModalBody>
        </Modal>
    ) : null
}

export default PayDirectModal