import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import reducers from '../redux/index';

const sagaMiddleware = createSagaMiddleware()
const store = createStore(reducers,
    compose(
        applyMiddleware(sagaMiddleware),
        window.devToolsExtension
            ? window.devToolsExtension()
            : function (f) {
                return f;
            }
    ))


export default store;