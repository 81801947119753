import React, { Fragment } from "react";
import { components } from "react-select";
import { Button } from 'reactstrap';

export const Menu = (props) => {
    return (
        <Fragment>
            <components.Menu {...props}>
                <div>
                    <Button color="primary" size="sm" type="button" onClick={props.selectProps.toggleClientModal} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 10 }}>
                        Criar Novo
                    </Button>
                    <div>{props.children}</div>
                </div>
            </components.Menu>
        </Fragment >
    );
};