import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';

import Geral from './tabs/geral';
import Documents from './tabs/documents';
import Seguro from './tabs/seguro';
import Repairs from './tabs/repairs';
import Transactions from './tabs/transactions';

// import Notes from './tabs/notes';
// import Others from './tabs/others';

import ProfileTitle from '../../../layout/profileTitle';

import { useGetOne } from '../../../hooks';
import axios from 'axios';

const PropertyProfile = (props) => {
    const history = useHistory();

    const { data, refetch, status } = useGetOne(`/api/properties/${props.match.params && props.match.params.id && props.match.params.id}`, props.match.params && props.match.params.id && props.match.params.id, 'properties');
    const [transaction_sheets, setTS2] = useState(null);

    useEffect(() => {
        if (!transaction_sheets && data && data.contracts && data.contracts.length > 0) {
            const activeContracts = data.contracts.find((el) => el.active);
            if (activeContracts)
            axios.get(`/api/transaction_sheets/?contract=${activeContracts.contractId}`)
                .then((result) => {
                    setTS2(result.data)
                })
                .catch((error) => {
                    setTS2('error')
                })
        }
    }, [data, transaction_sheets])

    const [transactionSheets, setTS] = useState(null);

    useEffect(() => {
        if (transaction_sheets && transaction_sheets.results && !transactionSheets) {
            let newTS = [];
            transaction_sheets.results.forEach((el) => {
                newTS = [...newTS, ...el.transactions]
            });

            setTS(newTS);
        }

    }, [transaction_sheets, transactionSheets])

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [activeTab, setActiveTab] = useState('geral');

    useEffect(() => {
        if (!history.location.search && history.location.search !== '') {
            const urlParams = new URLSearchParams(history.location.search);
            if (urlParams.get('tab')) setActiveTab(urlParams.get('tab'));
        } else {
            const windowParams = new URLSearchParams(window.location.search);
            if (windowParams.get('tab')) setActiveTab(windowParams.get('tab'));
        }
    }, [history.location.search])

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <ProfileTitle route={history.location.state && history.location.state.fromUrl ? `${process.env.PUBLIC_URL}${history.location.state.fromUrl}` : `${process.env.PUBLIC_URL}/patrimonio`} title={data.pmName} />
                        <Container fluid={true}>
                            <div className="user-profile social-app-profile">
                                <Col md="12" className="project-list" style={{ padding: 0 }}>
                                    <Card>
                                        <Row>
                                            <Col sm="12">
                                                <Nav tabs className="border-tab">
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'geral' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=geral`)
                                                                setActiveTab('geral')
                                                            }}
                                                        >
                                                            {"Geral"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'documentos' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=documentos`)
                                                                setActiveTab('documentos')
                                                            }}
                                                        >
                                                            {"Documentos"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'seguro' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=seguro`)
                                                                setActiveTab('seguro')
                                                            }}
                                                        >
                                                            {"Seguro"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'manutencao' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=manutencao`)
                                                                setActiveTab('manutencao')
                                                            }}
                                                        >
                                                            {"Manutenção / Reparação"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'condominio' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=condominio`)
                                                                setActiveTab('condominio')
                                                            }}
                                                        >
                                                            {"Condominio"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'impostos' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/propriedade/${props.match.params.id}?tab=impostos`)
                                                                setActiveTab('impostos')
                                                            }}
                                                        >
                                                            {"Impostos"}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <TabContent activeTab={activeTab} className="tab-content">
                                    <TabPane tabId="geral">
                                        <Geral data={data} propertyId={props.match.params.id} />
                                    </TabPane>
                                    <TabPane tabId="documentos">
                                        <Documents data={data} refetch={refetch} propertyId={props.match.params.id} />
                                    </TabPane>
                                    <TabPane tabId="seguro">
                                        <Seguro data={data} refetch={refetch} propertyId={props.match.params.id} />
                                    </TabPane>
                                    <TabPane tabId="manutencao">
                                        <Repairs data={data} refetch={refetch} propertyId={props.match.params.id} />
                                    </TabPane>
                                    {data && transactionSheets && (
                                        <>
                                            <TabPane tabId="condominio">
                                                <Transactions data={{ contract: data.contract, sheet: data.sheet, list: transactionSheets.filter((transaction) => transaction.type === 'condominio') }} activeTab={activeTab} />
                                            </TabPane>
                                            <TabPane tabId="impostos">
                                                <Transactions data={{ contract: data.contract, sheet: data.sheet, list: transactionSheets.filter((transaction) => transaction.type === 'impostos') }} activeTab={activeTab} />
                                            </TabPane>
                                        </>
                                    )}
                                </TabContent>
                            </div>
                        </Container>
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )
                }
            </Container >
        </Fragment >
    );
}

export default PropertyProfile;