import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Col, CardBody, Form } from 'reactstrap';
import moment from 'moment';

const Imoveis = ({ data }) => {
    return data ? (
        <Fragment>
            {data.properties.map((property) => (
                <Form
                    className="card theme-form needs-validation"
                    noValidate=""
                >
                    <CardBody>
                        <div key={property.pmName} className="list-persons row">
                            {/* <Link className="col-4 bg-img" style={{ backgroundImage: '../assets/img/gallery/thumb/8.jpg' }} to="/propriedade/<%= thisProperty._id%>"></Link> */}
                            <Col xl="5">
                                <div className="profile-mail" style={{ padding: 0 }}>
                                    <div className="email-general" style={{ padding: 0 }}>
                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/propriedade/${property._id}`,
                                            state: { fromUrl: `/cliente/${data._id}?tab=imoveis` }
                                        }}>
                                            <h6 className="mb-3">Proprietário De {property.pmName}</h6>
                                        </Link>
                                        <ul>
                                            <li>Morada <span className="font-primary">{property.address}</span></li>
                                            {/* <li>Fracção <span className="font-primary">{property.fractionOrLetter}</span></li> */}
                                            <li>Código Postal <span className="font-primary">{property.postalCode}</span></li>
                                            <li>Distrito <span className="font-primary">{property.district}</span></li>
                                            {/* <li>País <span className="font-primary">{property.country}</span></li> */}
                                            {/* <li>Composição <span className="font-primary">{property.composition}</span></li> */}
                                            <li>Topologia <span className="font-primary">{property.typology}</span></li>
                                            {property.state.forRent ? (
                                                <li className="font-primary">{'Disponível'}</li>
                                            ) : (
                                                <li className="font-danger">{'Indisponível'}</li>
                                            )}
                                            <hr />
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </CardBody>
                </Form>
            ))}
            {data.roles.map((thisRole) => thisRole.role === 'owner' ? (
                <Form
                    key={thisRole.contract.property._id}
                    className="card theme-form needs-validation"
                    noValidate=""
                >
                    <CardBody>
                        <div className="list-persons row">
                            {/* <Link className="col-4 bg-img" style={{ backgroundImage: '../assets/img/gallery/thumb/8.jpg' }} to="/propriedade/<%= thisProperty._id%>"></Link> */}
                            <Col xl="5">
                                <div className="profile-mail" style={{ padding: 0 }}>
                                    <div className="email-general" style={{ padding: 0 }}>
                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/propriedade/${thisRole.contract.property._id}`,
                                            state: { fromUrl: `/cliente/${data._id}?tab=imoveis` }
                                        }}>
                                            <h6 className="mb-3">Proprietário De {thisRole.contract.property.pmName}</h6>
                                        </Link>
                                        <ul>
                                            <li>Morada <span className="font-primary">{thisRole.contract.property.address}</span></li>
                                            {/* <li>Fracção <span className="font-primary">{thisRole.contract.property.fractionOrLetter}</span></li> */}
                                            <li>Código Postal <span className="font-primary">{thisRole.contract.property.postalCode}</span></li>
                                            <li>Distrito <span className="font-primary">{thisRole.contract.property.district}</span></li>
                                            {/* <li>País <span className="font-primary">{thisRole.contract.property.country}</span></li> */}
                                            {/* <li>Composição <span className="font-primary">{thisRole.contract.property.composition}</span></li> */}
                                            <li>Topologia <span className="font-primary">{thisRole.contract.property.typology}</span></li>
                                            {thisRole.contract.property.state.forRent ? (
                                                <li className="font-success">{'Disponível'}</li>
                                            ) : (
                                                <li className="font-danger">{'Indisponível'}</li>
                                            )}
                                            <hr />
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <div className="list-persons row">
                            {!thisRole.contract.property.state.forRent && thisRole.contract.tenants && thisRole.contract.tenants[0] && (
                                <Col xl="5">
                                    <div className="profile-mail" style={{ padding: 0 }}>
                                        <div className="email-general" style={{ padding: 0 }}>
                                            <Link to={`${process.env.PUBLIC_URL}/cliente/${thisRole.contract.tenants[0]._id}`}><h6 className="mb-3">Arrendado a {thisRole.contract.tenants[0].name}</h6></Link>
                                            <ul>
                                                <li>Naturalidade <span className="font-primary">{thisRole.contract.tenants[0].placeOfBirth} a {moment(thisRole.contract.tenants[0].birthDate).format('DD/MM/YYYY')}</span></li>
                                                <li>NIF <span className="font-primary">{thisRole.contract.tenants[0].NIF}</span></li>
                                                <li>NIPC <span className="font-primary">{thisRole.contract.tenants[0].NIPC}</span></li>
                                                <hr />
                                                <li>Email <span className="font-primary">{thisRole.contract.tenants[0].contacts.email}</span></li>
                                                <li>Outro <span className="font-primary">{thisRole.contract.tenants[0].contacts.other}</span></li>
                                                <li>Telefone <span className="font-primary">{thisRole.contract.tenants[0].contacts.phone}</span></li>
                                                {thisRole.contract.property.state.availability.forRent ? (
                                                    <li className="font-success">{'Disponível'}</li>
                                                ) : (
                                                    <li className="font-danger">{'Indisponível'}</li>
                                                )}
                                                <hr />
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            )}
                            <Col xl="5">
                                <div className="profile-mail" style={{ padding: 0 }}>
                                    <div className="email-general" style={{ padding: 0 }}>
                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/contrato/${thisRole.contract._id}`,
                                            state: { fromUrl: `/cliente/${data._id}?tab=imoveis` }
                                        }}>
                                            <h6 className="mb-3">Contrato - {thisRole.contract.rentalNumber}</h6>
                                        </Link>
                                        <ul>
                                            {thisRole.contract.ATRegister && thisRole.contract.ATRegister.registered && (
                                                <li>Registo AT <span className="font-primary">{thisRole.contract.ATRegister.value} em {moment(thisRole.contract.ATRegister.date).format('DD/MM/YYYY')}</span></li>
                                            )}
                                            <li>Renda {thisRole.contract.rent.periodicity === 'monthly' && '( Mensal )'}</li>
                                            <li>Bruto <span className="font-primary">{(thisRole.contract.rent.value).toFixed(2)} € {thisRole.contract.rent.retention && '( Retenção Fonte )'}</span></li>
                                            <li>Líquido <span className="font-primary">{(thisRole.contract.rent.liquidValue).toFixed(2)} €</span></li>
                                            <hr />
                                            <li>Inicio a <span className="font-primary">{moment(thisRole.contract.startDate).format('DD/MM/YYYY')}</span></li>
                                            <li>Termina a <span className="font-primary">{moment(thisRole.contract.endDate).format('DD/MM/YYYY')}</span></li>
                                            <li>Vai Renovar <span className="font-primary">{thisRole.contract.renewable ? 'Sim' : 'Não'}</span></li>
                                            <li></li>
                                            <li></li>
                                            <hr />
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </CardBody>
                </Form>
            ) : thisRole.role === 'tenant' && (
                <Form
                    key={thisRole.contract.property._id}
                    className="card theme-form needs-validation"
                    noValidate=""
                >
                    <CardBody>
                        <div className="list-persons row">
                            {/* <Link className="col-4 bg-img" style="background-image: url(../assets/img/gallery/thumb/8.jpg)" to="/propriedade/<%= thisProperty._id%>"></Link> */}
                            <Col xl="5">
                                <div className="profile-mail" style={{ padding: 0 }}>
                                    <div className="email-general" style={{ padding: 0 }}>

                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/propriedade/${thisRole.contract.property._id}`,
                                            state: { fromUrl: `/cliente/${data._id}?tab=imoveis` }
                                        }}>
                                            <h6 className="mb-3">Arrendatário De {thisRole.contract.property.pmName}</h6>
                                        </Link>
                                        <ul>
                                            <li>Morada <span className="font-primary">{thisRole.contract.property.address}</span></li>
                                            {/* <li>Fracção <span className="font-primary">{thisRole.contract.property.fractionOrLetter}</span></li> */}
                                            <li>Código Postal <span className="font-primary">{thisRole.contract.property.postalCode}</span></li>
                                            <li>Distrito <span className="font-primary">{thisRole.contract.property.district}</span></li>
                                            {/* <li>País <span className="font-primary">{thisRole.contract.property.country}</span></li> */}
                                            {/* <li>Composição <span className="font-primary">{thisRole.contract.property.composition}</span></li> */}
                                            <li>Topologia <span className="font-primary">{thisRole.contract.property.typology}</span></li>
                                            {thisRole.contract.property.state.forRent ? (
                                                <li className="font-success">{'Disponível'}</li>
                                            ) : (
                                                <li className="font-danger">{'Indisponível'}</li>
                                            )}
                                            <hr />
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="5">
                                <div className="profile-mail" style={{ padding: 0 }}>
                                    <div className="email-general" style={{ padding: 0 }}>
                                        <Link to={{
                                            pathname: `${process.env.PUBLIC_URL}/contrato/${thisRole.contract._id}`,
                                            state: { fromUrl: `/cliente/${data._id}?tab=imoveis` }
                                        }}>
                                            <h6 className="mb-3">Contrato - {thisRole.contract.rentalNumber}</h6>
                                        </Link>
                                        <ul>
                                            {thisRole.contract.ATRegister && thisRole.contract.ATRegister.registered && (
                                                <li>Registo AT <span className="font-primary">{thisRole.contract.ATRegister.value} em {moment(thisRole.contract.ATRegister.date).format('DD/MM/YYYY')}</span></li>
                                            )}
                                            <li>Renda {thisRole.contract.rent.periodicity === 'monthly' && '( Mensal )'}</li>
                                            <li>Bruto <span className="font-primary">{(thisRole.contract.rent.value).toFixed(2)} € {thisRole.contract.rent.retention && '( Retenção Fonte )'}</span></li>
                                            <li>Líquido <span className="font-primary">{(thisRole.contract.rent.liquidValue).toFixed(2)} €</span></li>
                                            <hr />
                                            <li>Inicio a <span className="font-primary">{moment(thisRole.contract.startDate).format('DD/MM/YYYY')}</span></li>
                                            <li>Termina a <span className="font-primary">{moment(thisRole.contract.endDate).format('DD/MM/YYYY')}</span></li>
                                            <li>Vai Renovar <span className="font-primary">{thisRole.contract.renewable ? 'Sim' : 'Não'}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </CardBody>
                </Form>
            ))}
        </Fragment>
    ) : null;
};

export default Imoveis;