import React, { Fragment } from "react";
import { Col, CardBody, Form } from 'reactstrap';
import moment from 'moment';

const affectation = [
    {
        value: "other",
        label: "Sem Informação",
    },
    {
        value: "housing",
        label: "Habitação",
    },
    {
        value: "services",
        label: "Serviços",
    },
    {
        value: "commerce",
        label: "Comércio",
    },
    {
        value: "agricultural",
        label: "Agricultura",
    },
]

const typology = [
    {
        value: "urban",
        label: "Urbano",
    },
    {
        value: "rustic",
        label: "Rústico",
    },
]

const Geral = ({ data }) => {
    return data ? (
        <Fragment>
            <Form
                className="card theme-form needs-validation"
                noValidate=""
            >
                <CardBody>
                    <div className="list-persons row">
                        <Col xl="6" style={{ marginBottom: 35 }}>
                            <div className="profile-mail" style={{ padding: 0 }}>
                                <div className="email-general" style={{ padding: 0 }}>
                                    <h6 className="mb-3">{'Informação'}</h6>
                                    <ul>
                                        <li>{'Morada'} <span className="font-primary">{data.address}</span></li>
                                        <li>{'Código Postal'} <span className="font-primary">{data.postalCode}</span></li>
                                        <li>{'Distrito'} <span className="font-primary">{data.district}</span></li>
                                        <li>{'Localidade'} <span className="font-primary">{data.city}</span></li>
                                        <li>{'Concelho'} <span className="font-primary">{data.county}</span></li>
                                        <li>{'Topologia'} <span className="font-primary">{data.typology && typology.find((el) => el.value === data.typology).label}</span></li>
                                        <li>{'Afectação'} <span className="font-primary">{data.affectation && affectation.find((el) => el.value === data.affectation).label}</span></li>
                                        <li>{'Incrição Predial'} <span className="font-primary">{data.propertyRegistry.number}</span></li>
                                        <li>{'Conservatória'} <span className="font-primary">{data.propertyRegistry.conservatory}</span></li>
                                        <li>{'Freguesia'} <span className="font-primary">{data.propertyRegistry.parish}</span></li>
                                        <li>{'Incrição Matricial'} <span className="font-primary">{data.matrixRegistration.parish} {data.matrixRegistration.number}</span></li>
                                        <li>{'Licença de Utilização'} <span className="font-primary">{data.licenseOfUse.description}</span></li>
                                        <li>{'Emitido Por'} <span className="font-primary">{data.licenseOfUse.issuedBy}</span></li>
                                        <li>{'Emitido em'} <span className="font-primary">{data.licenseOfUse?.emissionDate ? moment(data.licenseOfUse.emissionDate).format("DD/MM/YYYY") : "Não Disponível"}</span></li>
                                        <li>{'Certificado Energético'} <span className="font-primary">{data.energyCert.description}</span></li>
                                        <li>{'Válido até'} <span className="font-primary">{data.energyCert.validity ? moment(data.energyCert.validity).format("DD/MM/YYYY") : "Não Disponível"}</span></li>
                                        {data.state.availability.available ? (
                                            <li className="font-primary">{'Disponível'}</li>
                                        ) : (
                                            <li className="font-danger">{'Indisponível'}</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl="6" style={{ marginBottom: 35 }}>
                            <div className="profile-mail" style={{ padding: 0 }}>
                                <div className="email-general" style={{ padding: 0 }}>
                                    <h6 className="mb-3">Condomínio</h6>
                                    <ul>
                                        <li>Válido <span className="font-primary">{data.condominium.hasCondo ? 'Sim' : 'Não'}</span></li>
                                        {data.condominium.hasCondo && (
                                            <>
                                                <li>Valor Mensal <span className="font-primary">{data.condominium.monthlyValue.toFixed(2)} €</span></li>
                                                <li>IBAN <span className="font-primary">{data.condominium.IBAN}</span></li>
                                            </>
                                        )}

                                        <li>Observações <span className="font-primary">{data.condominium.obs}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </div>
                </CardBody>
            </Form>
        </Fragment>
    ) : null;
};

export default Geral;