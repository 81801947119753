import React, { Fragment, useState } from "react";
import { toast } from 'react-toastify';
import { Col, Row, CardHeader, Card, Table, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody, CardFooter } from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import Filepond from "../../../filepond";
import NoResults from "../../../ui-kits/noResults";

const Documents = ({ data, refetch, propertyId }) => {
    const [dropdownActive, setDropdown] = useState(null);

    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const deleteRequest = (file) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value && file && file._id) {
                    axios.delete(`/api/uploads/properties/${propertyId}/${file._id}`)
                        .then((result) => {
                            // const newDocuments = data.documents.filter((doc) => doc._id !== file._id);
                            // data.documents = newDocuments;

                            refetch();

                            toast.success("Documento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error("Ocorreu um erro ao apagar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const associateFile = (file, classification) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende associar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.post(`/api/properties/${propertyId}/documents/${file._id}`, { classification: classification })
                        .then((result) => {
                            // const newDocument = data.documents.find((doc) => doc._id === file._id);
                            // newDocument.classification = classification;

                            refetch();

                            toast.success("Documento associado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error("Ocorreu um erro ao associar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/properties/${propertyId}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    refetch();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                    setCustomFiles(null);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return data ? (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <h5>{'Documentos da propriedade'}</h5>
                        </CardHeader>
                        <div className="table-responsive" style={{ minHeight: 275 }}>
                            {data.documents.length > 0 ? (
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Uploaded"}</th>
                                            <th scope="col">{"Name"}</th>
                                            <th scope="col">{"Type"}</th>
                                            <th scope="col">{"Extension"}</th>
                                            <th scope="col">{"Options"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.documents.map((file) => (
                                            <tr key={file._id}>
                                                <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                <td>{file.origName}</td>
                                                <td>{file.classification}</td>
                                                <td>{file.origName.split('.').pop()}</td>
                                                <td>
                                                    <ButtonDropdown
                                                        isOpen={dropdownActive === file._id}
                                                        toggle={() => dropdownActive ? setDropdown(null) : setDropdown(file._id)}
                                                    >
                                                        <DropdownToggle caret color="primary" size="xs">
                                                            Associar
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => associateFile(file, 'front')}>
                                                                Imagem Fachada
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => associateFile(file, 'propertyRegistration')}>
                                                                Registo
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => associateFile(file, 'policy')}>
                                                                Seguro
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => associateFile(file, 'buildingPermit')}>
                                                                Licença
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => associateFile(file, 'energyCert')}>
                                                                Certificado Energético
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => associateFile(file, 'dataSheet')}>
                                                                Ficha Técnica
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file)}><i className="fa fa-trash"></i></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <NoResults />
                            )}
                        </div>
                    </Card>
                </Col>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <h5>{'Upload de Ficheiros'}</h5>
                        </CardHeader>
                        <CardBody>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                        </CardBody>
                        <CardFooter>
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                    <Button className="ml-2" color="secondary" onClick={() => setCustomFiles([])}>
                                        {"Cancelar"}
                                    </Button>
                                </>
                            )}
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Fragment >
    ) : null;
};

export default Documents;