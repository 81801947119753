import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import CustomForm from "../../form";

import Filepond from "../../filepond";

import { seguroModalForm, rqModel, pathname } from './settings';

const SeguroModal = ({ isOpen, toggle, toggleAfterCreate, _id, refetchData, data, propertyId, setModalData }) => {
    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const [success, setSuccess] = useState(false); //! TEMP

    const createSuccess = () => {
        if (!success) {
            toast.success(`Seguro criado!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        window.location.reload();
        // if (refetchData) refetchData();
    }

    const editSuccess = () => {
        if (!success) {
            toast.success(`Seguro editado!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        window.location.reload();
        // if (refetchData) refetchData();
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/insurance/${propertyId}/${data._id}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    const newSeguro = { ...data, documents: [...data.documents, result.data[0]] }
                    setModalData(newSeguro);

                    refetchData();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    setFileLoading(false);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                {data && data._id ? "Editar Seguro" : "Adicionar Seguro"}
            </ModalHeader>
            <ModalBody>
                <CustomForm
                    data={data}
                    toggleTable={() => toggleAfterCreate()}
                    schema={seguroModalForm}
                    rqModel={rqModel}
                    route={`/api/properties/${_id}/insurance`}
                    createSuccess={createSuccess}
                    editSuccess={editSuccess}
                    pathname={pathname}
                    page={"seguroModal"}
                />
                {data && (
                    <>

                        <hr />
                        <Col sm="12" className="mt-4">
                            <h5>{'Upload de Ficheiros'}</h5>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </>
                )}
            </ModalBody>
        </Modal>
    )
}

export default SeguroModal