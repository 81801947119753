import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { Container, Row, Col, Form, Label, Input, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

// import { useQueryClient } from 'react-query';

import { FroalaEditor } from "./froala";

import TimePickerTwo from './forms/form-widget/timepickerComponent/timepicker-two';
import Filepond from "./filepond/index";

import { Submit, Cancel } from "../constant";

import { usePost, usePut } from "../hooks/";

registerLocale("pt", pt);

const FormDefault = ({ data, toggleTable, route, rqModel, schema, createSuccess, editSuccess, pathname, page, refetchData }) => {
    const tempSchema = JSON.parse(JSON.stringify(schema));

    // const queryClient = useQueryClient(); //! RQ

    const history = useHistory();

    const { register, handleSubmit, setValue, getValues, control, errors, clearErrors, reset } = useForm({
        defaultValues: {
            ...data
        },
    });

    const [loading, setLoading] = useState(true);
    const [stateSchema, setStateSchema] = useState(tempSchema);
    const [submitValues, setSubmitValues] = useState({});
    const [files, setFiles] = useState(null);

    const [disabledDatepicker, setDisabledDatepicker] = useState(false);

    const createData = usePost(route, rqModel, submitValues);
    const saveData = usePut(route, rqModel, submitValues);

    const closeForm = () => {
        toggleTable();
        setStateSchema(schema);
    }

    const setCustomFiles = (output, segments, objMixByPharma, qtObjFixed) => {
        if (segments) { // PUSH FORM
            if (objMixByPharma) { // EXTRA PUSH FORM
                register('objMixByPharma');
                setValue('objMixByPharma', objMixByPharma);
            }
            if (qtObjFixed) {
                register('qtObjFixedByPharma');
                setValue('qtObjFixedByPharma', qtObjFixed);
            }
            register('segments');
            setValue('segments', segments);
        } else {
            setFiles(output) //* FILEPOND
        }
    }

    useSelector(content => {
        const reduxRequests = stateSchema.filter(field => field.api && field.api.redux);
        reduxRequests.forEach((model) => {
            if (!model.options) {
                if (model.api && model.api.redux) {
                    //! REDUX
                    model.options = content[model.api.redux].results;
                    //! REDUX

                    //! RQ
                    // model.options = queryClient.getQueryData(model.api.redux)
                    //! RQ
                }
            }
        });
    });

    const controlFields = () => {
        const controller = stateSchema.find(field => field.controller);

        if (controller) {
            const fieldsToShow = controller.controller.find((ctrl) => {
                const splittedName = controller.name.split(".")
                if (splittedName.length > 2) {
                    return ctrl.onSelect === data[splittedName[0]][splittedName[1]][splittedName[2]]
                } else if (splittedName.length > 1) {
                    return ctrl.onSelect === data[splittedName[0]][splittedName[1]]
                }
                return ctrl.onSelect === data[controller.name]
            });

            let tempSchema = [...stateSchema]; // Create Copy
            const hiddenFields = tempSchema.filter((hiddenFields) => hiddenFields.hidden === true);
            const shownFields = tempSchema.filter((showingFields) => showingFields.hidden === false);

            hiddenFields.forEach((showField) => {
                if (fieldsToShow && fieldsToShow.show) {
                    fieldsToShow.show.split("-").forEach((ctrl) => {
                        if (showField.id === ctrl) {
                            showField.hidden = false;
                        }
                    });
                }
            });
            shownFields.forEach((showField) => {
                fieldsToShow.show.split("-").forEach((ctrl) => {
                    if (showField.id !== ctrl && typeof showField.hidden !== 'undefined') {
                        showField.hidden = true;
                    }
                });
            });

            if (page === 'contract') {
                setTimeout(() => {
                    setStateSchema(tempSchema);
                    setLoading(false);
                }, 2500);
            } else {
                setTimeout(() => {
                    setStateSchema(tempSchema);
                    setLoading(false);
                }, 1250);
            }
        } else {
            if (page === 'contract') {
                setTimeout(() => {
                    let tempSchema = [...stateSchema]; // Create Copy
                    setStateSchema(tempSchema);
                    setLoading(false);
                }, 2500);
            } else {
                setTimeout(() => {
                    let tempSchema = [...stateSchema]; // Create Copy
                    setStateSchema(tempSchema);
                    setLoading(false);
                }, 1250);
            }
        }
    }

    useEffect(() => {
        const tempSchema = [...stateSchema]; // Copy stateSchema
        async function fetchData() {
            const apiRequests = tempSchema.filter(field => field.api);
            await apiRequests.forEach(async (model) => {
                if (!model.options) {
                    if (model.api && model.api.route && model.api.type !== 'freshdesk') {
                        await axios.get(`${data && model.api.editRoute ? model.api.editRoute : model.api.route}`)
                            .then((result) => {
                                if (result.data && result.data.results && Array.isArray(result.data.results)) {
                                    model.options = result.data.results;
                                } else if (result.data && result.data.docs && Array.isArray(result.data.docs)) {
                                    model.options = result.data.docs;
                                } else if (result.data && Array.isArray(result.data)) {
                                    model.options = result.data;
                                } else {
                                    model.options = [];
                                }
                            })
                            .catch((error) => {
                                model.options = [];
                            });
                    } else if (model.api.type === 'freshdesk') {
                        //! FRESHDESK
                        axios.all([
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=1`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=2`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=3`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=4`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=5`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                            axios.get(`https://rede360.freshdesk.com/api/v2/search/tickets?query="status:3 OR status:4"&page=6`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } }),
                        ])
                            .then(axios.spread((data1, data2, data3, data4, data5, data6) => {
                                model.options = [...data1.data.results, ...data2.data.results, ...data3.data.results, ...data4.data.results, ...data5.data.results, ...data6.data.results];
                            })).catch((error) => {
                                model.options = [];

                                console.error(error);
                            });
                    }
                }
            });
        }
        //! TEMP
        if (loading) {
            fetchData();
            if (data) {
                controlFields();
            } else {
                setStateSchema(tempSchema);
                setTimeout(() => {
                    setLoading(false);
                }, 1250);
            }
        }
        //! TEMP
        return () => {
            setStateSchema(schema);
            reset();
        }
    }, []) // eslint-disable-line


    const onSubmit = async (values) => {
        // console.log(values)

        let leSubmitValues = null;

        //! CLEAR ALL WRONG VALUES FOR BE
        const wrongSubmitFields = stateSchema.filter((stateField) => stateField.hidden);
        wrongSubmitFields.forEach((submitF) => {
            if (values[submitF.name]) {
                //! TEMP
                if (submitF.name !== 'mf_role') {
                    delete values[submitF.name]
                }
                //! TEMP
            }
        });
        Object.keys(values).forEach((key) => {
            if (typeof values[key] === 'undefined' || values[key] === null || values[key] === "") {
                delete values[key]
            }
        });
        //! CLEAR ALL WRONG VALUES FOR BE

        //* FIX CREATABLE FIELD
        const creatableFields = stateSchema.filter((stateField) => stateField.type === 'creatable');
        creatableFields.forEach((field) => {
            if (values[field.name] && Array.isArray(values[field.name])) {
                const string = values[field.name].map((i) => i.label);
                values[field.name] = string.join();
            }
        })

        //* IF FILE CREATE FORM DATA
        const fileField = stateSchema.find((stateField) => stateField.type === "file");
        if (fileField && files) {
            leSubmitValues = toFormData(values);
            leSubmitValues.append(`${fileField.name}[name]`, files.name);
            leSubmitValues.append(fileField.name, files);
            leSubmitValues.append('fileRequest', true);
        }

        //* IF FILE CREATE FORM DATA
        if (rqModel === "users" && values.role === "FarmaciaRole" && values.anf_code && values.qlikRefANF) {
            const foundAnf = values.qlikRefANF.find((anf) => anf === values.anf_code);
            if (!foundAnf) {
                values.qlikRefANF.push(values.anf_code.toString());
            }
        }

        if (rqModel === "users" && values.role === "FornecedorRole" && values.fornecedor_id && values.qlikRefLabCode) {
            const foundAnf = values.qlikRefLabCode.find((anf) => anf === values.fornecedor_id);
            if (!foundAnf) {
                values.qlikRefLabCode.push(values.fornecedor_id.toString());
            }
        }

        //! Temporary fix for boolean fields
        if (values && values.rent && values.rent.retention === "false") {
            values.rent.retention = false;
        } else if (values && values.rent && values.rent.retention === "true") {
            values.rent.retention = true;
        } else if (values.rentalNumber) {
            values.rent.retention = false;
        }
        if (values && values.renewable === "false") {
            values.renewable = false;
        } else if (values && values.renewable === "true") {
            values.renewable = true;
        } else if (values.rentalNumber) {
            values.renewable = false;
        }

        if (values && values.condominium && values.condominium.hasCondo === "") {
            values.condominium.hasCondo = false;
        }
        //! Temporary fix for boolean fields

        //! Temporary parse date for seguro modal
        if (values && values.renewalDate && page === "seguroModal") {
            values.renewalDate = moment(values.renewalDate).format("YYYYMMDD")
        }
        if (values && values.emissionDate && page === "seguroModal") {
            values.emissionDate = moment(values.emissionDate).format("YYYYMMDD")
        }

        //! Temporary fix for repair modal
        if (values && values.supplier && values.supplier.name) {
            values.supplier = values.supplier._id;
        }


        if (values) {
            if (data && page !== "transactionModal") {
                if (leSubmitValues) {
                    leSubmitValues.append('_id', data._id);
                }
                const values_id = {
                    ...values,
                    _id: data._id
                }

                if ((values_id.type === "repair" || values_id.type === "seguro") && data.propertyId) {
                    if (leSubmitValues) leSubmitValues.append('propertyId', data.propertyId);
                    values_id.propertyId = data.propertyId;
                }

                await setSubmitValues(leSubmitValues ? leSubmitValues : values_id);
                await saveData.mutate();

                setTimeout(async () => {
                    saveData.reset();
                }, 2500);
            } else {
                if (page === "transactionModal" && (values.type === "repair" || values.type === "seguro")) {
                    if (leSubmitValues) leSubmitValues.append('propertyId', data.propertyId);
                    values.propertyId = data.propertyId;
                }

                await setSubmitValues(leSubmitValues ? leSubmitValues : values);
                await createData.mutate();

                setTimeout(async () => {
                    createData.reset();
                }, 2500);
            }
        } else {
            errors.showMessages();
        }
    };

    const getDefaultValue = (field, value) => {
        const leSplit = field.name.split(".");

        if (field.multi && data && field.options) {
            if (field.backendPopulated) {
                if (leSplit.length === 2 && data[leSplit[0]] && data[leSplit[0]][leSplit[1]]) {
                    const fieldsFound = []
                    field.options.filter((opt) => {
                        data[leSplit[0]][leSplit[1]].filter((opt2) => {
                            if (opt[field.optionValue] === opt2[field.optionValue]) {
                                fieldsFound.push(opt);
                            }
                        });
                    });
                    return fieldsFound;
                } else if (data[field.name]) {
                    const fieldsFound = []
                    field.options.filter((opt) => {
                        data[field.name].filter((opt2) => {
                            if (opt[field.optionValue] === opt2[field.optionValue]) {
                                fieldsFound.push(opt);
                            }
                        });
                    });
                    return fieldsFound;
                } else {
                    return null
                }
            } else {
                if (leSplit.length === 2 && data[leSplit[0]] && data[leSplit[0]][leSplit[1]]) {
                    return field.options.filter((opt) => data[leSplit[0]][leSplit[1]].includes(opt[field.optionValue]));
                } else if (data[field.name]) {
                    return field.options.filter((opt) => data[field.name].includes(opt[field.optionValue]));
                } else {
                    return null
                }
            }
        } else if (data && field.options) {
            if (field.backendPopulated) {
                if (leSplit.length === 2 && data[leSplit[0]] && data[leSplit[0]][leSplit[1]]) {
                    return field.options.find((opt) => opt[field.optionValue].toString() === data[leSplit[0]][leSplit[1]][field.optionValue].toString());
                } else if (data[field.name]) {
                    return field.options.find((opt) => opt[field.optionValue].toString() === data[field.name][field.optionValue].toString());
                } else {
                    return null
                }
            } else {
                if (leSplit.length === 2 && data[leSplit[0]] && data[leSplit[0]][leSplit[1]]) {
                    return field.options.find((opt) => opt[field.optionValue]?.toString() === data[leSplit[0]][leSplit[1]]?.toString()) ? field.options.find((opt) => opt[field.optionValue]?.toString() === data[leSplit[0]][leSplit[1]]?.toString()) : null;
                } else if (data[field.name]) {
                    return field.options.find((opt) => opt[field.optionValue] && opt[field.optionValue].toString() === data[field.name] && data[field.name].toString());
                } else {
                    return null
                }
            }
        } else if (field.defaultValue) {
            return field.options.find((opt) => opt.value === field.defaultValue)
        } else {
            return null
        }
    }

    const getSelectValue = (field, value) => {
        if (value === false || value === true) { // lol what the actual fuck
            if (value === false) {
                return field.options.find((el) => el[field.optionValue] === "false")
            } else {
                return field.options.find((el) => el[field.optionValue] === "true")
            }
        } else {
            return field.options.find((el) => el[field.optionValue] === value)
        }
    }

    //* FORM SUBMIT HANDLERS
    if (data && page !== "transactionModal") {
        if (!saveData.isLoading && saveData.isSuccess) {
            if (editSuccess) editSuccess(saveData.data);
            if (refetchData) refetchData();

            saveData.reset();
            toggleTable();
        }
    } else {
        if (!createData.isLoading && createData.isSuccess) {
            if (createSuccess) createSuccess(createData.data);

            createData.reset();
            toggleTable();
        }
    }
    //* FORM SUBMIT HANDLERS

    history.listen((location => {
        if (history.action === "POP" && location.pathname === pathname) {
            toggleTable();
        }
    }));

    return (
        <Fragment>
            <Container fluid={true}>
                {!loading && stateSchema ? (
                    <Row>
                        <Col sm="12">
                            <Form className="theme-form needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    {stateSchema.map((field, index) => {
                                        return (field.type === "text" || field.type === "number" || field.type === "password" || field.type === "email" || field.type === "textarea") && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index}>
                                                <Label className="col-form-label pt-0">{field.label} {field.star && <i className="fa fa-star" style={{ color: "#EEBB00" }} />}</Label>
                                                <Input className="form-control" type={field.type} step={field.type === "number" ? field.step : null} id={field.id} name={field.name} innerRef={register({ required: field.required, pattern: field.pattern ? field.pattern : null, min: field.min, maxLength: field.maxLength })} />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length >= 3 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]][field.name.split('.')[3]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                                (field.requiredMessage)
                                                                : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                    (field.requiredMessage)
                                                                    : field.name.split('.').length <= 1 && errors[field.name] ?
                                                                        (field.requiredMessage)
                                                                        : null
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "select" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index}>
                                                <Label className="col-form-label pt-0">{field.label}</Label>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, value, ref }) => {
                                                        return (
                                                            <Select
                                                                placeholder="Selecionar ..."
                                                                className="basic-single"
                                                                id={field.id}
                                                                classNamePrefix="select"
                                                                defaultValue={getDefaultValue(field, value) ? getDefaultValue(field, value) : field.defaultValue ? field.defaultValue : null}
                                                                isMulti={field.multi}
                                                                isDisabled={field.disabled}
                                                                isClearable
                                                                isSearchable
                                                                name={field.name}
                                                                value={field.options && field.name === 'owners' ? field.options.find((el) => el[field.optionValue] === value) : field.options ? getSelectValue(field, value) : null}
                                                                options={field.options}
                                                                onChange={(values, input) => {
                                                                    if (!getValues(field.name) || (getValues(field.name) && values && getValues(field.name) !== values[field.optionValue])) {
                                                                        let trueValues = values
                                                                        // * SEND ONLY THE _ID TO BACKEND
                                                                        if (field.multi) {
                                                                            trueValues = values.map((val) => val[field.optionValue]);
                                                                        }

                                                                        // * SHOW AND HIDE FIELDS
                                                                        if (!field.multi && field.controller) {
                                                                            const controller = field.controller.find((ctrl) => ctrl.onSelect === trueValues[field.optionValue]);
                                                                            if (controller.show) {
                                                                                let tempSchema = [...stateSchema]; // Create Copy

                                                                                const hiddenFields = tempSchema.filter((hiddenFields) => hiddenFields.hidden === true);
                                                                                const shownFields = tempSchema.filter((showingFields) => showingFields.hidden === false);

                                                                                hiddenFields.forEach((showField) => {
                                                                                    controller.show.split("-").forEach((ctrl) => {
                                                                                        if (showField.id === ctrl) {
                                                                                            showField.hidden = false;
                                                                                        }
                                                                                    });
                                                                                });
                                                                                shownFields.forEach((showField) => {
                                                                                    controller.show.split("-").forEach((ctrl) => {
                                                                                        if (showField.id !== ctrl && typeof showField.hidden !== 'undefined') {
                                                                                            if (controller.dontHide) {
                                                                                                showField.hidden = false;
                                                                                            } else {
                                                                                                showField.hidden = true;
                                                                                            }
                                                                                        }
                                                                                    });
                                                                                });
                                                                                setStateSchema(tempSchema);
                                                                            } else {
                                                                                let tempSchema = [...stateSchema]; // Create Copy
                                                                                tempSchema.forEach((showField) => {
                                                                                    if (typeof showField.hidden !== "undefined") showField.hidden = true;
                                                                                });
                                                                                setStateSchema(tempSchema);
                                                                            }
                                                                        }

                                                                        // * SEND ONLY THE _ID TO BACKEND IF NOT MULTIPLE
                                                                        if (!field.multi) {
                                                                            trueValues = values[field.optionValue]
                                                                        }
                                                                        if (field.name === 'property') {
                                                                            setValue(input.name, trueValues);
                                                                            setValue('owners', [values.owners[0]]);
                                                                            clearErrors(input.name);
                                                                        } else {
                                                                            // * SET BODY VALUES
                                                                            setValue(input.name, trueValues)
                                                                            clearErrors(input.name);
                                                                        }
                                                                    }
                                                                }}
                                                                getOptionLabel={(opt) => opt[field.optionLabel]}
                                                                getOptionValue={(opt) => opt[field.optionValue]}
                                                            />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "datepicker" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index}>
                                                <Label className="col-form-label pt-0">{field.label}</Label>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    defaultValue=""
                                                    autoComplete='off'
                                                    render={({ onChange, onBlur, value }) => {
                                                        return (
                                                            <DatePicker
                                                                locale="pt"
                                                                utcOffset={1}
                                                                showYearDropdown={field.showYears}
                                                                dateFormat={field.dateFormat ? field.dateFormat : "dd/MM/yyyy"}
                                                                autoComplete='off'
                                                                className="form-control digits"
                                                                selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                onCalendarOpen={() => setValue(field.name, "")}
                                                                onBlur={onBlur}
                                                                disabled={field.name === disabledDatepicker || field.disabled || (field.editDisabled && value)}
                                                                onChange={(date) => {
                                                                    onChange(moment(date).add(5, 'hours'));
                                                                    setValue(field.name, moment(date).add(5, 'hours'));
                                                                }}
                                                                showMonthYearPicker={field.showMonthYearPicker}
                                                            />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "checkbox" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3 ${field.className ? field.className : ''}`} key={index} style={{ paddingTop: 30, paddingLeft: 15 }}>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    id={field.id}
                                                    defaultValue={data ? data[field.name] : false}
                                                    render={({ onChange, onBlur, value, ref }) => {
                                                        return (
                                                            <Input
                                                                style={{ marginLeft: 0 }}
                                                                className="form-check-input"
                                                                id={field.id}
                                                                type={field.type}
                                                                name={field.name}
                                                                checked={value}
                                                                onChange={e => {
                                                                    if (field.disables && e.target.checked) {
                                                                        setDisabledDatepicker(field.disables)
                                                                    } else if (field.disables && !e.target.checked) {
                                                                        setDisabledDatepicker(false)
                                                                    }

                                                                    setValue(field.name, e.target.checked);
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                />
                                                <Label className="form-check-label" style={{ marginLeft: 20 }} htmlFor={field.id}>{field.label}</Label>
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "froala" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index}>
                                                <Label className="col-form-label pt-0 mb-2">{field.label}</Label>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    id={field.id}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, value, ref }) => {
                                                        return (
                                                            <FroalaEditor name={field.name} id={field.id} onChange={(model) => setValue(field.name, model)} model={value} imageUploadParams={field.imageUploadParams} />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error mt-2">{field.name.split('.').length > 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ? (field.requiredMessage) : errors[field.name] && (field.requiredMessage)}</span>
                                            </Col>
                                        ) : field.type === "file" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index}>
                                                <Label className="col-form-label pt-0">{field.label}</Label>
                                                {field.content === 'image' && data && data[field.name] && (
                                                    <img src={data[field.name]} class="img-fluid mx-auto d-block mb-2" style={{ maxWidth: "15%" }} alt="current filepond img" />
                                                )}
                                                <Filepond
                                                    name={field.name}
                                                    label={field.label || "Carregar Ficheiro ou Imagem"}
                                                    multi={field.multi}
                                                    // files={files}
                                                    setFiles={setCustomFiles}
                                                    route={field.route}
                                                    content={field.content}
                                                    validate={field.validate}
                                                    imageEditInstantEdit={field.imageEditInstantEdit}
                                                    imageCropAspectRatio={field.imageEditInstantEdit}
                                                    imageResizeTargetWidth={field.imageResizeTargetWidth}
                                                    cropAspectRatio={field.cropAspectRatio}
                                                    cropAspectRatioOptions={field.cropAspectRatioOptions}
                                                />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "timepicker" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index} style={{ marginRight: 75 }}>
                                                <Label className="col-form-label pt-0">{field.label}</Label>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, value }) => {
                                                        return (
                                                            <TimePickerTwo
                                                                onSelect={(hour, minute) => {
                                                                    setValue(field.name, hour)
                                                                }}
                                                            />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "creatable" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} key={index} >
                                                <Label className="col-form-label pt-0">{field.label}</Label>
                                                <Controller
                                                    rules={{ required: field.required, pattern: field.pattern }}
                                                    control={control}
                                                    name={field.name}
                                                    defaultValue={[]}
                                                    render={({ onChange, onBlur, value }) => {
                                                        if (!Array.isArray(value) && value.split(",").length > 0) {
                                                            value = value.split(",").map((val) => createOption(val));
                                                        }
                                                        return (
                                                            <CreatableSelect
                                                                isMulti
                                                                value={value}
                                                                onCreateOption={(val) => {
                                                                    if (field.validateType === "email" && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
                                                                        const newOption = createOption(val);
                                                                        setValue(field.name, [...value, newOption]);
                                                                    } else {
                                                                        toast.error(`Email "${val}" inválido`, {
                                                                            position: toast.POSITION.TOP_CENTER
                                                                        });
                                                                    }
                                                                }}
                                                                onChange={(val) => {
                                                                    onChange(val);
                                                                }}
                                                                options={field.options}
                                                            />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error">
                                                    {
                                                        field.name.split('.').length === 2 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[1]] && errors[field.name.split('.')[0]][field.name.split('.')[1]][field.name.split('.')[2]] ?
                                                            (field.requiredMessage)
                                                            : field.name.split('.').length === 1 && errors[field.name.split('.')[0]] && errors[field.name.split('.')[0]][field.name.split('.')[1]] ?
                                                                (field.requiredMessage)
                                                                : errors[field.name] &&
                                                                (field.requiredMessage)
                                                    }
                                                </span>
                                            </Col>
                                        ) : field.type === "component" && field.name === "col" && !field.hidden ? (
                                            <Col md={`${field.colSize} mb-3`} className={field.className} key={index} />
                                        ) : field.type === "component" && field.name === "hr" && field.noBorder && !field.hidden ? (
                                            <Col md={`12`} key={index}>
                                                <hr className={field.className} key={index} style={{ borderTop: 'none' }} />
                                            </Col>
                                        ) : field.type === "component" && field.name === "hr" && !field.hidden ? (
                                            <Col md={`12 mb-3`} key={index}>
                                                <hr className={field.className} key={index} />
                                            </Col>
                                        ) : field.type === "component" && !field.hidden ? (
                                            <div className={field.className} key={index} />
                                        ) : field.type === "sectionTitle" && !field.hidden && (
                                            <Col md={`12 mb-3`} key={index}>
                                                <h6>{field.title}</h6>
                                            </Col>
                                        )
                                    })}
                                </div>
                                <div className="button-wrapper mt-4">
                                    {data ? (
                                        <Button color="primary" type="submit" className="mr-1" disabled={saveData.isLoading || saveData.isError}>
                                            {saveData.isLoading ? <i className="fa fa-spinner fa-spin" /> : saveData.isError ? `Erro, ${saveData.error?.response?.data}` : saveData.isSuccess ? "Guardado" : Submit}
                                        </Button>
                                    ) : (
                                        <Button color="primary" type="submit" className="mr-1" disabled={createData.isLoading || createData.isError}>
                                            {createData.isLoading ? <i className="fa fa-spinner fa-spin" /> : createData.isError ? `Erro, ${createData.error?.response?.data}` : createData.isSuccess ? "Criado" : Submit}
                                        </Button>
                                    )}
                                    <Button color="secondary" onClick={() => closeForm()}>{Cancel}</Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment >
    );
};

export default FormDefault;

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

const toFormData = (f => f(f))(h => f => f(x => h(h)(f)(x)))(f => fd => pk => d => {
    if (d instanceof Object) {
        Object.keys(d).forEach(k => {
            const v = d[k]
            if (pk) k = `${pk}[${k}]`
            if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
                return f(fd)(k)(v)
            } else {
                fd.append(k, v)
            }
        })
    }
    return fd
})(new FormData())()

