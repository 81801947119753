import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';

import CustomForm from "../form";

export const apiUrl = "/api/contracts";
export const rqModel = "contracts";
export const pathname = `${process.env.PUBLIC_URL}/contratos`;

const form = [
    {
        colSize: "6",
        label: "Renda",
        name: "rent",
        id: "rent",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "6",
        label: "Fee",
        name: "fee",
        id: "fee",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "12",
        label: "Data para entrar em efeito",
        name: "date",
        id: "date",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório"
    }
]

const UpdateRentFee = ({ isOpen, toggle, _id, refetchData, data }) => {
    const [success, setSuccess] = useState(false); //! TEMP

    const createSuccess = () => {
        if (!success) {
            toast.success(`Alteração da rent e fee feita com sucesso!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        if (refetchData) refetchData();
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle(_id)}>
            <ModalHeader toggle={() => toggle(_id)}>
                {"Alterar Renda/Fee"}
            </ModalHeader>
            <ModalBody>
                <CustomForm
                    data={{ rent: data?.contract?.rent?.value, fee: data?.contract?.fees?.base }}
                    toggleTable={toggle}
                    schema={form}
                    rqModel={rqModel}
                    route={`/api/contracts/${_id}/updateRentFee`}
                    createSuccess={createSuccess}
                    editSuccess={null}
                    pathname={pathname}
                    page={"transactionModal"}
                />
            </ModalBody>
        </Modal>
    )
}

export default UpdateRentFee;