import React, { Fragment } from "react";
import { CardBody, Card, Badge, Table, Button } from 'reactstrap';
import moment from "moment";

const Pagamentos = ({ data, transaction_sheets, toggleModal }) => {
    const getTotalPayments = (thisSheet) => {
        let totalPayments = 0;

        thisSheet.payments.forEach((payment) => {
            if (payment && payment.value) totalPayments += payment.value;
        });

        return totalPayments
    }

    return data ? (
        <Fragment>
            <Card>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Data</th>
                                <th>Descritivo</th>
                                <th>Tipo</th>
                                <th>Valor</th>
                                <th>Valor Pago</th>
                                <th>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transaction_sheets.data && transaction_sheets.data.results.map((thisSheet, index) => {
                                let totalDespesas = 0;
                                let totalCaptivations = 0;
                                let totalGuarantee = 0;
                                let totalPagProp = 0;
                                let totalFee = 0;

                                let realRentToBePaid = thisSheet.paid.value;
                                if (thisSheet.paid.paidInAdvance) realRentToBePaid = 0;

                                return (
                                    <>
                                        {thisSheet.transactions && thisSheet.transactions.map((thisTransaction, index) => {


                                            if (thisTransaction.tag !== 'rent' &&
                                                thisTransaction.tag !== 'guarantee' &&
                                                thisTransaction.tag !== 'captivation' &&
                                                thisTransaction.tag !== 'pp' &&
                                                thisTransaction.tag !== 'fee'
                                            ) {
                                                totalDespesas += -(thisTransaction.value)
                                            }

                                            if (thisTransaction.tag === 'captivation') {
                                                totalCaptivations += Math.abs(thisTransaction.value)
                                            }

                                            if (thisTransaction.tag === 'guarantee') {
                                                totalGuarantee += thisTransaction.value
                                            }

                                            if (thisTransaction.tag === 'pp') {
                                                totalPagProp += Math.abs(thisTransaction.value)
                                            }

                                            if (thisTransaction.tag === 'fee') {
                                                totalFee += Math.abs(thisTransaction.value)
                                            }

                                            return thisTransaction.tag === 'rent' ? (
                                                <tr key={index}>
                                                    <th scope="row">{thisSheet.monthAndYear}</th>
                                                    <td>{moment(thisTransaction.date).format('MMMM YYYY')}</td>
                                                    <td>{thisTransaction.name}</td>
                                                    <td><Badge color="warning">Pagamento</Badge></td>
                                                    <td><span style={{ color: '#48b0f7' }}>{thisSheet ? Math.abs(getTotalPayments(thisSheet)).toFixed(2) : "00.00"} €</span></td>
                                                    <td>{getTotalPayments(thisSheet).toFixed(2)} €</td>
                                                    <td>
                                                        <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-eye"></i></Button>
                                                    </td>
                                                </tr>
                                            ) : thisTransaction.tag === 'guarantee' ? (
                                                <tr key={index}>
                                                    <th scope="row">{thisSheet.monthAndYear}</th>
                                                    <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                    <td>{thisTransaction.name}</td>
                                                    <td><Badge color="light">Caução</Badge></td>
                                                    <td>{Math.abs(thisTransaction.value).toFixed(2)} €</td>
                                                    <td></td>
                                                    <td>
                                                        <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-eye"></i></Button>
                                                    </td>
                                                </tr>
                                            ) : thisTransaction.tag === 'pp' && (
                                                <tr key={index}>
                                                    <th scope="row">{thisSheet.monthAndYear}</th>
                                                    <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                    <td>{thisTransaction.name}</td>
                                                    <td><Badge color="dark">Crédito</Badge></td>
                                                    <td>{Math.abs(thisTransaction.value).toFixed(2)} €</td>
                                                    <td></td>
                                                    <td>
                                                        <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-eye"></i></Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <th scope="row">{thisSheet.monthAndYear}</th>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <Badge color="info">Upload</Badge>
                                                {thisSheet.proprietaryPayments && thisSheet.proprietaryPayments.payments.length > 0 && (
                                                    <Badge className="ml-1" color="success">Pago</Badge>
                                                )}
                                            </td>
                                            <td><span>{(realRentToBePaid + totalGuarantee + totalPagProp - totalDespesas - totalCaptivations + thisSheet.remainder - totalFee).toFixed(2)} €</span></td>
                                            <td>{thisSheet.proprietaryPaid.value.toFixed(2)} €</td>
                                            <td>
                                                <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisSheet._id, thisSheet, thisSheet, 'sheet')}><i className="fa fa-eye"></i></Button>
                                            </td>
                                        </tr>
                                        <tr style={{ background: '#f1f1f1' }}>
                                            <th scope="row"></th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    ) : null;
};

export default Pagamentos;