import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';

import DataTableExtensions from 'react-data-table-component-extensions';

import { useDelete } from '../../hooks/';

import CustomForm from '../form';
import { getWithFilters } from "../../api";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";


function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.email = el.contacts && el.contacts.email ? el.contacts.email : null
        el.phone = el.contacts && el.contacts.phone ? el.contacts.phone : null

        delete el._id
        delete el.contacts
        delete el.documents
        delete el.updatedAt
        delete el.createdAt
        delete el.NIPC
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += Math.abs(item.value);
            } else {
                result += item[key];
            }

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const Suppliers = () => {
    const history = useHistory();

    const [currentSupplier, setSupplier] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, supplierId)

    const getSuppliers = () => getWithFilters({ apiUrl: '/api/suppliers' });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel], () => getSuppliers(), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const toggleTable = () => {
        setViewType(null);
        setSupplier(null);
    }

    const createView = () => {
        setViewType("form");
        setSupplier(false);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (supplier) => {
        setViewType("form");
        setSupplier(supplier);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data, func) => {
        if (func) func();
        toast.success(`Fornecedor criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Fornecedor editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id, supplier_id) => {
        if (supplier_id) {
            await setSupplierId(`${_id}?supplier_id=${supplier_id}`);
        } else {
            await setSupplierId(`${_id}`);
        }
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Fornecedor?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Fornecedor apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });
        if (csv == null) return;

        filename = args.filename || 'export_suppliers_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <Fragment>
            <Breadcrumb title="Fornecedores" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                {viewType === "form" && (
                                    <CardHeader>
                                        <span>{viewType === "form" && !currentSupplier ? "Criar Fornecedor" : viewType === "form" && currentSupplier && "Editar Fornecedor"}</span>
                                    </CardHeader>
                                )}
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>}
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm
                                                data={currentSupplier}
                                                toggleTable={toggleTable}
                                                schema={schema}
                                                rqModel={rqModel}
                                                route={apiUrl}
                                                createSuccess={createSuccess}
                                                editSuccess={editSuccess}
                                                pathname={pathname}
                                                page="supplier"
                                            />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={data && Array.isArray(data) && data.map((supplier) => ({
                                                        name: `${supplier.name}`,
                                                        email: `${supplier.contacts.email}`,
                                                        NIF: `${supplier.NIF}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(supplier)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(supplier._id, supplier.supplier_id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        keyField={'_id'}
                                                        noHeader
                                                        columns={columns}
                                                        defaultSortField="type"
                                                        defaultSortAsc={true}
                                                        data={data && Array.isArray(data) && data.map((supplier) => ({
                                                            name: `${supplier.name}`,
                                                            email: `${supplier.contacts.email}`,
                                                            NIF: `${supplier.NIF}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(supplier)}><i className="fa fa-pencil"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(supplier._id, supplier.supplier_id)}><i className="fa fa-trash"></i></Button>
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                        noDataComponent={<NoResults />}
                                                        onRowClicked={(row, event) => {
                                                            const findRowId = data.find((supplier) => supplier.name === row.name)
                                                            history.push(`${process.env.PUBLIC_URL}/fornecedor/${findRowId._id}`)
                                                        }}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Suppliers