import React, { Fragment, useState } from "react";
import axios from 'axios';
import { Col, Card, CardHeader, Row, Media, Button } from 'reactstrap';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';

import Lightbox from "react-image-lightbox";

import Filepond from "../../filepond";

const ViewSeguro = ({ isOpen, toggle, _id, refetchData, data, propertyId, setViewModalData }) => {
    const beforeImage = data.before && data.before.length > 0 ? data.before[0].file : require("../../../assets/images/not-found.webp")
    const afterImage = data.after && data.after.length > 0 ? data.after[0].file : require("../../../assets/images/not-found.webp")

    const initilindex = { index: 0, isOpen: false }
    const [photoIndex, setPhotoIndex] = useState(initilindex)
    const [photoIndexAfter, setPhotoIndexAfter] = useState(initilindex)

    const onMovePrev = () => {
        const prev = (photoIndex.index + data.before.length - 1) % data.before.length
        setPhotoIndex({ ...photoIndex, index: prev })
    }
    const onMoveNext = () => {
        const next = (photoIndex.index + 1) % data.before.length
        setPhotoIndex({ ...photoIndex, index: next })
    }

    const onMovePrevAfter = () => {
        const prev = (photoIndexAfter.index + data.after.length - 1) % data.after.length
        setPhotoIndexAfter({ ...photoIndexAfter, index: prev })
    }
    const onMoveNextAfter = () => {
        const next = (photoIndexAfter.index + 1) % data.after.length
        setPhotoIndexAfter({ ...photoIndexAfter, index: next })
    }

    const [before, setBefore] = useState(null); //* FILEPOND
    const [beforeLoading, setBeforeLoading] = useState(false);
    const [beforeError, setBeforeError] = useState(null);

    const [after, setAfter] = useState(null); //* FILEPOND
    const [afterLoading, setAfterLoading] = useState(false);
    const [afterError, setAfterError] = useState(null);

    const deleteRequest = (fileId, type) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Foto?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/properties/${propertyId}/repair/${data._id}/photo/${fileId}?type=${type}`)
                        .then((result) => {
                            window.location.reload();

                            toast.success("Foto apagada!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);

                            // if (refetchData) refetchData();

                            toast.error("Ocorreu um erro ao apagar a Foto", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const setCustomBefore = (file, deleted) => {
        if (file && file.name) file.fileExtension = file.name.split('.').pop().toLowerCase();

        if (deleted) {
            setBefore(before ? before.filter((el) => el.name !== deleted.file.name) : null)
        } else {
            if (before) {
                setBefore([...before, file])
            } else {
                setBefore([file])
            }
        }
    }

    const uploadBefore = () => {
        setBeforeLoading(true);

        let submitValues = new FormData();

        submitValues.append('type', "before");
        for (let i = 0; i < before.length; i++) {
            submitValues.append('images', before[i]);
        }
        axios.put(`/api/properties/${propertyId}/repair/${data._id}/photo`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 201) {
                    setViewModalData(result.data[0]);
                    setBefore(null)

                    if (refetchData) refetchData();

                    toast.success(`Upload feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setBeforeLoading(false);
                } else {
                    setBeforeLoading(false);
                    setBeforeError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setBeforeError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);

                setBeforeLoading(false);
                setBeforeError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setBeforeError(null);
                }, 3000);
            });
    }

    const setCustomAfter = (file, deleted) => {
        if (file && file.name) file.fileExtension = file.name.split('.').pop().toLowerCase();

        if (deleted) {
            setAfter(after ? after.filter((el) => el.name !== deleted.file.name) : null)
        } else {
            if (after) {
                setAfter([...after, file])
            } else {
                setAfter([file])
            }
        }
    }

    const uploadAfter = () => {
        setAfterLoading(true);

        let submitValues = new FormData();

        submitValues.append('type', "after");
        for (let i = 0; i < after.length; i++) {
            submitValues.append('images', after[i]);
        }

        axios.put(`/api/properties/${propertyId}/repair/${data._id}/photo`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 201) {
                    setViewModalData(result.data[0]);
                    setAfter(null)

                    if (refetchData) refetchData();

                    toast.success(`Upload feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setAfterLoading(false);
                } else {
                    setAfterLoading(false);
                    setAfterError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setAfterError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);

                setAfterLoading(false);
                setAfterError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setAfterError(null);
                }, 3000);
            });
    }

    return data ? (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <Row style={{ paddingRight: 15, paddingLeft: 15 }}>
                                <Link onClick={() => toggle()}><ArrowLeft /></Link>
                                <h5>{data.name}</h5>
                            </Row>
                        </CardHeader>
                        <div className="profile-mail" style={{ padding: '35px' }}>
                            <div className="email-general" style={{ padding: 0 }}>
                                <h6 className="mb-3">{'Informação'}</h6>
                                <ul>
                                    <li>{'Fornecedor'} <span className="font-primary">{data.supplier.name}</span></li>
                                    <li>{'Valor'} <span className="font-primary">{data.price.toFixed(2)} €</span></li>
                                </ul>
                            </div>
                        </div>
                        <Row style={{ padding: '35px' }}>
                            <Col sm="6">
                                <Row style={{ marginBottom: 15 }}>
                                    <h5>Antes</h5>
                                </Row>
                                <figure className="col-xl-12 col-sm-12">
                                    <Media
                                        src={beforeImage}
                                        alt="Gallery"
                                        className="img-thumbnail"
                                        onClick={() =>
                                            setPhotoIndex({ ...photoIndex, index: 0, isOpen: true })
                                        }
                                        style={{ margin: "auto" }}
                                    />
                                </figure>
                            </Col>
                            <Col sm="6">
                                <Row style={{ marginBottom: 15 }}>
                                    <h5>Depois</h5>
                                </Row>
                                <figure className="col-xl-12 col-sm-12">
                                    <Media
                                        src={afterImage}
                                        alt="Gallery"
                                        className="img-thumbnail"
                                        onClick={() => {
                                            if (data.after && data.after.length > 0) setPhotoIndexAfter({ ...photoIndexAfter, index: 0, isOpen: true })
                                        }}
                                        style={{ margin: "auto" }}
                                    />
                                </figure>
                            </Col>
                            <Col sm="6" className="mt-4">
                                {data.before && (
                                    <>
                                        <h5>{'Upload de Imagem Antes'}</h5>
                                        <Filepond
                                            name={"mass_upload"}
                                            label={"Carregar Foto"}
                                            multi={true}
                                            files={before}
                                            setFiles={setCustomBefore}
                                        />
                                        {before && before.length > 0 && (
                                            <>
                                                <Button color="primary" onClick={uploadBefore}>
                                                    {beforeLoading ? (
                                                        <i className="fa fa-spin fa-spinner" />
                                                    ) : beforeError ? (
                                                        beforeError
                                                    ) : (
                                                        "Upload"
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col sm="6" className="mt-4">
                                <h5>{'Upload de Imagem Depois'}</h5>
                                <Filepond
                                    name={"mass_upload"}
                                    label={"Carregar Foto"}
                                    multi={true}
                                    files={after}
                                    setFiles={setCustomAfter}
                                />
                                {after && after.length > 0 && (
                                    <>
                                        <Button color="primary" onClick={uploadAfter}>
                                            {afterLoading ? (
                                                <i className="fa fa-spin fa-spinner" />
                                            ) : afterError ? (
                                                afterError
                                            ) : (
                                                "Upload"
                                            )}
                                        </Button>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {photoIndex.isOpen && data.before && data.before.length > 0 && (
                <Lightbox
                    mainSrc={data.before[photoIndex.index].file}
                    nextSrc={data.before[(photoIndex.index + 1) % data.before.length] && data.before[(photoIndex.index + 1) % data.before.length].file}
                    prevSrc={data.before[(photoIndex.index + data.before.length - 1) % data.before.length] && data.before[(photoIndex.index + data.before.length - 1) % data.before.length].file}
                    imageTitle="Antes"
                    onCloseRequest={() => setPhotoIndex({ ...photoIndex, index: 0, isOpen: false })}
                    onMovePrevRequest={onMovePrev}
                    onMoveNextRequest={onMoveNext}
                    toolbarButtons={[<Button color="primary" size="xs" className="mr-2" onClick={() => deleteRequest(data.before[photoIndex.index]._id, "before")}><i className="fa fa-trash"></i></Button>]}
                />
            )}
            {photoIndexAfter.isOpen && data.after && data.after.length > 0 && (
                <Lightbox
                    mainSrc={data.after[photoIndexAfter.index].file}
                    nextSrc={data.after[(photoIndexAfter.index + 1) % data.after.length] && data.after[(photoIndexAfter.index + 1) % data.after.length].file}
                    prevSrc={data.after[(photoIndexAfter.index + data.after.length - 1) % data.before.length] && data.after[(photoIndexAfter.index + data.after.length - 1) % data.before.length].file}
                    imageTitle="Depois"
                    onCloseRequest={() => setPhotoIndexAfter({ ...photoIndexAfter, index: 0, isOpen: false })}
                    onMovePrevRequest={onMovePrevAfter}
                    onMoveNextRequest={onMoveNextAfter}
                    toolbarButtons={[<Button color="primary" size="xs" className="mr-2" onClick={() => deleteRequest(data.after[photoIndex.index]._id, "after")}><i className="fa fa-trash"></i></Button>]}
                />
            )}
        </Fragment>
    ) : null
}

export default ViewSeguro