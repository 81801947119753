import "react-data-table-component-extensions/dist/index.css";

import './index.scss';

import ReactDOM from 'react-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { Provider } from 'react-redux';
import axios from 'axios';

import * as serviceWorker from './serviceWorker';

import App from './components/app';

import { routes } from './route';

import store from './store';

import ConfigDB from './data/customizer/config';

// Login Page
import Signin from './auth/signin';
import ForgotPwd from './auth/forgotpassword';
import ChangePwd from './auth/changepassword';

// Error page
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

// Maintenance
import Maintenance from "./pages/maintenance";

// Dashboards
import Admin from "./components/dashboard/admin";
import Default from "./components/dashboard/default";

import { setUser, setClients } from '../src/redux/actionTypes';

const queryClient = new QueryClient()

const Root = () => {
    const [anim, setAnim] = useState("");

    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade';

    const abortController = new AbortController();

    const [role, setRole] = useState(false); //! TEMP
    const [myANF, setMyANF] = useState(false);

    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('layout_version') === 'dark-only') {
            document.body.className = "dark-only"
        }

        setAnim(animation);
        setLoading(true);

        //! MOLONI
        // axios
        //     .get(`/api/moloni`)
        //     .then((result) => {
        //         console.log(result)
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });

        axios
            .get(`/api/clients`)
            .then((result) => {
                store.dispatch(setClients(result.data.results));
            })
            .catch((error) => {
                console.error(error);
            });

        axios
            .get(`/api/auth`)
            .then((result) => {
                store.dispatch(setUser(result.data));

                setRole(false); //! TEMP
                setAuthenticated(true);
                setLoading(false);
            })
            .catch((error) => {
                let resetUser = {
                    loaded: true,
                    authenticated: false,
                    kpiInfo: [],
                    user: null,
                }

                setAuthenticated(false);
                setLoading(false);

                console.error(error);
                store.dispatch(setUser(resetUser));
            });

        return function cleanup() {
            abortController.abort();
        }
    }, []); // eslint-disable-line 

    return (
        <Fragment>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <BrowserRouter basename={`/`}>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={() => <Signin />} />
                            <Route path={`${process.env.PUBLIC_URL}/recuperar-password`} component={ForgotPwd} />
                            <Route path={`${process.env.PUBLIC_URL}/alterar-password`} component={ChangePwd} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />

                            <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />

                            {loading ? (
                                <Route>
                                    <div className="loader-wrapper loderhide"><div className="loader-index"><span></span></div><svg><defs></defs><filter id="goo"><fegaussianblur in="SourceGraphic" stdDeviation="11" result="blur"></fegaussianblur><fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix></filter></svg></div>
                                </Route>
                            ) : !loading && authenticated ? (
                                <App>
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                    }} />
                                    <TransitionGroup>
                                        <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={() => role === "AdminRole" ? <Admin /> : <Default />} />

                                        {routes.map(({ path, Component }) => (
                                            <Route key={path} path={`${process.env.PUBLIC_URL}${path}`} component={Component} />
                                        ))}

                                    </TransitionGroup>
                                </App>
                            ) : !loading && !authenticated && (
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            )}

                        </Switch>
                    </BrowserRouter>
                </Provider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Fragment >
    )
}

ReactDOM.render(<Root />,
    document.getElementById('root')
);

serviceWorker.unregister();
