import React, { Fragment, useState } from "react";
import { Card, CardHeader, CardBody, Button, Table, Badge, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import { useDelete } from '../../../../hooks/';

import ViewRepairModal from "../../RepairModal/ViewRepairModal";
import RepairModal from "../../RepairModal/RepairModal";
import ViewTransactionModal from '../../../balances/transactions/ViewTransactionModal';

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.price = el.price ? el.price.toLocaleString("pt-BR", { minimumFractionDigits: 2 }) : null;
        el.supplier = el.supplier && el.supplier.name ? el.supplier.name : null

        el.startDate = el.startDate ? moment(el.startDate).format('DD/MM/YYYY') : null;
        el.endDate = el.endDate ? moment(el.endDate).format('DD/MM/YYYY') : null;
    })

    data.forEach((el) => {
        delete el.after
        delete el.before
        delete el.contractId
        delete el.supplier
        delete el.transactionId
        delete el.transactionSheetId
        delete el.transactions
        delete el._id
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += Math.abs(item.value);
            } else {
                result += item[key] ? item[key] : '';
            }

            ctr++;
        });

        result += lineDelimiter;
    });

    return result;
}

const Repairs = ({ data, refetch }) => {
    const [repairModal, setRepairModal] = useState(false);
    const [modalData, setModalData] = useState(false);

    const [repairId, setRepairId] = useState(null);

    const deleteData = useDelete(`/api/properties/${data._id}/repair`, "repairs", repairId)

    const [viewModal, setViewModal] = useState(false);
    const [viewModalData, setViewModalData] = useState(false);

    const [viewModalT, setViewModalT] = useState(false);
    const [currentTransaction, setTransaction] = useState(null);
    const [currentSheet, setSheet] = useState(null);
    const [optState, setOpt] = useState(null); // listagem de pagamentos
    const [currentModalId, setCurrentModalId] = useState(null);

    const toggleModalTransaction = (type, _id, thisTransaction, thisSheet, opt) => {
        if (type && _id) {
            if (opt) {
                setOpt(opt);
            } else {
                setOpt(null);
            }

            if (type === 'view') {
                setViewModalT(!viewModalT);
            }

            setTransaction(thisTransaction);
            setSheet(thisSheet);
            setCurrentModalId(_id);
        } else {
            // After form submission
            setOpt(null);

            setViewModalT(false);

            setTransaction(null);
            setSheet(null);
        }
    }

    const toggleModal = (data) => {
        setRepairModal(!repairModal)

        if (data) {
            setModalData(data)
        } else {
            setModalData(false)
        }
    }

    const toggleAfterCreate = (data) => {
        if (repairModal) {
            setRepairModal(false)

            if (data) {
                setModalData(data)
            } else {
                setModalData(false)
            }
        }
    }

    const toggleViewModal = (data) => {
        setViewModal(!viewModal)

        if (data) {
            setViewModalData(data)
        } else {
            setViewModalData(false)
        }
    }

    const deleteRequest = async (_id, customer_id) => {
        await setRepairId(`${_id}`);

        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Reparação?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Reparação apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        window.location.reload();
                        // if (refetch) refetch();
                    }
                }
            })
    }

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data.repairs);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });
        if (csv == null) return;

        filename = args.filename || 'export_property_repairs_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return data ? (
        <Fragment>
            {viewModal ? (
                <ViewRepairModal refetchData={refetch} _id={data._id} isOpen={viewModal} toggle={toggleViewModal} data={viewModalData} propertyId={data._id} setViewModalData={setViewModalData} />
            ) : (
                <>
                    <Button style={{ display: 'block', marginLeft: 'auto', marginBottom: '15px' }} className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>
                    <Button style={{ display: 'block', marginLeft: 'auto', marginBottom: '15px' }} color="primary" onClick={() => toggleModal()}>{"Adicionar"}</Button>
                    {/*! FALTA DAR SORT */}
                    {data.repairs && data.repairs.map((el) => (
                        <Card>
                            <CardHeader className='reduce-padding'>
                                <h4 className="card-title mb-0 float-left">{el.name}</h4>
                                <Button color="primary" size="xs" className="ml-2 float-right" onClick={() => deleteRequest(el._id)}><i className="fa fa-trash"></i></Button>
                                <Button color="primary" size="xs" className="ml-2 float-right" onClick={() => toggleModal(el)}><i className="fa fa-pencil"></i></Button>
                                <Button color="primary" size="xs" className="ml-2 float-right" onClick={() => toggleViewModal(el)}><i className="fa fa-eye"></i></Button>
                            </CardHeader>
                            <CardBody style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div className="text-muted">Fornecedor</div>
                                    <div className="text-success">{el.supplier?.name}</div>
                                </div>
                                <div>
                                    <div className="text-muted">Data de Inicio</div>
                                    <div className="text-info">{moment(el.startDate).format("DD/MM/YYYY")}</div>
                                </div>
                                <div>
                                    <div className="text-muted">Data de Fim</div>
                                    <div className="text-info">{moment(el.endDate).format("DD/MM/YYYY")}</div>
                                </div>
                                <div style={{ fontSize: 40 }} className={`text-right pr-2}`}>{el.price.toFixed(2)} €</div>
                            </CardBody>
                            {el.transactions && (
                                <Table>
                                    <tbody>
                                        {el.transactions.map((thisTransaction, index) => {
                                            return thisTransaction.tag === 'guarantee' ? (
                                                <tr key={index}>
                                                    <th scope="row">{thisTransaction.name}</th>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><Badge color='secondary'>Caução</Badge></td>
                                                    <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                    <td>{parseInt(Math.abs(thisTransaction.value)).toFixed(2)} €</td>
                                                    <td>
                                                        <Button outline color="primary" size="xs" onClick={() => toggleModalTransaction('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                                    </td>
                                                </tr>
                                            ) : thisTransaction.tag !== 'rent' && (
                                                <>
                                                    {thisTransaction.tag === 'captivation' ? (
                                                        <tr key={index}>
                                                            <th scope="row">{thisTransaction.name}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><Badge color='light'>Cativação</Badge></td>
                                                            <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                            <td>{parseInt(Math.abs(thisTransaction.value)).toFixed(2)} €</td>
                                                            <td>
                                                                <Button outline color="primary" size="xs" onClick={() => toggleModalTransaction('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                                            </td>
                                                        </tr>
                                                    ) : thisTransaction.tag === 'fee' ? (
                                                        <tr key={index}>
                                                            <th scope="row">{thisTransaction.name}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><Badge color='warning'>Fee</Badge></td>
                                                            <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                            <td>{parseInt(Math.abs(thisTransaction.value)).toFixed(2)} €</td>
                                                            <td>
                                                                <Button outline color="primary" size="xs" onClick={() => toggleModalTransaction('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                                            </td>
                                                        </tr>
                                                    ) : thisTransaction.tag === 'pp' ? (
                                                        <tr key={index}>
                                                            <th scope="row">{thisTransaction.name}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><Badge color='dark'>Crédito</Badge></td>
                                                            <td>
                                                                {/* {moment(thisTransaction.date).format('DD/MM/YYYY')} */}
                                                            </td>
                                                            <td>{parseInt(Math.abs(thisTransaction.value)).toFixed(2)} €</td>
                                                            <td>
                                                                <Button outline color="primary" size="xs" onClick={() => toggleModalTransaction('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={index}>
                                                            <th scope="row">{thisTransaction.name}</th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <Badge color='info'>Despesa</Badge>
                                                                {thisTransaction.paymentDate && (
                                                                    <Badge color='success'>Pago</Badge>
                                                                )}
                                                            </td>
                                                            <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                            <td>{parseInt(Math.abs(thisTransaction.value)).toFixed(2)} €</td>
                                                            <td>
                                                                <Button outline color="primary" size="xs" onClick={() => toggleModalTransaction('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            )}
                        </Card>
                    ))}

                    <RepairModal refetchData={refetch} _id={data._id} isOpen={repairModal} toggle={toggleModal} toggleAfterCreate={toggleAfterCreate} data={modalData} propertyId={data._id} setModalData={setModalData} />
                    <ViewTransactionModal _id={currentModalId} setTransaction={setTransaction} transaction={currentTransaction} setSheet={setSheet} currentSheet={currentSheet} isOpen={viewModalT} toggle={(type, _id) => toggleModalTransaction(type, _id)} opt={optState} />
                </>
            )}
        </Fragment>
    ) : null;
};

export default Repairs;