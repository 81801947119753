import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { Container, Row, Col, Form, Label, Input, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt';

import { Submit, Cancel } from "../../constant";

import { usePost, usePut } from "../../hooks";

import { Menu } from './customSelectMenu';
import ClientModal from './clientModal';

registerLocale("pt", pt);

const idTypeOptions = [
    {
        value: "cc",
        label: "Cartão Cidadão",
    },
    {
        value: "passport",
        label: "Passaporte",
    },
    {
        value: "bi",
        label: "Número BI",
    },
    {
        value: "cr",
        label: "Cartão Residência",
    },
]

const genderOptions = [
    {
        value: "M",
        label: "Masculino",
    },
    {
        value: "F",
        label: "Feminino",
    }
]

const maritalStatusOptions = [
    {
        value: "single",
        label: "Solteiro",
    },
    {
        value: "married",
        label: "Casado",
    },
    {
        value: "widow",
        label: "Viúvo",
    },
]

const typeOptions = [
    {
        value: "acquired",
        label: "Comunhão de Adquiridos",
    },
    {
        value: "general",
        label: "Comunhão Geral de Bens",
    },
    {
        value: "separated",
        label: "Separação de bens",
    },
    {
        value: "unionOfFact",
        label: "União de Facto",
    },
]

const ClientForm = ({ data, toggleTable, route, rqModel, createSuccess, editSuccess, pathname, clients }) => {
    const history = useHistory();

    const [type, setType] = useState('individual');
    const [idType, setIdtype] = useState('cc');
    const [maritalStatus, setMaritalStatus] = useState('single');

    const [clientModal, setClientModal] = useState(false);

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm({
        defaultValues: {
            ...data
        },
    });

    useEffect(() => {
        if (data) {
            register('company', data.company);
            if (data.company) {
                setType('empresa');
            }
            if (data.idType) {
                setIdtype(data.idType);
            }
            if (data.maritalStatus) {
                if (data.maritalStatus.status) setMaritalStatus(data.maritalStatus.status);
                if (data.maritalStatus.spouse) setValue('maritalStatus.spouse', data.maritalStatus.spouse);
            }
        } else {
            register('company', false);
        }
    }, [data, register, setValue]);

    const [submitValues, setSubmitValues] = useState({});

    const createData = usePost(route, rqModel, submitValues);
    const saveData = usePut(route, rqModel, submitValues);

    const closeForm = () => {
        toggleTable();
    }

    const onSubmit = async (values) => {
        if (values) {
            if (data) {
                const values_id = {
                    ...values,
                    _id: data._id
                }

                await setSubmitValues(values_id);
                await saveData.mutate();

                setTimeout(async () => {
                    saveData.reset();
                }, 2500);
            } else {
                await setSubmitValues(values);
                await createData.mutate();

                setTimeout(async () => {
                    createData.reset();
                }, 2500);
            }
        } else {
            errors.showMessages();
        }
    };

    const getDefaultValue = (field, value) => {
        if (!getValues(field)) {
            if (value && value.value) {
                setValue(field, value.value)
            } else if (value && value._id) {
                setValue(field, value._id)
            } else if (value) {
                setValue(field, value)
            }
        }
        return value;
    }

    //* FORM SUBMIT HANDLERS
    if (data) {
        if (!saveData.isLoading && saveData.isSuccess) {
            if (editSuccess) editSuccess(saveData.data);

            saveData.reset();
            toggleTable();
        }
    } else {
        if (!createData.isLoading && createData.isSuccess) {
            if (createSuccess) createSuccess(createData.data);

            createData.reset();
            toggleTable();
        }
    }
    //* FORM SUBMIT HANDLERS

    history.listen((location => {
        if (history.action === "POP" && location.pathname === pathname) {
            toggleTable();
        }
    }));

    const toggleClientModal = () => {
        setClientModal(!clientModal)
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data || true ? (
                    <Row>
                        <Col sm="12">
                            <Form className="theme-form needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <Col md={`2 mb-3`}>
                                        <Label className="col-form-label">{'Tipo'}</Label>
                                        <Controller
                                            name={'type'}
                                            control={control}
                                            render={({ onChange, onBlur, name, ref }) => (
                                                <Select
                                                    ref={ref}
                                                    placeholder="Selecionar ..."
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    options={[
                                                        {
                                                            value: "individual",
                                                            label: "Individual",
                                                        },
                                                        {
                                                            value: "empresa",
                                                            label: "Empresa",
                                                        }
                                                    ]}
                                                    defaultValue={data && data.company ?
                                                        getDefaultValue('type', {
                                                            value: "empresa",
                                                            label: "Empresa",
                                                        }) : getDefaultValue('type', {
                                                            value: "individual",
                                                            label: "Individual",
                                                        })
                                                    }
                                                    name={name}
                                                    onBlur={onBlur}
                                                    onChange={(values) => {
                                                        onChange(values)
                                                        setValue('company', values.value === 'empresa' ? true : false)
                                                        setValue('type', values.value)
                                                        setType(values.value)
                                                    }}
                                                />
                                            )}
                                        />
                                        {/* <span className="form-error">{errors.type && 'Este campo é obrigatório'}</span> */}
                                    </Col>
                                    <Col md={`6 mb-3`}>
                                    </Col>
                                    <Col md={`6 mb-3`}>
                                        <Label className="col-form-label">{'Nome'}</Label>
                                        <Input className="form-control" type="text" name="name" innerRef={register({ required: true })} />
                                        <span className="form-error">{errors.name && 'Este campo é obrigatório'}</span>
                                    </Col>
                                    <Col md={`6 mb-3`}>
                                    </Col>
                                    {type === 'individual' ? (
                                        <>
                                            <Col md={`2 mb-3`}>
                                                <Label className="col-form-label">{'NIF'}</Label>
                                                <Input className="form-control" type="text" name="IF" innerRef={register({ required: true })} />
                                                <span className="form-error">{errors.IF && 'Este campo é obrigatório'}</span>
                                            </Col>
                                            <Col md={`9 mb-3`}>
                                            </Col>
                                            <Col md={`4 mb-3`}>
                                                <Label className="col-form-label">{'Identificação'}</Label>
                                                <Controller
                                                    control={control}
                                                    name={'idType'}
                                                    render={({ onChange, onBlur, name, ref }) => (
                                                        <Select
                                                            ref={ref}
                                                            placeholder="Selecionar ..."
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            options={idTypeOptions}
                                                            defaultValue={!data ?
                                                                getDefaultValue('idType', {
                                                                    value: "cc",
                                                                    label: "Cartão Cidadão",
                                                                }) : data.idType && getDefaultValue('idType', {
                                                                    value: data.idType,
                                                                    label: idTypeOptions.find((el) => el.value === data.idType).label,
                                                                })
                                                            }
                                                            name={name}
                                                            onBlur={onBlur}
                                                            onChange={(values) => {
                                                                onChange(values);
                                                                setValue('idType', values.value);
                                                                setIdtype(values.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <span className="form-error">{errors.idType && 'Este campo é obrigatório'}</span> */}
                                            </Col>
                                            <Col md={`4 mb-3`}>
                                                <Label className="col-form-label">{'Número'}</Label>
                                                <Input className="form-control" type="text" name="identification.nationalId" innerRef={register()} />
                                            </Col>
                                            <Col md={`4 mb-3`}>
                                            </Col>
                                            {idType === 'cc' ? (
                                                <>
                                                    {/* ==== CARTÃO DE CIDADÃO ==== */}
                                                    <Col md={`4 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Validade'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.validity'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.validity', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.validity', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.validity && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`4 mb-3`}>
                                                        <Label className="col-form-label">{'Emitido Por'}</Label>
                                                        <Input className="form-control" type="text" name="identification.issuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedBy && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`4 mb-3`}>
                                                    </Col>
                                                    {/* =//==//= */}
                                                </>

                                            ) : idType === 'passport' ? (
                                                <>
                                                    {/* Passaporte */}
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.issuedOn'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.issuedOn', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.issuedOn', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedOn && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Validade'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.validity'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.validity', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.validity', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.validity && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Emitido Por'}</Label>
                                                        <Input className="form-control" type="text" name="identification.issuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedBy && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    {/* =//==//= */}
                                                </>
                                            ) : idType === 'bi' ? (
                                                <>
                                                    {/* BI */}
                                                    <Col md={`4 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.issuedOn'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.issuedOn', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.issuedOn', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedOn && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`4 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Validade'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.validity'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.validity', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.validity', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.validity && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    {/* =//==//= */}
                                                </>
                                            ) : idType === 'cr' && (
                                                <>
                                                    {/* Cartão de Residência */}
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.issuedOn'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.issuedOn', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.issuedOn', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedOn && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Data de Validade'}</Label>
                                                        <Controller
                                                            rules={{ required: false }}
                                                            control={control}
                                                            name={'identification.validity'}
                                                            defaultValue=""
                                                            render={({ onChange, onBlur, name, value }) => {
                                                                return (
                                                                    <DatePicker
                                                                        showYearDropdown
                                                                        locale="pt"
                                                                        utcOffset={1}
                                                                        autoComplete="off"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        className="form-control digits"
                                                                        selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                        onCalendarOpen={() => setValue('identification.validity', "")}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                onChange(moment(date));
                                                                                setValue('identification.validity', moment(date));
                                                                            }
                                                                        }}
                                                                    />
                                                                )

                                                            }}
                                                        />
                                                        <span className="form-error">{errors.identification && errors.identification.validity && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    <Col md={`3 mb-3`}>
                                                        <Label className="col-form-label">{'Emitido Por'}</Label>
                                                        <Input className="form-control" type="text" name="identification.issuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                        <span className="form-error">{errors.identification && errors.identification.issuedBy && 'Este campo é obrigatório'}</span>
                                                    </Col>
                                                    {/* =//==//= */}
                                                </>
                                            )}
                                            <Col md={`3 mb-3`}>
                                                <Label className="col-form-label">{'Sexo'}</Label>
                                                <Controller
                                                    // rules={{ required: false}}
                                                    control={control}
                                                    name={'gender'}
                                                    render={({ onChange, onBlur, name, ref }) => (
                                                        <Select
                                                            ref={ref}
                                                            placeholder="Selecionar ..."
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            options={genderOptions}
                                                            defaultValue={!data ?
                                                                getDefaultValue('gender', {
                                                                    value: "M",
                                                                    label: "Masculino",
                                                                }) : data.gender && getDefaultValue('gender', {
                                                                    value: data.gender,
                                                                    label: genderOptions.find((el) => el.value === data.gender).label,
                                                                })
                                                            }
                                                            name={name}
                                                            onBlur={onBlur}
                                                            onChange={(values) => {
                                                                onChange(values);
                                                                setValue('gender', values.value);
                                                                setIdtype(values.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <span className="form-error">{errors.gender && 'Este campo é obrigatório'}</span>
                                            </Col>
                                            <Col md={`4 mb-3`}>
                                                <Label className="col-form-label">{'Data de Nascimento'}</Label>
                                                <Controller
                                                    rules={{ required: false }}
                                                    control={control}
                                                    name={'birthDate'}
                                                    defaultValue=""
                                                    render={({ onChange, onBlur, name, value }) => {
                                                        return (
                                                            <DatePicker
                                                                showYearDropdown
                                                                locale="pt"
                                                                utcOffset={1}
                                                                autoComplete="off"
                                                                dateFormat="dd/MM/yyyy"
                                                                className="form-control digits"
                                                                selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                onCalendarOpen={() => setValue('birthDate', "")}
                                                                name={name}
                                                                onBlur={onBlur}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        onChange(moment(date));
                                                                        setValue('birthDate', moment(date));
                                                                    }
                                                                }}
                                                            />
                                                        )

                                                    }}
                                                />
                                                <span className="form-error">{errors.birthdate && 'Este campo é obrigatório'}</span>
                                            </Col>
                                            <Col md={`4 mb-3`}>
                                                <Label className="col-form-label">{'Local de Nascimento'}</Label>
                                                <Input className="form-control" type="text" name="placeOfBirth" innerRef={register({ required: false })} />
                                                <span className="form-error">{errors.placeOfBirth && 'Este campo é obrigatório'}</span>
                                            </Col>
                                            <Col md={`12 mb-3`}>
                                                <hr className={'mb-4'} />
                                            </Col>
                                            <Col md={`2 mb-3`}>
                                                <Label className="col-form-label">{'Estado Civil'}</Label>
                                                <Controller
                                                    // rules={{ required: false}}
                                                    control={control}
                                                    name={'maritalStatus.status'}
                                                    render={({ onBlur, name, ref }) => (
                                                        <Select
                                                            ref={ref}
                                                            placeholder="Selecionar ..."
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            options={maritalStatusOptions}
                                                            defaultValue={!data ?
                                                                getDefaultValue('maritalStatus.status', {
                                                                    value: "single",
                                                                    label: "Solteiro",
                                                                }) : data.maritalStatus && data.maritalStatus.status && data.maritalStatus.status !== 'null' && getDefaultValue('maritalStatus.status', maritalStatusOptions.find((el) => el.value === data.maritalStatus.status))
                                                            }
                                                            name={name}
                                                            onBlur={onBlur}
                                                            onChange={(values) => {
                                                                setValue('maritalStatus.status', values.value);
                                                                setMaritalStatus(values.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <span className="form-error">{errors.maritalStatus && errors.maritalStatus.status && 'Este campo é obrigatório'}</span> */}
                                            </Col>
                                            <Col md={`6 mb-3`} />
                                            {(maritalStatus === 'married' || maritalStatus === 'widow') && (
                                                <>
                                                    <Col md={`6 mb-3`}>
                                                        <Label className="col-form-label">{'Regime de Bens'}</Label>
                                                        <Controller
                                                            // rules={{ required: false}}
                                                            control={control}
                                                            name={'maritalStatus.assets'}
                                                            render={({ onBlur, name, ref }) => (
                                                                <Select
                                                                    ref={ref}
                                                                    placeholder="Selecionar ..."
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    options={typeOptions}
                                                                    defaultValue={!data ?
                                                                        getDefaultValue('type', {
                                                                            value: "acquired",
                                                                            label: "Comunhão de Adquiridos",
                                                                        }) : data.maritalStatus && data.maritalStatus.assets && getDefaultValue('type', typeOptions.find((el) => el.value === data.maritalStatus.assets))
                                                                    }
                                                                    name={name}
                                                                    onBlur={onBlur}
                                                                    onChange={(values) => {
                                                                        setValue('maritalStatus.assets', values.value);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {/* <span className="form-error">{errors.maritalStatus && errors.maritalStatus.assets && 'Este campo é obrigatório'}</span> */}
                                                    </Col>
                                                    <Col md={`6 mb-3`}>
                                                        <Label className="col-form-label">{'Conjuge'}</Label>
                                                        <Controller
                                                            // rules={{ required: false}}
                                                            control={control}
                                                            name={'maritalStatus.spouse'}
                                                            render={({ onBlur, name, ref }) => (
                                                                <Select
                                                                    ref={ref}
                                                                    placeholder="Selecionar ..."
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isClearable
                                                                    isSearchable
                                                                    options={clients.filter((client) => client.maritalStatus.status !== "married" && !client.maritalStatus.spouse)}
                                                                    name={name}
                                                                    onBlur={onBlur}
                                                                    components={{ Menu }}
                                                                    defaultValue={!data ?
                                                                        null : data.maritalStatus && data.maritalStatus.spouse && getDefaultValue('maritalStatus.spouse', clients.find((el) => el._id === data.maritalStatus.spouse))
                                                                    }
                                                                    onChange={(values) => {
                                                                        if (values) {
                                                                            setValue('maritalStatus.spouse', values);
                                                                        } else {
                                                                            setValue('maritalStatus.spouse', null);
                                                                        }
                                                                    }}
                                                                    getOptionLabel={(opt) => opt['name']}
                                                                    getOptionValue={(opt) => opt['_id']}
                                                                    toggleClientModal={toggleClientModal}
                                                                />
                                                            )}
                                                        />
                                                        {/* <span className="form-error">{errors.maritalStatus && errors.maritalStatus.spouse && 'Este campo é obrigatório'}</span> */}
                                                    </Col>
                                                </>
                                            )}
                                        </>
                                    ) : type === 'empresa' && (
                                        <>
                                            <Col md={`6 mb-3`}>
                                                <Label className="col-form-label">{'NIPC / NIF'}</Label>
                                                <Input className="form-control" type="text" name="nipc" innerRef={register({ required: false })} />
                                                <span className="form-error">{errors.nipc && 'Este campo é obrigatório'}</span>
                                            </Col>
                                        </>
                                    )}
                                    <Col md={`12 mb-3`}>
                                        <hr className={'mb-4'} />
                                    </Col>
                                    <Col md={`12 mb-3`}>
                                        <h6>Morada e Contactos</h6>
                                    </Col>
                                    <Col md={`12 mb-3`}>
                                        <Label className="col-form-label">{'Morada / Sede'}</Label>
                                        <Input className="form-control" type="text" name="address" innerRef={register({ required: false })} />
                                        {/* <span className="form-error">{errors.address && 'Este campo é obrigatório'}</span> */}
                                    </Col>
                                    <Col md={`6 mb-3`}>
                                        <Label className="col-form-label">{'Email'}</Label>
                                        <Input className="form-control" type="text" name="contacts.email" innerRef={register({ required: false })} />
                                        {/* <span className="form-error">{errors.contacts && errors.contacts.email && 'Este campo é obrigatório'}</span> */}
                                    </Col>
                                    <Col md={`6 mb-3`}>
                                        <Label className="col-form-label">{'Telefone'}</Label>
                                        <Input className="form-control" type="text" name="contacts.phone" innerRef={register({ required: false })} />
                                        {/* <span className="form-error">{errors.contacts && errors.contacts.phone && 'Este campo é obrigatório'}</span> */}
                                    </Col>
                                    <Col md={`12 mb-3`}>
                                        <Label className="col-form-label">{'Outros Contactos / Informações'}</Label>
                                        <Input className="form-control" type="text" name="contacts.other" innerRef={register({ required: false })} />
                                        {/* <span className="form-error">{errors.contacts && errors.contacts.other && 'Este campo é obrigatório'}</span> */}
                                    </Col>
                                </div>
                                <div className="button-wrapper mt-4">
                                    {data ? (
                                        <Button color="primary" type="submit" className="mr-1" disabled={saveData.isLoading || saveData.isError}>
                                            {saveData.isLoading ? <i className="fa fa-spinner fa-spin" /> : saveData.isError ? `Erro, ${saveData.error?.response?.data}` : saveData.isSuccess ? "Guardado" : Submit}
                                        </Button>
                                    ) : (
                                        <Button color="primary" type="submit" className="mr-1" disabled={createData.isLoading || createData.isError}>
                                            {createData.isLoading ? <i className="fa fa-spinner fa-spin" /> : createData.isError ? `Erro, ${createData.error?.response?.data}` : createData.isSuccess ? "Criado" : Submit}
                                        </Button>
                                    )}
                                    <Button color="secondary" onClick={() => closeForm()}>{Cancel}</Button>
                                </div>
                            </Form>
                        </Col>

                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <ClientModal isOpen={clientModal} toggle={toggleClientModal} route={route} rqModel={rqModel} createSuccess={createSuccess} clients={clients} />
        </Fragment>
    )
}

export default ClientForm