import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';

import DataTableExtensions from 'react-data-table-component-extensions';

import { useDelete } from '../../hooks/';

// import CustomForm from "../form";
import ClientForm from './form';
import { getWithFilters } from "../../api";
import { apiUrl, rqModel, pathname, columns, schema } from "./settings";
import axios from 'axios';

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.email = el.contacts && el.contacts.email ? el.contacts.email : null
        el.phone = el.contacts && el.contacts.phone ? el.contacts.phone : null
        el.birthDate = moment(el.birthDate).format('DD/MM/YYYY')

        delete el._id
        delete el.contacts
        delete el.gender
        delete el.maritalStatus
        delete el.roles
        delete el.updatedAt
        delete el.createdAt
        delete el.company
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += Math.abs(item.value);
            } else {
                result += item[key];
            }

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const Clients = () => {
    const history = useHistory();

    const [currentClient, setClient] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [clientId, setClientId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, clientId)

    const getClients = () => getWithFilters({ apiUrl: '/api/clients' });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel], () => getClients(), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const toggleTable = () => {
        setViewType(null);
        setClient(null);
    }

    const createView = () => {
        setViewType("form");
        setClient(false);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (client) => {
        axios.get(`/api/clients/${client._id}`)
            .then((result) => {
                setClient(result.data);
                window.history.pushState(null, null, `${pathname}?view=edit`);
                setViewType("form");
            })
            .catch((error) => {
                setClient(client);
                window.history.pushState(null, null, `${pathname}?view=edit`);
                setViewType("form");
            });
    }

    const createSuccess = (data, func) => {
        if (func) func();
        toast.success(`Cliente criado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Cliente editado!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id, customer_id) => {
        if (customer_id) {
            await setClientId(`${_id}?customer_id=${customer_id}`);
        } else {
            await setClientId(`${_id}`);
        }
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Cliente?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Cliente apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data.results);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });
        if (csv == null) return;

        filename = args.filename || 'export_clients_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <Fragment>
            <Breadcrumb title="Clientes" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                {viewType === "form" && (
                                    <CardHeader>
                                        <span>{viewType === "form" && !currentClient ? "Criar Cliente" : viewType === "form" && currentClient && "Editar Cliente"}</span>
                                    </CardHeader>
                                )}
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>}
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <ClientForm data={currentClient} toggleTable={toggleTable} schema={schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} clients={data.results} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={data && data.results.map((client) => ({
                                                        type: client.roles && client.roles[0] ? client.roles[0].role === "owner" ? 'Proprietário' : client.roles[0].role === "tenant" ? 'Arrendatário' : client.roles[0].role === "guarantor" ? "Fiador" : 'N/A' : 'N/A',
                                                        name: `${client.name}`,
                                                        email: `${client.contacts?.email}`,
                                                        phone: `${client.contacts?.phone}`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(client)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(client._id, client.customer_id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        keyField={'_id'}
                                                        noHeader
                                                        columns={columns}
                                                        defaultSortField="type"
                                                        defaultSortAsc={true}
                                                        data={data && data.results.map((client) => ({
                                                            // type: <Badge color={client.roles && client.roles[0] ? client.roles[0].role === "owner" ? 'primary' : client.roles[0].role === "tenant" ? 'success' : client.roles[0].role === "guarantor" ? "info" : 'danger' : 'danger'}>{client.roles && client.roles[0] ? client.roles[0].role === "owner" ? 'Proprietário' : client.roles[0].role === "tenant" ? 'Arrendatário' : client.roles[0].role === "guarantor" ? "Fiador" : 'N/A' : 'N/A'}</Badge>,
                                                            type: client.roles && client.roles[0] ? client.roles[0].role === "owner" ? 'Proprietário' : client.roles[0].role === "tenant" ? 'Arrendatário' : client.roles[0].role === "guarantor" ? "Fiador" : 'N/A' : 'N/A',
                                                            name: `${client.name}`,
                                                            email: `${client.contacts?.email}`,
                                                            phone: `${client.contacts?.phone}`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(client)}><i className="fa fa-pencil"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(client._id, client.customer_id)}><i className="fa fa-trash"></i></Button>
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                        noDataComponent={<NoResults />}
                                                        onRowClicked={(row, event) => {
                                                            // console.log(event)
                                                            const findRowId = data.results.find((client) => client.name === row.name)
                                                            history.push(`${process.env.PUBLIC_URL}/cliente/${findRowId._id}`)
                                                        }}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Clients