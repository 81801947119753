import React from 'react';
import { Table, Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getRealRentToBePaid, getTotalCaptivations, getTotalDespesas, getTotalGuarantee, getTotalPagProp } from './settings';

const BalanceRow = ({ contract, sheet, profile }) => {
    // console.log(contract)
    // console.log(sheet)

    return (
        <Card>
            <CardHeader className='reduce-padding'>
                <h4 className="card-title mb-0 float-left">{contract.rentalNumber} {contract && sheet ? `- ${moment(sheet.monthAndYear, 'YYYYMM').format('MMMM YYYY')}` : ''}</h4>
                {!profile && (
                    <span className="float-right"><Link className="btn btn-xs btn-primary" to={`${process.env.PUBLIC_URL}/balanco/${contract._id}`}>Ver</Link></span>
                )}
            </CardHeader>
            <CardBody className={`${sheet && sheet.paid && sheet.paid.value >= contract.rent.liquidValue && !sheet.paid.paidInAdvance ? 'borderLeftSuccess' : sheet && sheet.paid && sheet.paid.value > 0 && sheet.paid.value < contract.rent.liquidValue && !sheet.paid.paidInAdvance ? 'borderLeftWarning' : sheet && sheet.paid && sheet.paid.paidInAdvance ? 'borderLeftInfo' : 'borderLeftDanger'}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div className="text-muted">Renda</div>
                    {sheet && !sheet.paid.paidInAdvance ? (
                        <div className="text-success">{contract.rent.liquidValue.toFixed(2)} €</div>
                    ) : (
                        <div className="text-info">{`0 (${contract.rent.liquidValue.toFixed(2)}) €`}</div>
                    )}
                </div>
                <div>
                    <div className="text-muted">Carry</div>
                    {contract && sheet && sheet.remainder >= 0 && (
                        <div className="text-success">{sheet.remainder.toFixed(2)} €</div>
                    )}
                    {contract && sheet && sheet.remainder < 0 && (
                        <div className="text-warning">{sheet.remainder.toFixed(2)} €</div>
                    )}
                </div>
                <div>
                    <div className="text-muted">Despesas</div>
                    <div className="text-success">{getTotalDespesas({ ...contract, ...sheet }).toFixed(2)} €</div>
                </div>
                <div style={{ fontSize: 40 }} className={`text-right pr-2 ${sheet && typeof sheet.proprietaryPayments !== 'undefined' && sheet.proprietaryPayments.payments.length > 0 ? 'text-success' : 'text-danger'}`}>{(getRealRentToBePaid({ ...contract, ...sheet }) - getTotalDespesas({ ...contract, ...sheet }) - getTotalCaptivations({ ...contract, ...sheet }) + (sheet && sheet.remainder ? sheet.remainder : 0) + getTotalGuarantee({ ...contract, ...sheet }) + getTotalPagProp({ ...contract, ...sheet })).toFixed(2)} €</div>
            </CardBody>
            <Table>
                <tbody>
                    {sheet && sheet.transactions.map((thisTransaction, index) => {
                        return thisTransaction.tag === 'guarantee' ? (
                            <tr key={index}>
                                <th scope="row">{thisTransaction.name}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><Badge color='secondary'>Caução</Badge></td>
                                <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                <td>{thisTransaction.value.toFixed(2)} €</td>
                            </tr>
                        ) : thisTransaction.tag !== 'rent' && (
                            <>
                                {thisTransaction.tag === 'captivation' ? (
                                    <tr key={index}>
                                        <th scope="row">{thisTransaction.name}</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Badge color='light'>Cativação</Badge></td>
                                        <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                        <td>{thisTransaction.value.toFixed(2)} €</td>
                                    </tr>
                                ) : thisTransaction.tag === 'fee' ? (
                                    <tr key={index}>
                                        <th scope="row">{thisTransaction.name}</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Badge color='warning'>Fee</Badge></td>
                                        <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                        <td>{thisTransaction.value.toFixed(2)} €</td>
                                    </tr>
                                ) : thisTransaction.tag === 'pp' ? (
                                    <tr key={index}>
                                        <th scope="row">{thisTransaction.name}</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Badge color='dark'>Crédito</Badge></td>
                                        <td>
                                            {/* {moment(thisTransaction.date).format('DD/MM/YYYY')} */}
                                        </td>
                                        <td>{thisTransaction.value.toFixed(2)} €</td>
                                    </tr>
                                ) : (
                                    <tr key={index}>
                                        <th scope="row">{thisTransaction.name}</th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <Badge color='info'>Despesa</Badge>
                                            {thisTransaction.paymentDate && (
                                                <Badge color='success'>Pago</Badge>
                                            )}
                                        </td>
                                        <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                        <td>{thisTransaction.value.toFixed(2)} €</td>
                                    </tr>
                                )}
                            </>
                        )
                    })}
                </tbody>
            </Table>
        </Card>
    )
}

export default BalanceRow