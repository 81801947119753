import { combineReducers } from 'redux'
import User from './user/reducer'
import Customizer from './customizer/reducer'
import Bookmarkapp from './bookmark/reducer'

const reducers = combineReducers({
    User,
    Customizer,
    Bookmarkapp
});

export default reducers;