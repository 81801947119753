export const actionTypes = {
    CHANGE_MOMENT: "CHANGE_MOMENT",
    SET_USER: "SET_USER",
    SET_CLIENTS: "SET_CLIENTS",
    SET_NEW_NOTIFICATION: "SET_NEW_NOTIFICATION",
    MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
    REFRESH_TODOS: "REFRESH_TODOS",
};

export const changeMoment = (payload) => ({
    type: actionTypes.CHANGE_MOMENT,
    payload: payload,
});

export const setUser = (payload) => ({
    type: actionTypes.SET_USER,
    payload: payload,
});

export const setClients = (payload) => ({
    type: actionTypes.SET_CLIENTS,
    payload: payload,
});

export const setNewNotification = (payload) => ({
    type: actionTypes.SET_NEW_NOTIFICATION,
    payload: payload,
});

export const markNotificationAsRead = (payload) => ({
    type: actionTypes.MARK_NOTIFICATION_AS_READ,
    payload: payload,
});

export const refreshToDos = (payload) => ({
    type: actionTypes.REFRESH_TODOS,
    payload: payload,
});
