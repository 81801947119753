export const apiUrl = "/api/contracts";
export const rqModel = "contracts";
export const pathname = `${process.env.PUBLIC_URL}/balances`;

export const getTotalDespesas = (contract) => {
    // console.log(contract)

    let totalDespesas = 0;

    if (contract && contract.transactions) {
        contract.transactions && contract.transactions.forEach((el) => {
            if (el.tag !== 'rent' && el.tag !== 'guarantee' && el.tag !== 'captivation' && el.tag !== 'pp') {
                totalDespesas += -(el.value)
            }
        });
    }

    return totalDespesas;
}

export const getRealRentToBePaid = (contract) => {
    let realRentToBePaid = 0;

    if (contract && contract.paid) {
        realRentToBePaid = contract.paid.value
    }

    if (contract && contract.paid && contract.paid.paidInAdvance) realRentToBePaid = 0

    return realRentToBePaid;
}

export const getTotalCaptivations = (contract) => {
    let totalCaptivations = 0;

    if (contract && contract.transactions) {
        contract.transactions.forEach((el) => {
            if (el.tag === 'captivation') {
                totalCaptivations += Math.abs(el.value)
            }
        });
    }

    return totalCaptivations;
}

export const getTotalGuarantee = (contract) => {
    let totalGuarantee = 0;

    if (contract && contract.transactions) {
        contract.transactions.forEach((el) => {
            if (el.tag === 'guarantee') {
                totalGuarantee += el.value
            }
        });
    }

    return totalGuarantee;
}

export const getTotalPagProp = (contract) => {
    let totalPagProp = 0;

    if (contract && contract.transactions) {
        contract.transactions.forEach((el) => {
            if (el.tag === 'pp') {
                totalPagProp += Math.abs(el.value)
            }
        });
    }

    return totalPagProp;
}
