import React, { Fragment } from "react";
import { CardBody, Form } from 'reactstrap';

const Geral = ({ data }) => {
    return data ? (
        <Fragment>
            <Form
                className="card theme-form needs-validation"
                noValidate=""
            >
                <CardBody>
                    <div className="list-persons row">
                        <div className="profile-mail" style={{ padding: 0 }}>
                            <div className="email-general" style={{ padding: 0 }}>
                                <h6 className="mb-3">{'Informação'}</h6>
                                <ul>
                                    <li>{'Nome'} <span className="font-primary">{data.name}</span></li>
                                    <li>{'NIF'} <span className="font-primary">{data.NIF}</span></li>
                                    <li>{'NIPC'} <span className="font-primary">{data.NIPC}</span></li>
                                    <li>{'IBAN'} <span className="font-primary">{data.IBAN}</span></li>
                                    <hr />
                                    <li>{'Email'} <span className="font-primary email_add_0">{data.contacts.email}</span></li>
                                    <li>{'Outro'} <span className="font-primary email_add_0">{data.contacts.other}</span></li>
                                    <li>{'Telefone'} <span className="font-primary email_add_0">{data.contacts.phone}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Form>
        </Fragment>
    ) : null;
};

export default Geral;