export const apiUrl = "/api/properties";
export const rqModel = "properties";
export const pathname = `${process.env.PUBLIC_URL}/patrimonio`;

export const schema = [
  //   {
  //     colSize: "6",
  //     label: "Código Interno",
  //     name: "idPM",
  //     id: "idPM",
  //     type: "text",
  //     required: true,
  //     requiredMessage: "Este campo é obrigatório",
  //   },
  //   {
  //     type: "component",
  //     colSize: "6",
  //     name: "col",
  //     id: "col",
  //   },
  //   {
  //     name: "hr",
  //     id: "hr",
  //     type: "component",
  //     className: "mb-4",
  //   },
  {
    title: "Localização",
    name: "sectionTitle",
    id: "sectionTitle",
    type: "sectionTitle",
  },
  {
    colSize: "12",
    label: "Morada",
    name: "address",
    id: "address",
    type: "textarea",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Código Postal",
    name: "postalCode",
    id: "postalCode",
    type: "text",
  },
  {
    colSize: "3",
    label: "Distrito",
    name: "district",
    id: "district",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "Aveiro",
        label: "Aveiro",
      },
      {
        value: "Beja",
        label: "Beja",
      },
      {
        value: "Braga",
        label: "Braga",
      },
      {
        value: "Bragança",
        label: "Bragança",
      },
      {
        value: "Castelo Branco",
        label: "Castelo Branco",
      },
      {
        value: "Coimbra",
        label: "Coimbra",
      },
      {
        value: "Évora",
        label: "Évora",
      },
      {
        value: "Faro",
        label: "Faro",
      },
      {
        value: "Guarda",
        label: "Guarda",
      },
      {
        value: "Leiria",
        label: "Leiria",
      },
      {
        value: "Lisboa",
        label: "Lisboa",
      },
      {
        value: "Portalegre",
        label: "Portalegre",
      },
      {
        value: "Porto",
        label: "Porto",
      },
      {
        value: "Santarém",
        label: "Santarém",
      },
      {
        value: "Setúbal",
        label: "Setúbal",
      },
      {
        value: "Viana do Castelo",
        label: "Viana do Castelo",
      },
      {
        value: "Vila Real",
        label: "Vila Real",
      },
      {
        value: "Viseu",
        label: "Viseu",
      },
    ],
  },
  {
    colSize: "3",
    label: "Localidade",
    name: "city",
    id: "city",
    type: "text",
  },
  {
    colSize: "3",
    label: "Concelho",
    name: "county",
    id: "county",
    type: "text",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "6",
    label: "Topologia",
    name: "typology",
    id: "typology",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "urban",
        label: "Urbano",
      },
      {
        value: "rustic",
        label: "Rústico",
      },
    ],
  },
  {
    colSize: "6",
    label: "Afectação",
    name: "affectation",
    id: "affectation",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "other",
        label: "Sem Informação",
      },
      {
        value: "housing",
        label: "Habitação",
      },
      {
        value: "services",
        label: "Serviços",
      },
      {
        value: "commerce",
        label: "Comércio",
      },
      {
        value: "agricultural",
        label: "Agricultura",
      },
    ],
  },
  {
    colSize: "4",
    label: "Incrição Predial",
    name: "propertyRegistry.number",
    id: "propertyRegistry.number",
    type: "text",
  },
  {
    colSize: "4",
    label: "Conservatória",
    name: "propertyRegistry.conservatory",
    id: "propertyRegistry.conservatory",
    type: "text",
  },
  {
    colSize: "4",
    label: "Freguesia",
    name: "propertyRegistry.parish",
    id: "propertyRegistry.parish",
    type: "text",
  },
  // {
  //     colSize: "4",
  //     label: "Fracção",
  //     name: "unit",
  //     id: "unit",
  //     type: "text",
  //     required: true,
  //     requiredMessage: "Este campo é obrigatório",
  // },
  {
    colSize: "4",
    label: "Incrição Matricial",
    name: "matrixRegistration.number",
    id: "matrixRegistration.number",
    type: "text",
  },
  // {
  //     colSize: "6",
  //     label: "Composição",
  //     name: "composition",
  //     id: "composition",
  //     type: "text",
  //     required: true,
  //     requiredMessage: "Este campo é obrigatório",
  // },
  {
    colSize: "4",
    label: "Proprietários",
    name: "owners",
    id: "owners",
    type: "select",
    multi: true,
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    api: {
      route: "/api/clients?form=true",
      redux: null,
    },
  },
  {
    colSize: "4",
    label: "Licença de Utilização",
    name: "licenseOfUse.description",
    id: "licenseOfUse.description",
    type: "text",
  },
  {
    colSize: "4",
    label: "Emitido Por",
    name: "licenseOfUse.issuedBy",
    id: "licenseOfUse.issuedBy",
    type: "text",
  },
  {
    colSize: "4",
    label: "Emitido em",
    name: "licenseOfUse.emissionDate",
    id: "licenseOfUse.emissionDate",
    type: "datepicker",
    showYears: true,
  },
  {
    colSize: "6",
    label: "Certificado Energético",
    name: "energyCert.description",
    id: "energyCert.description",
    type: "text",
  },
  {
    colSize: "6",
    label: "Válido até",
    name: "energyCert.validity",
    id: "energyCert.validity",
    type: "datepicker",
    showYears: true,
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "6",
    label: "Condomínio",
    name: "condominium.hasCondo",
    id: "condominium.hasCondo",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
    controller: [
      {
        onSelect: true,
        show: "condominium.monthlyValue-condominium.IBAN",
      },
      {
        onSelect: false,
        show: null,
      },
    ],
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    hidden: true,
    colSize: "6",
    label: "Valor Mensal (€)",
    name: "condominium.monthlyValue",
    id: "condominium.monthlyValue",
    type: "text",
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    hidden: true,
    colSize: "6",
    label: "IBAN",
    name: "condominium.IBAN",
    id: "condominium.IBAN",
    type: "text",
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    colSize: "12",
    label: "Observações",
    name: "condominium.obs",
    id: "condominium.obs",
    type: "textarea",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    title: "Seguro",
    name: "sectionTitle",
    id: "sectionTitle",
    type: "sectionTitle",
  },
  {
    colSize: "6",
    label: "Seguradora",
    name: "propertyInsurance.insurer",
    id: "propertyInsurance.insurer",
    type: "text",
  },
  {
    colSize: "6",
    label: "Número da Apólice",
    name: "propertyInsurance.number",
    id: "propertyInsurance.number",
    type: "text",
  },
  {
    colSize: "6",
    label: "Valor",
    name: "propertyInsurance.annualValue",
    id: "propertyInsurance.annualValue",
    type: "number",
    step: 0.01,
  },
  {
    colSize: "6",
    label: "Emitido Em",
    name: "propertyInsurance.emissionDate",
    id: "propertyInsurance.emissionDate",
    type: "datepicker",
    showYears: true,
  },
  {
    colSize: "6",
    label: "Renova Em",
    name: "propertyInsurance.validity",
    id: "propertyInsurance.validity",
    type: "datepicker",
    showYears: true,
  },
];

export const editSchema = [
  {
    title: "Localização",
    name: "sectionTitle",
    id: "sectionTitle",
    type: "sectionTitle",
  },
  {
    colSize: "12",
    label: "Morada",
    name: "address",
    id: "address",
    type: "textarea",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "3",
    label: "Código Postal",
    name: "postalCode",
    id: "postalCode",
    type: "text",
  },
  {
    colSize: "3",
    label: "Distrito",
    name: "district",
    id: "district",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "Aveiro",
        label: "Aveiro",
      },
      {
        value: "Beja",
        label: "Beja",
      },
      {
        value: "Braga",
        label: "Braga",
      },
      {
        value: "Bragança",
        label: "Bragança",
      },
      {
        value: "Castelo Branco",
        label: "Castelo Branco",
      },
      {
        value: "Coimbra",
        label: "Coimbra",
      },
      {
        value: "Évora",
        label: "Évora",
      },
      {
        value: "Faro",
        label: "Faro",
      },
      {
        value: "Guarda",
        label: "Guarda",
      },
      {
        value: "Leiria",
        label: "Leiria",
      },
      {
        value: "Lisboa",
        label: "Lisboa",
      },
      {
        value: "Portalegre",
        label: "Portalegre",
      },
      {
        value: "Porto",
        label: "Porto",
      },
      {
        value: "Santarém",
        label: "Santarém",
      },
      {
        value: "Setúbal",
        label: "Setúbal",
      },
      {
        value: "Viana do Castelo",
        label: "Viana do Castelo",
      },
      {
        value: "Vila Real",
        label: "Vila Real",
      },
      {
        value: "Viseu",
        label: "Viseu",
      },
    ],
  },
  {
    colSize: "3",
    label: "Localidade",
    name: "city",
    id: "city",
    type: "text",
  },
  {
    colSize: "3",
    label: "Concelho",
    name: "county",
    id: "county",
    type: "text",
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "6",
    label: "Topologia",
    name: "typology",
    id: "typology",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "urban",
        label: "Urbano",
      },
      {
        value: "rustic",
        label: "Rústico",
      },
    ],
  },
  {
    colSize: "6",
    label: "Afectação",
    name: "affectation",
    id: "affectation",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: "other",
        label: "Sem Informação",
      },
      {
        value: "housing",
        label: "Habitação",
      },
      {
        value: "services",
        label: "Serviços",
      },
      {
        value: "commerce",
        label: "Comércio",
      },
      {
        value: "agricultural",
        label: "Agricultura",
      },
    ],
  },
  {
    colSize: "4",
    label: "Incrição Predial",
    name: "propertyRegistry.number",
    id: "propertyRegistry.number",
    type: "text",
  },
  {
    colSize: "4",
    label: "Conservatória",
    name: "propertyRegistry.conservatory",
    id: "propertyRegistry.conservatory",
    type: "text",
  },
  {
    colSize: "4",
    label: "Freguesia",
    name: "propertyRegistry.parish",
    id: "propertyRegistry.parish",
    type: "text",
  },
  // {
  //     colSize: "4",
  //     label: "Fracção",
  //     name: "unit",
  //     id: "unit",
  //     type: "text",
  // },
  {
    colSize: "4",
    label: "Incrição Matricial",
    name: "matrixRegistration.number",
    id: "matrixRegistration.number",
    type: "text",
  },
  // {
  //     colSize: "6",
  //     label: "Composição",
  //     name: "composition",
  //     id: "composition",
  //     type: "text",
  // },
  {
    colSize: "4",
    label: "Proprietários",
    name: "owners",
    id: "owners",
    type: "select",
    multi: true,
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    api: {
      route: "/api/clients",
      redux: null,
    },
  },
  {
    colSize: "4",
    label: "Licença de Utilização",
    name: "licenseOfUse.description",
    id: "licenseOfUse.description",
    type: "text",
  },
  {
    colSize: "4",
    label: "Emitido Por",
    name: "licenseOfUse.issuedBy",
    id: "licenseOfUse.issuedBy",
    type: "text",
  },
  {
    colSize: "4",
    label: "Emitido em",
    name: "licenseOfUse.emissionDate",
    id: "licenseOfUse.emissionDate",
    type: "datepicker",
    showYears: true,
  },
  {
    colSize: "6",
    label: "Certificado Energético",
    name: "energyCert.description",
    id: "energyCert.description",
    type: "text",
  },
  {
    colSize: "6",
    label: "Válido até",
    name: "energyCert.validity",
    id: "energyCert.validity",
    type: "datepicker",
    showYears: true,
  },
  {
    name: "hr",
    id: "hr",
    type: "component",
    className: "mb-4",
  },
  {
    colSize: "6",
    label: "Condomínio",
    name: "condominium.hasCondo",
    id: "condominium.hasCondo",
    type: "select",
    multi: false,
    optionLabel: "label",
    optionValue: "value",
    options: [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
    controller: [
      {
        onSelect: true,
        show: "condominium.monthlyValue-condominium.IBAN",
      },
      {
        onSelect: false,
        show: null,
      },
    ],
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    hidden: true,
    colSize: "6",
    label: "Valor Mensal (€)",
    name: "condominium.monthlyValue",
    id: "condominium.monthlyValue",
    type: "text",
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    hidden: true,
    colSize: "6",
    label: "IBAN",
    name: "condominium.IBAN",
    id: "condominium.IBAN",
    type: "text",
  },
  {
    type: "component",
    colSize: "6",
    name: "col",
    id: "col",
  },
  {
    colSize: "12",
    label: "Observações",
    name: "condominium.obs",
    id: "condominium.obs",
    type: "textarea",
  },
];
