import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import CustomForm from "../../form";

import { repairModalForm, rqModel, pathname } from './settings';

const RepairModal = ({ isOpen, toggle, toggleAfterCreate, _id, refetchData, data, propertyId, setModalData }) => {
    const [success, setSuccess] = useState(false); //! TEMP

    const createSuccess = () => {
        if (!success) {
            toast.success(`Reparação criada!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        window.location.reload();
        // if (refetchData) refetchData();
    }

    const editSuccess = () => {
        if (!success) {
            toast.success(`Reparação editada!`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setSuccess(true);
        }

        window.location.reload();
        // if (refetchData) refetchData();
    }

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                {data && data._id ? "Editar Reparação" : "Adicionar Reparação"}
            </ModalHeader>
            <ModalBody>
                <CustomForm
                    data={data}
                    toggleTable={() => toggleAfterCreate()}
                    schema={repairModalForm}
                    rqModel={rqModel}
                    route={`/api/properties/${_id}/repair`}
                    createSuccess={createSuccess}
                    editSuccess={editSuccess}
                    pathname={pathname}
                    page={"repairModal"}
                />
            </ModalBody>
        </Modal>
    )
}

export default RepairModal