import { Users, Package, Briefcase, FileText, BarChart2, Airplay, Search, Phone } from 'react-feather';

export const MENUITEMS = [
    {
        menuTitle: null,
        menuContent: null,
        Items: [
            {
                title: 'Dashboard', icon: Airplay, type: 'link', path: `${process.env.PUBLIC_URL}/dashboard`
            },
            {
                title: 'Pesquisar', icon: Search, type: 'link', path: `${process.env.PUBLIC_URL}/pesquisar`,
            },
            {
                title: 'Clientes', icon: Users, type: 'link', path: `${process.env.PUBLIC_URL}/clientes`
            },
            {
                title: 'Fornecedores', icon: Package, type: 'link', path: `${process.env.PUBLIC_URL}/fornecedores`
            },
            {
                title: 'Património', icon: Briefcase, type: 'link', path: `${process.env.PUBLIC_URL}/patrimonio`
            },
            {
                title: 'Contratos', icon: FileText, type: 'link', path: `${process.env.PUBLIC_URL}/contratos`
            },
            {
                title: 'Balanços', icon: BarChart2, type: 'link', path: `${process.env.PUBLIC_URL}/balancos`
            },
            // {
            //     title: 'Suporte', icon: Phone, type: 'outlink', path: 'https://diogodev.freshdesk.com/support/tickets/new'
            // }
        ]
    },
    // {
    //     menuTitle: "Gestão Diária",
    //     menuContent: " ",
    //     Items: [
    //         {
    //             title: 'Marketing', icon: Award, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/marketing/music`, type: 'link', title: 'Music' },
    //                 { path: `${process.env.PUBLIC_URL}/marketing/facebook`, type: 'link', title: 'Facebook' }
    //             ]
    //         },
    //         {
    //             title: 'Centro', icon: Folder, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/centro/newsletters`, title: 'Newsletters', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/centro/noticias`, title: 'Noticias', type: 'link' },
    //                 // { path: `${process.env.PUBLIC_URL}/centro/knowledge-base`, type: 'link', title: 'KnowledgeBase' },
    //             ]
    //         },
    //         {
    //             title: 'Loja', icon: Package, type: 'sub', active: false, children: [
    //                 { path: `${process.env.PUBLIC_URL}/loja/produtos-de-loja`, title: 'Produtos de Loja', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/loja/equipamentos-de-renting`, title: 'Equipamentos de Renting', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/loja/renting`, title: 'Renting', type: 'link' },
    //                 // { path: `${process.env.PUBLIC_URL}/loja/subscricoes`, title: 'Subscrições', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/loja/fornecedores`, title: 'Fornecedores', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/loja/estatisticas`, title: 'Estatísticas', type: 'link' },
    //                 { path: `${process.env.PUBLIC_URL}/loja/encomendas`, title: 'Encomendas', type: 'link' },
    //             ]
    //         }
    //     ]
    // }
]