import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';

import { Container, Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
// import { useHistory } from 'react-router-dom';
// import CountUp from 'react-countup';
// import { useGetAll } from '../../hooks';

import NoResults from '../ui-kits/noResults';

import { getWithFilters } from "../../api";
import { useDelete } from '../../hooks/';

import moment from 'moment';

const Default = () => {
    const getFlags = () => getWithFilters({ apiUrl: '/api/flags' });
    const {
        isLoading,
        data,
        error,
    } = useQuery(["flags"], () => getFlags(), { keepPreviousData: true, staleTime: 300000 });

    const [flagId, setFlagId] = useState(null);

    const deleteData = useDelete('/api/flags', 'flags', flagId)

    const deleteRequest = async (_id) => {
        await setFlagId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Alerta?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Alerta apagado!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    // const history = useHistory();

    // const [dashboardStateLoaded, setDashboardStateLoaded] = useState(false);
    // const [dashboardState, setDashboardState] = useState({
    //     totalReceived: 0,
    //     totalToReceive: 0,
    //     percentageReceived: 0,
    //     totalExpenses: 0,
    //     totalCaptivations: 0,
    //     totalToPay: 0,
    //     totalFeesReceived: 0,
    //     totalContractsNumber: 0,
    //     totalContractsPaid: 0,
    //     totalContractsProcessed: 0
    // });

    // const contractsQuery = useGetAll('/api/contracts', 'contracts');

    // if (contractsQuery.error && contractsQuery.error.response) {
    //     history.push(`${process.env.PUBLIC_URL}/pages/errors/error${contractsQuery.error.response.status}`);
    // } else if (contractsQuery.error) {
    //     history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    // }

    // useEffect(() => {
    //     if (contractsQuery.status === 'success' && contractsQuery.data && !dashboardStateLoaded) {
    //         const newDashboardState = {
    //             totalReceived: 0,
    //             totalToReceive: 0,
    //             percentageReceived: 0,
    //             totalExpenses: 0,
    //             totalCaptivations: 0,
    //             totalToPay: 0,
    //             totalFeesReceived: 0,
    //             totalContractsNumber: 0,
    //             totalContractsPaid: 0,
    //             totalContractsProcessed: 0
    //         };

    //         contractsQuery.data.results.forEach((thisSheet) => {
    //             newDashboardState.totalContractsNumber += 1;

    //             if (thisSheet.sheet.paid.value >= thisSheet.sheet.rentAtCreation || thisSheet.sheet.paid.paidInAdvance) {
    //                 newDashboardState.totalContractsPaid += 1;
    //             }

    //             if (thisSheet.sheet.proprietaryPaid.value > 0) {
    //                 newDashboardState.totalContractsProcessed += 1;
    //             }

    //             newDashboardState.totalToReceive += thisSheet.rent.liquidValue
    //             newDashboardState.totalReceived += thisSheet.sheet.paid.value

    //             let thisSheetExpenses = 0;
    //             let thisSheetCaptivations = 0;
    //             let thisSheetPagoPro = 0;

    //             thisSheet.sheet.transactions.forEach((thisTransaction) => {
    //                 if (thisTransaction.tag !== 'rent' && thisTransaction.tag !== 'guarantee' && thisTransaction.tag !== 'captivation' && thisTransaction.tag !== 'pp') {
    //                     thisSheetExpenses += -(thisTransaction.value)
    //                 }

    //                 if (thisTransaction.tag === 'captivation') {
    //                     thisSheetCaptivations += Math.abs(thisTransaction.value)
    //                 }
    //                 if (thisTransaction.tag === 'pp') {
    //                     thisSheetPagoPro += Math.abs(thisTransaction.value)
    //                 }
    //                 if (thisTransaction.tag === 'fee') {
    //                     newDashboardState.totalFeesReceived += -(thisTransaction.value)
    //                 }
    //             });

    //             newDashboardState.totalExpenses += thisSheetExpenses;
    //             newDashboardState.totalCaptivations += thisSheetCaptivations;

    //             newDashboardState.totalToPay += (thisSheet.rent.liquidValue - thisSheetExpenses + thisSheet.sheet.remainder + thisSheetPagoPro)

    //         });

    //         setDashboardState(newDashboardState);
    //         setDashboardStateLoaded(true);
    //     }
    // }, [contractsQuery, dashboardStateLoaded])

    //! data de pagamento de seguro próxima alterar no backend a descrição

    return (
        <Fragment>
            <Container fluid={true}>
                <Row className='pt-4'>
                    <Col xl="12" className="notification box-col-12">
                        <Card>
                            <CardHeader className="reduce-padding">
                                <div className="header-top">
                                    <h5 className="m-0">Alertas</h5>
                                    <div className="card-header-right-icon">
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-4">
                                {!isLoading ? (
                                    <>
                                        {data && Array.isArray(data) && data.length > 0 ?
                                            data.map((flag) => (
                                                <div className="media">

                                                    <div className="media-body">
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(flag._id)} style={{ float: 'right' }}>
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                        <p>
                                                            {moment(flag.createdAt).format("DD/MM/YYYY")} - {moment(flag.createdAt).format("HH:mm")}
                                                        </p>
                                                        <Link to={{
                                                            pathname: `${process.env.PUBLIC_URL}/propriedade/${flag.propertyId}?tab=seguro`,
                                                            state: { fromUrl: `/dashboard` }
                                                        }}>
                                                            <h6>{flag.pmName.toUpperCase()}<span className="dot-notification"></span></h6>
                                                        </Link>
                                                        <span>O seguro desta propriedade vai ser renovado em <b>{moment(flag.endDate).format("DD/MM/YYYY")}</b> com o valor de <b>{parseInt(flag.value).toFixed(2)} €</b></span>
                                                    </div>
                                                </div>
                                            )) : (
                                                <NoResults />
                                            )}
                                    </>
                                ) : (
                                    <Row style={{ minHeight: 500 }}>
                                        <Col sm="12">
                                            <div className="loader-box loader-abs">
                                                <div className="loader-7" />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row className='pt-4'>
                    <Col xl="4" lg="4" md="12" className="box-col-6">
                        <Card className="total-users">
                            <CardHeader className="card-no-border reduce-padding">
                                <p className="f-w-500 font-roboto">
                                    {'24 CONTRATOS'}
                                    <Button className="float-right btn-xs" color="primary" onClick={() => history.push('/contratos')}>{"Ver"}</Button>
                                </p>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <div className="apex-chart-container goal-status text-center row">
                                    <ul className="col-xl-12">
                                        <li style={{ marginTop: 0, paddingTop: 0 }}>
                                            <div className="goal-detail">
                                                <h6> <span className="font-primary">{'Pago pelo Arrendatário'} : </span>{"23"}</h6>
                                                <div className="progress sm-progress-bar progress-animate">
                                                    <div className="progress-gradient-primary" role="progressbar" style={{ width: "60%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="goal-detail mb-0">
                                                <h6><span className="font-primary">{'Pago ao Proprietário'}: </span>{"5"}</h6>
                                                <div className="progress sm-progress-bar progress-animate">
                                                    <div className="progress-gradient-primary" role="progressbar" style={{ width: "50%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="4" lg="4" md="12" className="box-col-6">
                        <Card className="o-hidden">
                            <CardBody>
                                <div className="media">
                                    <div className="media-body">
                                        <p className="f-w-500 font-roboto">
                                            {'TAXA OCUPAÇÃO'}
                                            <Button className="float-right btn-xs" color="primary" onClick={() => history.push('/propriedades')}>{"Ver"}</Button>
                                        </p>
                                        <div className="progress-box">
                                            <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp end={324} /></span></h4>
                                            <div className="progress sm-progress-bar progress-animate app-right d-flex justify-content-end">
                                                <div className="progress-gradient-primary" role="progressbar" style={{ width: "35%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"><span className="font-primary"></span><span className="animate-circle"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="4" lg="4" md="12" className="box-col-6">
                        <Card className="o-hidden">
                            <CardBody>
                                <div className="media">
                                    <div className="media-body">
                                        <p className="f-w-500 font-roboto">
                                            {'FEES'}
                                            <Button className="float-right btn-xs" color="primary" onClick={() => history.push('/balancos')}>{"Ver"}</Button>
                                        </p>
                                        <div className="progress-box">
                                            <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp end={3300} /></span>{"€"}</h4>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </Container>
        </Fragment>
    );
}

export default Default;