import { actionTypes } from "./";

const INIT_STATE = {
    loaded: false,
    authenticated: false,
    user: null,
    clients: [],
    moment: 'present',
    notifications: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_MOMENT:
            return {
                ...state,
                ...action.payload,
                moment: action.payload ? action.payload : state.user,
            };
        case actionTypes.SET_USER:
            return {
                ...state,
                ...action.payload,
                user: action.payload ? action.payload : state.user,
                loaded: action.payload.loaded ? action.payload.loaded : true,
                authenticated: action.payload.authenticated ? action.payload.authenticated : true,
            };
        case actionTypes.SET_CLIENTS:
            return {
                ...state,
                ...action.payload,
                clients: action.payload ? action.payload : state.user,
            };
        case actionTypes.SET_NEW_NOTIFICATION:
            return {
                ...state,
                notifications: [action.payload, ...state.notifications]
            };
        case actionTypes.MARK_NOTIFICATION_AS_READ:
            return {
                ...state,
                notifications: state.notifications.filter((notif) => notif._id !== action.payload._id)
            };
        case actionTypes.REFRESH_TODOS:
            return {
                ...state,
                todo: [...action.payload.todo]
            };
        default:
            return { ...state };
    }
};
