import React, { Fragment, useState } from "react";
import { toast } from 'react-toastify';
import { Col, Row, CardHeader, Card, Table, Button, CardBody, CardFooter } from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import NoResults from '../../../ui-kits/noResults';

import Filepond from "../../../filepond";

const Documents = ({ data, supplierId, refetch }) => {
    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const deleteRequest = (file) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/uploads/suppliers/${supplierId}/${file._id}`)
                        .then((result) => {
                            // const newDocuments = data.documents.filter((doc) => doc._id !== file._id);
                            // data.documents = newDocuments;

                            refetch();

                            toast.success("Documento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error("Ocorreu um erro ao apagar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }
    
    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/suppliers/${supplierId}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    refetch();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    setFileLoading(false);

                    setCustomFiles(null);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return data ? (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <h5>{'Documentos do fornecedor'}</h5>
                        </CardHeader>
                        <div className="table-responsive">
                            {data.documents.length > 0 ? (
                                <Table style={{ minHeight: 200 }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Uploaded"}</th>
                                            <th scope="col">{"Name"}</th>
                                            <th scope="col">{"Type"}</th>
                                            <th scope="col">{"Extension"}</th>
                                            <th scope="col">{"Options"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.documents.map((file) => (
                                            <tr key={file._id}>
                                                <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                <td>{file.origName}</td>
                                                <td>{file.classification}</td>
                                                <td>{file.origName.split('.').pop()}</td>
                                                <td>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file)}><i className="fa fa-trash"></i></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <NoResults />
                            )}
                        </div>
                    </Card>
                </Col>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <h5>{'Upload de Ficheiros'}</h5>
                        </CardHeader>
                        <CardBody>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                        </CardBody>
                        <CardFooter>
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                    <Button className="ml-2" color="secondary" onClick={() => setCustomFiles(null)}>
                                        {"Cancelar"}
                                    </Button>
                                </>
                            )}
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Fragment >
    ) : null;
};

export default Documents;