import React, { Fragment } from "react";
import { CardBody, Card, Table } from 'reactstrap';

import TransanctionRow from "../../transactions/TransanctionRow";

function comparator(a, b) {
    if (a.monthAndYear < b.monthAndYear) {
        return 1;
    }
    if (a.monthAndYear > b.monthAndYear) {
        return -1;
    }
    return 0;
}

const Despesas = ({ data, transaction_sheets, toggleModal, deleteTransaction }) => {
    return data ? (
        <Fragment>
            <Card>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Data</th>
                                <th>Descritivo</th>
                                <th>Tipo</th>
                                <th>Valor</th>
                                <th>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transaction_sheets.data && transaction_sheets.data.results.sort(comparator).map((thisSheet, index) => (
                                <TransanctionRow
                                    key={index}
                                    type="despesas"
                                    transactions={thisSheet.transactions.filter((elTransaction) => elTransaction.tag === " " || elTransaction.tag === "" || elTransaction.tag === "fee")}
                                    contract={data.contract}
                                    thisSheet={thisSheet}
                                    toggleModal={toggleModal}
                                    deleteTransaction={deleteTransaction}
                                />
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    ) : null;
};

export default Despesas;