import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Col, CardBody, Form } from 'reactstrap';
import moment from 'moment'

const Geral = ({ data }) => {
    return data ? (
        <Fragment>
            <Form
                className="card theme-form needs-validation"
                noValidate=""
            >
                <CardBody>
                    <div className="list-persons row">
                        <Col xl="6">
                            <div className="profile-mail" style={{ padding: 0 }}>
                                <div className="email-general" style={{ padding: 0 }}>
                                    <h6 className="mb-3">{data.contract.rentalNumber}</h6>
                                    <hr />
                                    <ul>
                                        {data.contract.ATRegister && data.contract.ATRegister.registered && (
                                            <li>Registo AT <span className="font-primary">{data.contract.ATRegister.value} em {moment(data.contract.ATRegister.date).format('DD/MM/YYYY')}</span></li>
                                        )}
                                        <li>Renda {data.contract.rent.periodicity === 'monthly' && '( Mensal )'}</li>
                                        <li>Bruto <span className="font-primary">{(data.contract.rent.value).toFixed(2)} € {data.contract.rent.retention && '( Retenção Fonte )'}</span></li>
                                        <li>Líquido <span className="font-primary">{(data.contract.rent.liquidValue).toFixed(2)} €</span></li>
                                        <hr />
                                        <li>Inicio a <span className="font-primary">{moment(data.contract.startDate).format('DD/MM/YYYY')}</span></li>
                                        <li>Termina a <span className="font-primary">{moment(data.contract.endDate).format('DD/MM/YYYY')}</span></li>
                                        <li>Vai Renovar <span className="font-primary">{data.contract.renewable ? 'Sim' : 'Não'}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xl="6">
                            <div className="profile-mail" style={{ padding: 0 }}>
                                <div className="email-general" style={{ padding: 0 }}>
                                    <Link to={{
                                        pathname: `${process.env.PUBLIC_URL}/propriedade/${data.contract.property._id}`,
                                        state: { fromUrl: `/contrato/${data.contract._id}` }
                                    }}>
                                        <h6 className="mb-3">Propriedade {data.contract.property.pmName}</h6>
                                    </Link>

                                    <hr />
                                    <ul>
                                        <li>Morada <span className="font-primary">{data.contract.property.address}</span></li>
                                        <li>Fracção <span className="font-primary">{data.contract.property.fractionOrLetter ? data.contract.property.fractionOrLetter : '-'}</span></li>
                                        <li>Código Postal <span className="font-primary">{data.contract.property.postalCode}</span></li>
                                        <li>Distrito <span className="font-primary">{data.contract.property.district}</span></li>
                                        {/* <li>País <span className="font-primary">{data.contract.property.country}</span></li> */}
                                        <li>Composição <span className="font-primary">{data.contract.property.composition}</span></li>
                                        <li>Topologia <span className="font-primary">{data.contract.property.typology}</span></li>
                                        {data.contract.property.state.forRent ? (
                                            <li className="font-success">{'Disponível'}</li>
                                        ) : (
                                            <li className="font-danger">{'Indisponível'}</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </div>
                </CardBody>
            </Form>
        </Fragment>
    ) : null;
};

export default Geral;