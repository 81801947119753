import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { CardBody, Form } from 'reactstrap';
import moment from 'moment'

const Geral = ({ data }) => {
    return data ? (
        <Fragment>
            <Form
                className="card theme-form needs-validation"
                noValidate=""
            >
                <CardBody>
                    <div className="list-persons row">
                        <div className="profile-mail" style={{ padding: 0 }}>
                            <div className="email-general" style={{ padding: 0 }}>
                                <h6 className="mb-3">{'Informação'}</h6>
                                <ul>
                                    {data.company ? (
                                        <>
                                            <li>{'Name'} <span className="font-primary">{data.name}</span></li>
                                            <li>
                                                {'NIPC'}
                                                {data.documents.map((file) => {
                                                    return (
                                                        file.classification === "if" && (
                                                            <Link key={file._id} className="btn btn-xs btn-primary ml-2" to={'https://property360.dokky2.cottonhat.net' + file.path} target="_blank" rel="noopener noreferrer">Ver</Link>
                                                        )
                                                    )
                                                })}
                                                <span className="font-primary">
                                                    {data.IF}
                                                </span>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li>Nome <span className="font-primary first_name_0">{data.name} - {data.gender}</span></li>
                                            <li>{'Naturalidade'} <span className="font-primary first_name_0">{data.placeOfBirth}</span></li>
                                            <li>{'Data de Nascimento'} <span className="font-primary first_name_0">{data.birthDate && data.birthDate !== "" && moment(data.birthDate).format('DD/MM/YYYY')}</span></li>
                                            <li>
                                                {'NIF'}
                                                {data.documents.map((file) => {
                                                    return (
                                                        file.classification === "if" && (
                                                            <Link key={file._id} className="btn btn-xs btn-primary ml-2" to={'https://property360.dokky2.cottonhat.net' + file.path} target="_blank" rel="noopener noreferrer">Ver</Link>
                                                        )
                                                    )
                                                })}
                                                <span className="font-primary">
                                                    {data.IF}
                                                </span>
                                            </li>
                                            <li>{'Endereço'} <span className="font-primary email_add_0">{data.address}</span></li>
                                            <li>
                                                {'Número Identificação'}
                                                {data.documents.map((file) => {
                                                    return (
                                                        file.classification === "nationalId" && (
                                                            <Link key={file._id} className="btn btn-xs btn-primary ml-2" to={'https://property360.dokky2.cottonhat.net' + file.path} target="_blank" rel="noopener noreferrer">Ver</Link>
                                                        )
                                                    )
                                                })}
                                                <span className="font-primary">
                                                    {data.identification.nationalId}
                                                </span>
                                            </li>
                                            <li>
                                                {'Passaporte Nº'}
                                                {data.documents.map((file) => {
                                                    return file.classification === "passport" && (
                                                        <Link key={file._id} className="btn btn-xs btn-primary ml-2" to={'https://property360.dokky2.cottonhat.net' + file.path} target="_blank" rel="noopener noreferrer">Ver</Link>
                                                    )
                                                })}
                                                <span className="font-primary">
                                                    {data.identification.nationalId}
                                                </span>
                                            </li>
                                            {data.idType === 'passport' && data.idType === 'bi' && data.idType === 'cr' && (
                                                <li>{'Emitido Em'} <span className="font-primary email_add_0">{data.identification.issuedOn ? moment(data.identification.issuedOn).format('DD/MM/YYYY') : null}</span></li>
                                            )}
                                            {/* <li>{'Local de Emissão'} <span className="font-primary email_add_0">{data.identification.placeOfIssue}</span></li> */}
                                            <li>{'Válido Até'} <span className="font-primary email_add_0">{data.identification.validity ? moment(data.identification.validity).format('DD/MM/YYYY') : null}</span></li>
                                            <li>{'Emitido Por'} <span className="font-primary email_add_0">{data.identification.issuedBy}</span></li>
                                        </>
                                    )}
                                    <hr />
                                    <li>{'Email'} <span className="font-primary email_add_0">{data.contacts.email}</span></li>
                                    <li>{'Outro'} <span className="font-primary email_add_0">{data.contacts.other}</span></li>
                                    <li>{'Telefone'} <span className="font-primary email_add_0">{data.contacts.phone}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Form>
        </Fragment>
    ) : null;
};

export default Geral;