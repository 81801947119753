import React, { Fragment, useState, useEffect } from 'react';
import { LogIn, User, Minimize } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";

import { setTranslations, setDefaultLanguage, setLanguageCookie, translate } from 'react-switch-lang';

import Bookmark from "../../layout/bookmark";

import man from '../../assets/images/dashboard/profile.jpg';

import { LogOut } from '../../constant';

import en from '../../assets/i18n/en.json';
import es from '../../assets/i18n/es.json';
import pt from '../../assets/i18n/pt.json';
import fr from '../../assets/i18n/fr.json';
import du from '../../assets/i18n/du.json';
import cn from '../../assets/i18n/cn.json';
import ae from '../../assets/i18n/ae.json';

import { setUser } from "../../redux/actionTypes";

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage('pt');
setLanguageCookie();

const Rightbar = () => {
    const dispatch = useDispatch();

    const user = useSelector(content => content.User.user);

    const _mainRole = useSelector(content => content.User._mainRole);

    const history = useHistory();

    const [moonlight, setMoonlight] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("layout_version") === "dark-only") {
            setMoonlight(true)
        }
    }, []);

    const Logout = () => {
        axios
            .post(`/api/accounts/logout`)
            .then(() => {
                let resetUser = {
                    loaded: false,
                    authenticated: false,
                    user: null,
                }
                dispatch(setUser(resetUser));
                history.push(`${process.env.PUBLIC_URL}/login`)
                localStorage.removeItem('bookmark')
            })
            .catch((error) => {
                console.error(error)
            });
    }

    const UserMenuRedirect = (redirect) => {
        history.push(redirect)
    }

    //full screen function
    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    const MoonlightToggle = (light) => {
        if (light) {
            setMoonlight(!light)
            document.body.className = "light"
            localStorage.setItem('layout_version', 'light');
        } else {
            setMoonlight(!light)
            document.body.className = "dark-only"
            localStorage.setItem('layout_version', 'dark-only');
        }
    }

    return (
        <Fragment>
            <div className="nav-right col-8 pull-right right-header p-0">
                <ul className="nav-menus">
                    {/* <Bookmark /> */}
                    <li>
                        <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
                    </li>
                    <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Minimize /></a></li>
                    <li className="profile-nav onhover-dropdown p-0">
                        <div className="media profile-media">
                            {/* <img className="b-r-10" src={user && user.profilePhoto ? user.profilePhoto : man} alt="profile-pic" /> */}
                            {/* <img className="b-r-10" src={user ? man : man} alt="man" /> */}
                            <div className="media-body"><span>{user && user.name && `${user.name.first} ${user.name.last}`}</span>
                                <p className="mb-0 font-roboto">{user && _mainRole} <i className="middle fa fa-angle-down"></i></p>
                            </div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div">
                            <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/minha-conta`)}><User /><span>Minha Conta</span></li>
                            <li onClick={user && Logout}><LogIn /><span>{LogOut}</span></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </Fragment>

    );
}

export default translate(Rightbar);