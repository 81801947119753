export const apiUrl = "/api/contracts";
export const rqModel = "contracts";
export const pathname = `${process.env.PUBLIC_URL}/contratos`;

export const schema = [
    {
        colSize: "6",
        label: "Número de Referência",
        name: "rentalNumber",
        id: "rentalNumber",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "6",
        label: "Imóvel",
        name: "property",
        id: "property",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        options: null,
        optionLabel: "pmName",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/properties",
            redux: null,
        },
    },
    {
        colSize: "6",
        label: "Proprietário",
        name: "owners",
        id: "owners",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        disabled: true, //! default TRUE
        backendPopulated: true,
        api: {
            route: "/api/clients",
            redux: null,
        },
    },
    {
        colSize: "6",
        label: "Arrendatários",
        name: "tenants",
        id: "tenants",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        backendPopulated: true,
        api: {
            route: "/api/clients",
            redux: null,
        },
    },
    {
        colSize: "6",
        label: "Fiadores",
        name: "guarantors",
        id: "guarantors",
        type: "select",
        // required: true,
        // requiredMessage: "Este campo é obrigatório",
        multi: true,
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        api: {
            route: "/api/clients?form=true",
            redux: null,
        },
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        colSize: "4",
        label: "Data de Inicio",
        name: "startDate",
        id: "startDate",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Data de Fim",
        name: "endDate",
        id: "endDate",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Renovavel",
        name: "renewable",
        id: "renewable",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "true",
                label: "Sim",
            },
            {
                value: "false",
                label: "Não",
            },
        ],
        defaultValue: "false",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        colSize: "2",
        label: "Valor Mensal (€)",
        name: "rent.value",
        id: "rent.value",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "2",
        label: "Retenção",
        name: "rent.retention",
        id: "rent.retention",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "true",
                label: "Sim",
            },
            {
                value: "false",
                label: "Não",
            },
        ],
        defaultValue: "false",
    },
    {
        colSize: "2",
        label: "Rendas Pagas Inicial",
        name: "numberInitialRent",
        id: "numberInitialRent",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "2",
        label: "Caução",
        name: "guarantee",
        id: "guarantee",
        type: "number",
        step: 0.01,
    },
    {
        type: "component",
        colSize: "10",
        name: "col",
        id: "col",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        colSize: "4",
        label: "PM Fee",
        name: "fees.base",
        id: "fees.base",
        type: "number",
        step: 0.01,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Fee Imobiliaria",
        name: "fees.realEstate",
        id: "fees.realEstate",
        type: "number",
        step: 0.01,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Fee Honorários",
        name: "fees.honor",
        id: "fees.honor",
        type: "number",
        step: 0.01,
        required: true,
        requiredMessage: "Este campo é obrigatório"
    },
];

export const expensesModalForm = [
    {
        colSize: "3",
        label: "Valor",
        name: "value",
        id: "value",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "3",
        label: "Tipo",
        name: "tag",
        id: "tag",
        type: "select",
        multi: false,
        required: true,
        requiredMessage: "Este campo é obrigatório",
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: " ",
                label: "Despesa",
            },
            {
                value: "refund",
                label: "Reembolso",
            },
            {
                value: "captivation",
                label: "Cativação",
            },
            {
                value: "pp",
                label: "Pagamento Proprietário",
            },
            {
                value: "fee",
                label: "Fee",
            },
        ],
    },
    {
        colSize: "3",
        label: "Data da Despesa",
        name: "date",
        id: "date",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "3",
        label: "Data do Pagamento",
        name: "paymentDate",
        id: "paymentDate",
        type: "datepicker",
        showYears: true,
    },
    {
        colSize: "4",
        label: "Ticket",
        name: "ticket",
        id: "ticket",
        type: "select",
        multi: false,
        optionLabel: "subject",
        optionValue: "id",
        options: null,
        api: {
            route: "/api/v2/tickets",
            redux: null,
            type: "freshdesk",
        },
    },
    {
        colSize: "4",
        label: "Tag",
        name: "type",
        id: "type",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "repair",
                label: "Manutenção/Reparação",
            },
            {
                value: "condominio",
                label: "Condominio",
            },
            {
                value: "seguro",
                label: "Seguro",
            },
            {
                value: "impostos",
                label: "Impostos",
            }
        ],
        controller: [
            {
                onSelect: "repair",
                show: "repair",
            },
            {
                onSelect: "condominio",
                show: null,
            },
            {
                onSelect: "seguro",
                show: null,
            },
            {
                onSelect: "impostos",
                show: null,
            },
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Reparação",
        name: "repair",
        id: "repair",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        options: null,
        optionLabel: "name",
        optionValue: "_id",
        backendPopulated: true,
        // api: {
        //     route: "/api/suppliers",
        //     redux: null,
        // },
    },
    {
        colSize: "6",
        label: "Cativação Associada",
        name: "related.transactionId",
        id: "related.transactionId",
        type: "select",
        multi: false,
        optionLabel: "name",
        optionValue: "_id",
        options: [
            {
                _id: null,
                name: "Nenhuma",
            },
        ],
        defaultValue: "null"
    },
    {
        type: "component",
        colSize: "6",
        name: "col",
        id: "col",
    },
    {
        colSize: "12",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "description",
        id: "description",
        type: "textarea",
    },
];

export const monthlyPayForm = [
    {
        colSize: "12",
        label: "Valor",
        name: "value",
        id: "value",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "6",
        label: "Data Referencia",
        name: "dateMonthRef",
        id: "dateMonthRef",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
        // editDisabled: true,
        dateFormat: "MM/yyyy"
    },
    {
        colSize: "6",
        label: "Data do Pagamento",
        name: "dateOfPayment",
        id: "dateOfPayment",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Múltiplo",
        name: "multiple",
        id: "Múltiplo",
        type: "checkbox",
    },
];

export const propertyPayForm = [
    {
        colSize: "12",
        label: "Valor",
        name: "value",
        id: "value",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        step: 0.01,
    },
    {
        colSize: "6",
        label: "Data Referencia",
        name: "dateMonthRef",
        id: "dateMonthRef",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
        // editDisabled: true,
        dateFormat: "MM/yyyy"
    },
    {
        colSize: "6",
        label: "Data do Pagamento",
        name: "dateOfPayment",
        id: "dateOfPayment",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    // {
    //     colSize: "6",
    //     label: "Fechar Mês",
    //     name: "closed",
    //     id: "Closed",
    //     type: "checkbox",
    // },
];

export const finishContractForm = [
    {
        colSize: "6",
        label: "Data de Fim (Todo o mes será contabilizado)",
        name: "date",
        id: "date",
        type: "datepicker",
        showYears: true,
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
];
