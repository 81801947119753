import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import { CreditCard, Home, Percent } from 'react-feather';
import { useQuery } from 'react-query';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';
import BalanceRow from './BalanceRow';

import { getWithFilters } from "../../api/";
import { getRealRentToBePaid, getTotalCaptivations, getTotalDespesas, getTotalGuarantee, getTotalPagProp, rqModel } from "./settings";

function comparator(a, b) {
    if (a.rentalNumber < b.rentalNumber) {
        return -1;
    }
    if (a.rentalNumber > b.rentalNumber) {
        return 1;
    }
    return 0;
}

function getTotalPayments(thisSheet) {
    let totalPayments = 0;

    thisSheet.payments.forEach((payment) => {
        if (payment && payment.value) totalPayments += payment.value;
    });

    return totalPayments
}

const Balances = () => {
    const history = useHistory();

    const [contractsData, setContractsData] = useState(undefined);
    const contractsQuery = useQuery([rqModel], () => getWithFilters({ apiUrl: '/api/contracts', filters: { balance: true, moment: localStorage.getItem('fakeMoment') } }), { keepPreviousData: true, staleTime: 300000 });

    const [state, setState] = useState("active");
    const [filter, setFilter] = useState("");

    if (contractsQuery.error && contractsQuery.error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${contractsQuery.error.response.status}`);
    } else if (contractsQuery.error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    useEffect(() => {
        if (contractsQuery.data && contractsQuery.status === 'success') {
            setContractsData(contractsQuery.data.results.filter((contract) => contract.state === "active"))
        }
    }, [contractsQuery])

    const filterList = (filter, state) => {
        let contracts = contractsQuery.data.results;

        setState(state);
        setFilter(filter);

        if (state === "active") {
            contracts = contractsQuery.data.results.filter((contract) => contract.state === "active");
        } else if (state === "ceased") {
            contracts = contractsQuery.data.results.filter((contract) => contract.state === "ceased");
        } else {
            contracts = contractsQuery.data.results;
        }

        if (filter === 'not_paid') {
            const newData = contracts.filter((contract) => {
                return (getRealRentToBePaid(contract) - getTotalDespesas(contract) - getTotalCaptivations(contract) + (contract.sheet && contract.sheet.remainder ? contract.sheet.remainder : 0) + getTotalGuarantee(contract) + getTotalPagProp(contract)).toFixed(2) > 0
            });


            setContractsData(newData);
        } else if (filter === 'paid') {
            const newData = contracts.filter((contract) => {
                return (getRealRentToBePaid(contract) - getTotalDespesas(contract) - getTotalCaptivations(contract) + (contract.sheet && contract.sheet.remainder ? contract.sheet.remainder : 0) + getTotalGuarantee(contract) + getTotalPagProp(contract)).toFixed(2) < 0
            });


            setContractsData(newData);
        } else if (filter === 'not_paid_expense') {
            const newData = contracts.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => (transaction.tag === "" || transaction.tag === " ") && !transaction.paymentDate));


            setContractsData(newData);
        } else if (filter === 'not_paid_rent') {
            const newData = contracts.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "rent" && Math.abs(transaction.value).toFixed(2) !== Math.abs(getTotalPayments(contract.sheet)).toFixed(2)));


            setContractsData(newData);
        } else if (filter === 'not_paid_fee') {
            const newData = contracts.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "fee" && !transaction.paymentDate));


            setContractsData(newData);
        } else if (filter === "paid_rent") {
            const newData = contracts.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "rent" && Math.abs(transaction.value).toFixed(2) === Math.abs(getTotalPayments(contract.sheet)).toFixed(2)));


            setContractsData(newData);
        } else if (filter === "") {
            setContractsData(contracts);
        } else {
            contractsQuery.refetch();
        }
    }

    const getTotalPaidRent = () => {
        const newData = contractsQuery.data.results.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "rent" && Math.abs(transaction.value).toFixed(2) === Math.abs(getTotalPayments(contract.sheet)).toFixed(2)));
        return newData.length;
    }

    const getTotalNotPaidRent = () => {
        const newData = contractsQuery.data.results.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "rent" && Math.abs(transaction.value).toFixed(2) !== Math.abs(getTotalPayments(contract.sheet)).toFixed(2)));
        return newData.length;
    }

    const getTotalNotPaidExpenses = () => {
        const newData = contractsQuery.data.results.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => (transaction.tag === "" || transaction.tag === " ") && !transaction.paymentDate));
        return newData.length;
    }

    const getTotalNotPaidFees = () => {
        const newData = contractsQuery.data.results.filter((contract) => contract.sheet && contract.sheet.transactions.some((transaction) => transaction.tag === "fee" && !transaction.paymentDate));
        return newData.length;
    }

    return (
        <Fragment>
            <Breadcrumb title="Balanços" />
            <Container fluid={true}>
                <div className="feature-products">
                    <Row>
                        <Col md="6" className="products-total" />
                        <Col md="6" className="text-right">
                            <span className="f-w-600 m-r-5">{"Estado"}</span>
                            <div
                                style={{ marginRight: 20 }}
                                className="select2-drpdwn-product select-options d-inline-block"
                                onChange={(e) => {
                                    filterList(filter, e.target.value)
                                }}
                            >
                                <select className="form-control btn-square" name="select">
                                    <option value="" selected={state === ""}>{"Todos"}</option>
                                    <option value="active" selected={state === "active"}>{"Ativos"}</option>
                                    <option value="ceased" selected={state === "ceased"}>{"Inativos"}</option>
                                </select>
                            </div>
                            <span className="f-w-600 m-r-5">{"Filtro"}</span>
                            <div
                                className="select2-drpdwn-product select-options d-inline-block"
                                onChange={(e) => {
                                    filterList(e.target.value, state)
                                }}
                            >
                                <select className="form-control btn-square" name="select">
                                    <option value="" selected={filter === ""}>{"Todos"}</option>
                                    <option value="paid_rent" selected={filter === "paid_rent"}>{"Rendas Pagas"}</option>
                                    <option value="not_paid_rent" selected={filter === "not_paid_rent"}>{"Renda por Pagar"}</option>
                                    <option value="not_paid_expense" selected={filter === "not_paid_expense"}>{"Despesas por Pagar"}</option>
                                    <option value="not_paid_fee" selected={filter === "not_paid_fee"}>{"Fees por Pagar"}</option>
                                    <option value="not_paid" selected={filter === "not_paid"}>{"Positivo"}</option>
                                    <option value="paid" selected={filter === "paid"}>{"Negativo"}</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md="3">
                        <Card>
                            <CardBody>
                                <Media className="faq-widgets">
                                    <Media body>
                                        <span>{'Rendas Pagas'}</span>
                                        {!contractsQuery.isLoading ? <h3>{getTotalPaidRent()}</h3> : <h3>0</h3>}
                                    </Media>
                                    <i className="fa fa-bank" style={{ fontSize: 32, color: "#959595" }}></i>
                                </Media>
                            </CardBody>
                            <button className="btn btn-xs btn-outline-primary" style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => filterList("paid_rent", state)}>Ver</button>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                            <CardBody>
                                <Media className="faq-widgets">
                                    <Media body>
                                        <span>{'Rendas por pagar'}</span>
                                        {!contractsQuery.isLoading ? <h3>{getTotalNotPaidRent()}</h3> : <h3>0</h3>}
                                    </Media>
                                    <i className="fa fa-building" style={{ fontSize: 32, color: "#959595" }}></i>
                                </Media>
                            </CardBody>
                            <button className="btn btn-xs btn-outline-primary" style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => filterList("not_paid_rent", state)}>Ver</button>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                            <CardBody>
                                <Media className="faq-widgets">
                                    <Media body>
                                        <span>{'Despesas por pagar'}</span>
                                        {!contractsQuery.isLoading ? <h3>{getTotalNotPaidExpenses()}</h3> : <h3>0</h3>}
                                    </Media>
                                    <i className="fa fa-credit-card" style={{ fontSize: 32, color: "#959595" }}></i>
                                </Media>
                            </CardBody>
                            <button className="btn btn-xs btn-outline-primary" style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => filterList("not_paid_expense", state)}>Ver</button>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card>
                            <CardBody>
                                <Media className="faq-widgets">
                                    <Media body>
                                        <span>{'Fees por pagar'}</span>
                                        {!contractsQuery.isLoading ? <h3>{getTotalNotPaidFees()}</h3> : <h3>0</h3>}
                                    </Media>
                                    <i className="fa fa-euro" style={{ fontSize: 32, color: "#959595" }}></i>
                                </Media>
                            </CardBody>
                            <button className="btn btn-xs btn-outline-primary" style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => filterList("not_paid_fee", state)}>Ver</button>
                        </Card>
                    </Col>
                </Row>
                {!contractsQuery.isLoading && contractsData ? (
                    <>
                        {contractsData.length > 0 ? contractsData.sort(comparator).map((contract) => (
                            <BalanceRow contract={contract} sheet={contract.sheet} />
                        )) : (
                            <NoResults />
                        )}
                    </>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Balances