import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import DataTable from 'react-data-table-component';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';

import DataTableExtensions from 'react-data-table-component-extensions';

import { useDelete } from '../../hooks/';

import CustomForm from "../form";
import { getWithFilters } from "../../api/";
import { apiUrl, rqModel, pathname, editSchema, schema } from "./settings";

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.condominiumIBAN = el.condominium && el.condominium.IBAN ? el.condominium.IBAN : null
        el.condominiumCondoId = el.condominium && el.condominium.condoId ? el.condominium.condoId : null
        el.condominiumContact = el.condominium && el.condominium.contact ? el.condominium.contact : null
        el.condominiumMonthlyValue = el.condominium && el.condominium.monthlyValue ? el.condominium.monthlyValue.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
        }) : null
        el.condominiumObs = el.condominium && el.condominium.obs ? el.condominium.obs : null

        el.energyCertDescription = el.energyCert && el.energyCert.description ? el.energyCert.description : null
        el.energyCertValidade = el.energyCert && el.energyCert.validity ? moment(el.energyCert.validity).format('DD/MM/YYYY') : null

        el.licenseOfUseDescription = el.licenseOfUse && el.licenseOfUse.description ? el.licenseOfUse.description : null
        el.licenseOfUseEmissionDate = el.licenseOfUse && el.licenseOfUse.emissionDate ? moment(el.licenseOfUse.emissionDate).format('DD/MM/YYYY') : null
        el.licenseOfUseIssuedBy = el.licenseOfUse && el.licenseOfUse.issuedBy ? el.licenseOfUse.issuedBy : null

        el.matrixRegistrationNumber = el.matrixRegistration && el.matrixRegistration.number ? el.matrixRegistration.number : null
        el.matrixRegistrationParish = el.matrixRegistration && el.matrixRegistration.parish ? el.matrixRegistration.parish : null
    })

    data.forEach((el) => {
        delete el.GAManager
        delete el.buildingPermit
        delete el.condominium
        delete el.constPropHorizontal
        delete el.contracts
        delete el.counterRef
        delete el.country
        delete el.dataSheet
        delete el.documents
        delete el.energyCert
        delete el.insurance
        delete el.licenseOfUse
        delete el.owners
        delete el.matrixRegistration
        delete el.propertyDescription
        delete el.propertyInsurance
        delete el.propertyRegistry
        delete el.repairs
        delete el.signatureDate
        delete el.state
        delete el._id
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += Math.abs(item.value);
            } else {
                result += item[key] ? item[key] : '';
            }

            ctr++;
        });

        result += lineDelimiter;
    });

    return result;
}

const Patrimonio = () => {
    const history = useHistory();

    const clients = useSelector((content) => content.User.clients);

    const [currentProperty, setProperty] = useState(null);
    const [viewType, setViewType] = useState(null);
    const [propertyId, setPropertyId] = useState(null);

    const deleteData = useDelete(apiUrl, rqModel, propertyId)

    const getProperties = () => getWithFilters({ apiUrl: '/api/properties' });
    const {
        isLoading,
        data,
        error,
    } = useQuery([rqModel], () => getProperties(), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const toggleTable = () => {
        setViewType(null);
        setProperty(null);
    }

    const createView = () => {
        setViewType("form");
        setProperty(null);
        window.history.pushState(null, null, `${pathname}?view=create`);
    }

    const editView = (property) => {
        setViewType("form");
        setProperty(property);
        window.history.pushState(null, null, `${pathname}?view=edit`);
    }

    const createSuccess = (data) => {
        toast.success(`Propriedade ${data.pmName} criada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editSuccess = (data) => {
        toast.success(`Propriedade ${data.pmName} editada!`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const deleteRequest = async (_id) => {
        await setPropertyId(_id);
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar a Propriedade?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    await deleteData.mutate();
                    if (deleteData.isIdle) {
                        toast.success("Propriedade apagada!", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
            })
    }

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data.results);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });
        if (csv == null) return;

        filename = args.filename || 'export_properties_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    const getOwner = (prop, type) => {
        if (type === 'name') {
            const foundOwner = clients.find((el) => el.name === prop);
            if (foundOwner) {
                return foundOwner
            } else {
                return { name: 'Empty' }
            }
        } else {
            const foundOwner = prop.owners && clients.find((el) => el._id === prop.owners[0]);
            if (foundOwner) {
                return foundOwner
            } else {
                return { name: 'Empty' }
            }
        }
    }

    const columns = [
        {
            name: "PM",
            selector: "pmName",
            sortable: true,
            center: true,
        },
        {
            name: "Proprietário",
            selector: "owner",
            sortable: true,
            center: true,
            cell: d => <a style={{ color: "#0047ab" }} href={`${process.env.PUBLIC_URL}/cliente/${getOwner(d.owner, 'name')._id}`}>{d.owner}</a>
        },
        {
            name: "Estado",
            selector: "state",
            sortable: true,
            center: true,
        },
        {
            name: "Contrato",
            selector: "contract",
            sortable: true,
            center: true,
        },
        {
            name: "Ações",
            selector: "action",
            sortable: false,
            center: true,
            grow: 2,
        },
    ];

    return (
        <Fragment>
            <Breadcrumb title="Património" />
            <Container fluid={true}>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                {viewType === "form" && (
                                    <CardHeader>
                                        <span>{viewType === "form" && !currentProperty ? "Criar Propriedade" : viewType === "form" && currentProperty && "Editar Propriedade"}</span>
                                    </CardHeader>
                                )}
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        {viewType !== "form" && <Button className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>}
                                                        {viewType !== "form" && <Button color="primary" onClick={createView}>{"Criar"}</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {viewType === "form" ? (
                                            <CustomForm data={currentProperty} toggleTable={toggleTable} schema={currentProperty ? editSchema : schema} rqModel={rqModel} route={apiUrl} createSuccess={createSuccess} editSuccess={editSuccess} pathname={pathname} />
                                        ) : (
                                            <div className="table-responsive product-table">
                                                <DataTableExtensions
                                                    columns={columns}
                                                    filterPlaceholder={"Procurar..."}
                                                    filterHidden={false}
                                                    export={false}
                                                    print={false}
                                                    data={data && data.results.map((property) => ({
                                                        pmName: `${property.pmName}`,
                                                        owner: `${getOwner(property, '_id').name}`,
                                                        state: `${property.state?.availability?.available ? 'Não Arrendado' : 'Arrendado'}`,
                                                        contract: `Não Disponível`,
                                                        action: <div>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => editView(property)}><i className="fa fa-pencil"></i></Button>
                                                            <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(property._id)}><i className="fa fa-trash"></i></Button>
                                                        </div>
                                                    }))}
                                                >
                                                    <DataTable
                                                        noHeader
                                                        columns={columns}
                                                        defaultSortField="pmName"
                                                        defaultSortAsc={true}
                                                        data={data && data.results.map((property) => ({
                                                            pmName: `${property.pmName}`,
                                                            owner: `${getOwner(property, '_id').name}`,
                                                            state: `${property.state?.availability?.available ? 'Não Arrendado' : 'Arrendado'}`,
                                                            contract: `Não Disponível`,
                                                            action: <div>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => editView(property)}><i className="fa fa-pencil"></i></Button>
                                                                <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(property._id)}><i className="fa fa-trash"></i></Button>
                                                            </div>
                                                        }))}
                                                        highlightOnHover={true}
                                                        pointerOnHover={true}
                                                        pagination={true}
                                                        paginationPerPage={25}
                                                        noDataComponent={<NoResults />}
                                                        onRowClicked={(row, event) => {
                                                            const findRowId = data.results.find((property) => property.pmName === row.pmName)
                                                            history.push(`${process.env.PUBLIC_URL}/propriedade/${findRowId._id}`, '_blank')
                                                        }}
                                                    />
                                                </DataTableExtensions>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Patrimonio