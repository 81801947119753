import React, { useState } from "react";
import { Col, Container, Row, Table, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import Filepond from "../../../components/filepond";

const ViewSeguro = ({ isOpen, toggle, _id, refetchData, data, propertyId, setViewModalData }) => {
    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    const deleteRequest = (file) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/uploads/insurance/${propertyId}/${data._id}/${file}`)
                        .then((result) => {
                            const newSeguro = { ...data }
                            const newDocuments = newSeguro.documents.filter((doc) => doc._id !== file);
                            newSeguro.documents = newDocuments;

                            setViewModalData(newSeguro);

                            if (refetchData) refetchData();

                            toast.success("Documento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);

                            if (refetchData) refetchData();

                            toast.error("Ocorreu um erro ao apagar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/insurance/${propertyId}/${data._id}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    const newSeguro = { ...data }
                    const newDocuments = [...newSeguro.documents, result.data]
                    newSeguro.documents = newDocuments;

                    setViewModalData(newSeguro);

                    if (refetchData) refetchData();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);

                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return data ? (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                {data.insurer}
            </ModalHeader>
            <ModalBody>
                <Container fluid={true}>
                    <Row>
                        <div className="text-muted mr-1">Número da Apolice</div>
                        <div className="text-info">{data.number}</div>
                    </Row>
                    <Row>
                        <div className="text-muted mr-1">Data de Emissão</div>
                        <div className="text-info">{moment(data.emissionDate).format("DD/MM/YYYY")}</div>
                    </Row>
                    <Row>
                        <div className="text-muted mr-1">Data de Renovação</div>
                        <div className="text-info">{moment(data.renewalDate).format("DD/MM/YYYY")}</div>
                    </Row>
                    <Row>
                        <div className="text-muted mr-1">Valor</div>
                        <div className="text-info">{data.annualValue.toFixed(2)} €</div>
                    </Row>
                </Container>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <div className="table-responsive mt-4">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Uploaded"}</th>
                                            <th scope="col">{"Name"}</th>
                                            <th scope="col">{"Extension"}</th>
                                            <th scope="col">{"Options"}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.documents && data.documents.map((file) => (
                                            <tr key={file._id}>
                                                <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                <td>{file.origName}</td>
                                                <td>{file.origName.split('.').pop()}</td>
                                                <td>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file._id)}><i className="fa fa-trash"></i></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col sm="12" className="mt-4">
                            <h5>{'Upload de Ficheiros'}</h5>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                    <Button className="ml-2" color="secondary" onClick={() => setCustomFiles([])}>
                                        {"Cancelar"}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    ) : null
}

export default ViewSeguro