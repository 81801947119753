import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom'

import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";

import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from 'moment';

import Select from 'react-select';
import { useQuery } from 'react-query';

import Breadcrumb from '../../layout/breadcrumb';
import NoResults from '../ui-kits/noResults';

import { getWithFilters } from "../../api";
import { options } from "./settings";

registerLocale("pt", pt);

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;

    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    //! TEMP
    data.forEach((el) => {
        el.proprietario = el.owners && el.owners.name ? el.owners.name : null

        el.endDate = moment(el.endDate).format('DD/MM/YYYY')

        delete el._id
        delete el.contractId
        delete el.propertyId
        delete el.endValidity
        delete el.flag
        delete el.owners
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                //! TEMP
                result += item.value.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2
                });
            } else {
                result += item[key];
            }

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const Queries = () => {
    const history = useHistory();

    const [currentQuery, setQuery] = useState(options[0]);
    const [monthYear, setMonthYear] = useState('YYYYMM');

    const getQuery = () => getWithFilters({ apiUrl: `${currentQuery.apiUrl}/${monthYear !== 'YYYYMM' ? moment(monthYear).format("YYYYMM") : monthYear}` });
    const {
        isLoading,
        data,
        error,
    } = useQuery([currentQuery.rqModel, monthYear], () => getQuery(), { keepPreviousData: true, staleTime: 300000 });

    if (error && error.response) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error${error.response.status}`);
    } else if (error) {
        history.push(`${process.env.PUBLIC_URL}/pages/errors/error500`);
    }

    const exportCSV = () => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });

        if (csv == null) return;

        filename = `export_seguros_${monthYear}.csv`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <Fragment>
            <Breadcrumb title="Pesquisar" />
            <Container fluid={true}>
                <Row style={{ marginBottom: 25 }}>
                    <Col sm="6">
                        <Select
                            placeholder="Selecionar ..."
                            className="basic-single"
                            classNamePrefix="select"
                            options={options}
                            defaultValue={options[0]}
                            onChange={(values) => {
                                setQuery(values)
                            }}
                        />
                    </Col>
                    <Col sm="4">
                        <DatePicker
                            locale="pt"
                            utcOffset={1}
                            showYearDropdown={true}
                            dateFormat={"MM/yyyy"}
                            autoComplete='off'
                            className="form-control digits"
                            selected={monthYear !== 'YYYYMM' ? monthYear : null}
                            onChange={(date) => {
                                setMonthYear(date);
                            }}
                            showMonthYearPicker={true}
                        />
                    </Col>
                </Row>
                {!isLoading ? (
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <div className="todo">
                                        <div className="todo-list-wrapper">
                                            <div className="todo-list-container">
                                                <div className="mark-all-tasks">
                                                    <div className="mark-all-tasks-container">
                                                        <Button className="mr-2" color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive product-table">
                                            <DataTableExtensions
                                                columns={currentQuery.columns}
                                                filterPlaceholder={"Procurar..."}
                                                filterHidden={false}
                                                export={false}
                                                print={false}
                                                data={data && Array.isArray(data) && data.map((object) => ({
                                                    pmName: object.pmName,
                                                    client: object.owners && object.owners.length > 0 ? object.owners[0].name : object.owners && object.owners.name ? object.owners.name : '',
                                                    endDate: `${moment(object.endDate).format("DD/MM/YYYY")}`,
                                                    value: `${object.value.toFixed(2)} €`,
                                                }))}
                                            >
                                                <DataTable
                                                    keyField={'_id'}
                                                    noHeader
                                                    columns={currentQuery.columns}
                                                    defaultSortField="type"
                                                    defaultSortAsc={true}
                                                    data={data && Array.isArray(data) && data.map((object) => ({
                                                        pmName: object.pmName,
                                                        client: object.owners && object.owners.length > 0 ? object.owners[0].name : object.owners.name,
                                                        endDate: `${moment(object.endDate).format("DD/MM/YYYY")}`,
                                                        value: `${object.value.toFixed(2)} €`,
                                                    }))}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    pagination={true}
                                                    paginationPerPage={25}
                                                    noDataComponent={<NoResults />}
                                                    onRowClicked={(row, event) => {
                                                        const findRowId = data.find((object) => object.name === row.name)
                                                        history.push(`${process.env.PUBLIC_URL}/propriedade/${findRowId.propertyId}?tab=seguro`, { fromUrl: '/pesquisar' })
                                                    }}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    )
}

export default Queries