export const pathname = `${process.env.PUBLIC_URL}/pesquisar`;

export const insuranceColumns = [
  {
    name: "Propriedade",
    selector: "pmName",
    sortable: true,
    center: true,
  },
  {
    name: "Proprietário",
    selector: "client",
    sortable: true,
    center: true,
  },
  {
    name: "Data Final",
    selector: "endDate",
    sortable: true,
    center: true,
  },
  {
    name: "Valor",
    selector: "value",
    sortable: true,
    center: true,
  }
];

export const options = [
  {
    label: "Seguros que acabem no Mês e Ano selecionado",
    value: "insuranceQuery",
    rqModel: "insuranceQuery",
    apiUrl: "/api/properties/insurance_by_month",
    columns: insuranceColumns,
  },
  // {
  //   label: "Seguros que acabem no Mês e Ano selecionado2",
  //   value: "insuranceQuery2",
  //   rqModel: "insuranceQuery2",
  //   apiUrl: "/api/properties/insurance_by_month",
  //   columns: insuranceColumns,
  // },
];
