import React, { Fragment, useState } from 'react';
import { Card, CardBody, Table, Badge, Button } from 'reactstrap';
import moment from 'moment';

import NoResults from "../../../ui-kits/noResults";

import ViewTransactionModal from '../../../balances/transactions/ViewTransactionModal';

function comparator(a, b) {
    if (a.monthAndYear < b.monthAndYear) {
        return 1;
    }
    if (a.monthAndYear > b.monthAndYear) {
        return -1;
    }
    return 0;
}

function convertArrayOfObjectsToCSV(args) {
    let result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    data.forEach((el) => {
        el.date = el.date && el.date ? moment(el.date).format('DD/MM/YYYY') : null
        el.date = el.date && el.date ? moment(el.date).format('DD/MM/YYYY') : null
    });

    //! TEMP
    data.forEach((el) => {
        delete el.documents
        delete el.insurance
        delete el.repair
        delete el.payments
        delete el.related
        delete el._id
    });
    //! TEMP

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            if (key === 'value') {
                result += Math.abs(item.value);
            } else {
                result += item[key];
            }

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const Transactions = ({ data }) => {
    const [payModal, setPayModal] = useState(false);

    const [currentModalId, setCurrentModalId] = useState(null);

    const [currentTransaction, setTransaction] = useState(null);
    const [currentSheet, setSheet] = useState(null);

    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [optState, setOpt] = useState(null); // listagem de pagamentos

    const toggleModal = (type, _id, thisTransaction, thisSheet, opt) => {
        if (type && _id) {
            if (opt) {
                setOpt(opt);
            } else {
                setOpt(null);
            }

            if (type === 'view') {
                setViewModal(!viewModal);
            } else if (type === 'edit') {
                setEditModal(!editModal);
            } else if (type === 'upload') {
                setUploadModal(!uploadModal);
            } else if (type === 'pay') {
                setPayModal(!payModal);
            }

            setTransaction(thisTransaction);
            setSheet(thisSheet);
            setCurrentModalId(_id);
        } else {
            // After form submission
            setOpt(null);

            setViewModal(false);
            setEditModal(false);
            setUploadModal(false);
            setPayModal(false);

            setTransaction(null);
            setSheet(null);
            setCurrentModalId(null);
        }
    }

    const exportCSV = (args) => {
        let dataCSV, filename, link;

        const copyArray = JSON.stringify(data.list);
        let csv = convertArrayOfObjectsToCSV({
            data: JSON.parse(copyArray)
        });
        if (csv == null) return;

        filename = args.filename || 'export_expenses_ppm.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        dataCSV = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', dataCSV);
        link.setAttribute('download', filename);
        link.click();
    }

    return (
        <Fragment>
            <Button style={{ display: 'block', marginLeft: 'auto', marginBottom: '15px' }} color="primary" onClick={exportCSV}>{"Exportar CSV"}</Button>
            <Card>
                <CardBody>
                    {data && data.list && data.list.length > 0 ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Descritivo</th>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.list && data.list.sort(comparator).map((thisTransaction, index) => (
                                    <tr key={thisTransaction._id}>
                                        <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                        <td>{thisTransaction.name}</td>
                                        <td>
                                            {thisTransaction.tag === 'captivation' ? (
                                                <Badge color="light">
                                                    Cativação
                                                </Badge>
                                            ) : thisTransaction.tag === 'fee' ? (
                                                <>
                                                    <Badge color="warning">
                                                        Fee
                                                    </Badge>
                                                    {thisTransaction.paymentDate ? (
                                                        <Badge color="success">Pago</Badge>
                                                    ) : null}
                                                </>
                                            ) : thisTransaction.tag === 'guarantee' ? (
                                                <Badge color="primary">
                                                    Caução
                                                </Badge>
                                            ) : (
                                                <>
                                                    <Badge color="info">
                                                        Despesa
                                                    </Badge>
                                                    {thisTransaction.paymentDate ? (
                                                        <Badge color="success">Pago</Badge>
                                                    ) : null}
                                                </>

                                            )}
                                        </td>
                                        <td>{Math.abs(thisTransaction.value).toFixed(2)} €</td>
                                        <td>
                                            <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, data.sheet)}><i className="fa fa-eye"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <NoResults />
                    )}
                </CardBody>
            </Card>

            <ViewTransactionModal _id={currentModalId} setTransaction={setTransaction} transaction={currentTransaction} setSheet={setSheet} currentSheet={currentSheet} isOpen={viewModal} toggle={(type, _id) => toggleModal(type, _id)} opt={optState} />
        </Fragment>
    );
}

export default Transactions;