import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import Filepond from "../../../components/filepond";

const ViewTransanction = ({ isOpen, toggle, _id, setTransaction, transaction, setSheet, currentSheet, refetchData, opt }) => {
    const [currentTicket, setCurrentTicket] = useState(null); //* FILEPOND

    const [files, setFiles] = useState(null); //* FILEPOND
    const [fileLoading, setFileLoading] = useState(false);
    const [fileError, setFileError] = useState(null);

    useEffect(() => {
        if (transaction && transaction.ticket && !currentTicket) {
            axios
                .get(`https://rede360.freshdesk.com/api/v2/tickets/${transaction.ticket}`, { auth: { username: 'k3tVzTjZa0XEHTDbDowJ', password: null } })
                .then((result) => {
                    setCurrentTicket(result.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [currentTicket, transaction])

    useEffect(() => {
        return () => {
            if (!isOpen) setCurrentTicket(null);
        }
    }, [isOpen])



    const deletePayment = (payment) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o pagamento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/transaction_sheets/${currentSheet._id}/payments/${payment._id}`)
                        .then((result) => {
                            const newSheet = { ...currentSheet }
                            const newDocuments = newSheet.payments.filter((doc) => doc._id !== payment._id);
                            newSheet.payments = newDocuments;

                            setSheet(newSheet);

                            if (refetchData) refetchData();

                            toast.success("Pagamento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error("Ocorreu um erro ao apagar o pagamento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const deleteRequest = (file) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/uploads/${opt === "sheet" ? 'contracts' : 'transactions'}/${opt === "sheet" ? currentSheet._id : transaction._id}/${file}`)
                        .then((result) => {
                            if (opt !== "sheet") {
                                const newTransaction = { ...transaction }
                                const newDocuments = newTransaction.documents.filter((doc) => doc._id !== file);
                                newTransaction.documents = newDocuments;

                                setTransaction(newTransaction);
                            }

                            if (refetchData) refetchData();

                            toast.success("Documento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);

                            if (refetchData) refetchData();

                            toast.error("Ocorreu um erro ao apagar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    const setCustomFiles = (files) => {
        setFiles(files) //* FILEPOND
    }

    const uploadDocument = () => {
        setFileLoading(true);

        let submitValues = new FormData();
        submitValues.append('file[name]', files.name);
        submitValues.append('file', files);
        axios.post(`/api/uploads/${opt === "sheet" ? 'transaction_sheets' : 'transactions'}/${opt === "sheet" ? currentSheet._id : transaction._id}`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    if (opt !== "sheet") {
                        const newTransaction = { ...transaction, documents: [...transaction.documents, result.data[0]] }
                        setTransaction(newTransaction);
                    }

                    if (refetchData) refetchData();

                    toast.success(`Uploaded feito com sucesso!`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLoading(false);
                } else {
                    setFileLoading(false);
                    setFileError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setFileError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);

                setFileLoading(false);
                setFileError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setFileError(null);
                }, 3000);
            });
    }

    return transaction ? (
        <Modal size={'lg'} isOpen={isOpen} toggle={() => toggle('view', _id)}>
            <ModalHeader toggle={() => toggle('view', _id)}>
                {opt === 'sheet' ? (
                    <>Upload</>
                ) : (
                    <>{transaction.name} {Math.abs(transaction.value).toFixed(2)} || {moment(transaction.date).format('MMMM YYYY')}</>
                )}
            </ModalHeader>
            <ModalBody>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <div className="table-responsive mb-4">
                                {transaction.tag === 'rent' && (
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Data</th>
                                                <th>Valor Pago</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentSheet.payments.map((lesPayments, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{index}</th>
                                                    <td>{moment(lesPayments.dateOfPayment).format('DD/MM/YYYY')}</td>
                                                    <td>{Math.abs(lesPayments.value).toFixed(2)}</td>
                                                    <td>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => deletePayment(lesPayments)}><i className="fa fa-trash"></i></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                                {currentTicket && (
                                    <>
                                        <a href={`https://rede360.freshdesk.com/a/tickets/${currentTicket.id}`} rel="noopener noreferrer" target="_blank" >{currentTicket.subject}</a>
                                        <hr />
                                    </>
                                )}
                                {transaction.description && (<div dangerouslySetInnerHTML={{ __html: transaction.description }} />)}
                            </div>
                            <div className="table-responsive mt-4">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Uploaded"}</th>
                                            <th scope="col">{"Name"}</th>
                                            <th scope="col">{"Extension"}</th>
                                            <th scope="col">{"Options"}</th>
                                        </tr>
                                    </thead>
                                    {opt === 'sheet' ? (
                                        <tbody>
                                            {currentSheet.proprietaryPayments && currentSheet.proprietaryPayments.documents.map((file) => (
                                                <tr key={file._id}>
                                                    <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                    <td>{file.origName}</td>
                                                    <td>{file.origName.split('.').pop()}</td>
                                                    <td>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file._id)}><i className="fa fa-trash"></i></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {transaction.documents && transaction.documents.map((file) => (
                                                <tr key={file._id}>
                                                    <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                    <td>{file.origName}</td>
                                                    <td>{file.origName.split('.').pop()}</td>
                                                    <td>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file._id)}><i className="fa fa-trash"></i></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </Table>
                            </div>
                        </Col>
                        <Col sm="12" className="mt-4">
                            <h5>{'Upload de Ficheiros'}</h5>
                            <Filepond
                                name={"mass_upload"}
                                label={"Carregar Ficheiro"}
                                multi={false}
                                files={files}
                                setFiles={setCustomFiles}
                            />
                            {files && (
                                <>
                                    <Button color="primary" onClick={uploadDocument}>
                                        {fileLoading ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : fileError ? (
                                            fileError
                                        ) : (
                                            "Upload"
                                        )}
                                    </Button>
                                    <Button className="ml-2" color="secondary" onClick={() => setCustomFiles([])}>
                                        {"Cancelar"}
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    ) : null
}

export default ViewTransanction