import React, { forwardRef } from 'react';
import { Container, Table, Badge } from 'reactstrap';
import moment from 'moment';

function comparator(a, b) {
    if (a.monthAndYear < b.monthAndYear) {
        return 1;
    }
    if (a.monthAndYear > b.monthAndYear) {
        return -1;
    }
    return 0;
}

function getTotalPayments(thisSheet) {
    let totalPayments = 0;

    thisSheet.payments.forEach((payment) => {
        if (payment && payment.value) totalPayments += payment.value;
    });

    return totalPayments
}

export class ComponentToPrint extends React.PureComponent {
    canvasEl;

    setRef = (ref) => (this.canvasEl = ref);

    render() {
        const { reportData } = this.props;

        return (
            <Container fluid={true} className='print-source'>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Data</th>
                            <th>Descritivo</th>
                            <th>Tipo</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportData && reportData.sort(comparator).map((thisSheet, index) => {
                            let totalCaptivations = 0;
                            let totalDespesas = 0;
                            let totalGuarantee = 0;
                            let totalPagProp = 0;
                            let totalFee = 0;

                            let realRentToBePaid = thisSheet && thisSheet.paid.value;
                            if (thisSheet && thisSheet.paid.paidInAdvance) realRentToBePaid = 0;

                            return (
                                <>
                                    {thisSheet.transactions && thisSheet.transactions.map((thisTransaction, index) => {
                                        if (thisTransaction.tag !== 'rent' &&
                                            thisTransaction.tag !== 'guarantee' &&
                                            thisTransaction.tag !== 'captivation' &&
                                            thisTransaction.tag !== 'pp' &&
                                            thisTransaction.tag !== 'fee'
                                        ) {
                                            totalDespesas += -(thisTransaction.value)
                                        }

                                        if (thisTransaction.tag === 'captivation') {
                                            totalCaptivations += Math.abs(thisTransaction.value)
                                        }

                                        if (thisTransaction.tag === 'guarantee') {
                                            totalGuarantee += thisTransaction.value
                                        }

                                        if (thisTransaction.tag === 'pp') {
                                            totalPagProp += Math.abs(thisTransaction.value)
                                        }

                                        if (thisTransaction.tag === 'fee') {
                                            totalFee += Math.abs(thisTransaction.value)
                                        }

                                        return thisTransaction.tag !== 'rent' ? (
                                            <tr key={thisTransaction._id}>
                                                <th scope="row">{thisSheet.monthAndYear}</th>
                                                <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                <td>{thisTransaction.name}</td>
                                                <td>
                                                    {thisTransaction.tag === 'captivation' ? (
                                                        <Badge color="light">
                                                            Cativação
                                                        </Badge>
                                                    ) : thisTransaction.tag === 'fee' ? (
                                                        <Badge color="warning">
                                                            Fee
                                                        </Badge>
                                                    ) : thisTransaction.tag === 'guarantee' ? (
                                                        <Badge color="primary">
                                                            Caução
                                                        </Badge>
                                                    ) : (
                                                        <>
                                                            <Badge color="info">
                                                                Despesa
                                                            </Badge>
                                                        </>

                                                    )}
                                                </td>
                                                <td>{Math.abs(thisTransaction.value).toFixed(2)} €</td>
                                            </tr>
                                        ) : (
                                            <tr key={thisTransaction._id}>
                                                <th scope="row">{thisSheet.monthAndYear}</th>
                                                <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                                                <td>{thisTransaction.name}</td>
                                                <td><Badge color="warning">Pagamento</Badge></td>
                                                <td>{Math.abs(thisTransaction.value).toFixed(2)} € <br /> <span style={{ color: '#48b0f7' }}>{thisSheet ? Math.abs(getTotalPayments(thisSheet)).toFixed(2) : "00.00"} €</span></td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <th scope="row">{thisSheet.monthAndYear}</th>
                                        <td></td>
                                        <td>Total</td>
                                        <td></td>
                                        <td><span>{(realRentToBePaid + totalGuarantee + totalPagProp - totalDespesas - totalCaptivations + thisSheet.remainder - totalFee).toFixed(2)} €</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">{thisSheet.monthAndYear}</th>
                                        <td></td>
                                        <td>Transfered to client account</td>
                                        <td></td>
                                        <td><span>{thisSheet.proprietaryPaid.value.toFixed(2)} €</span></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: 0 }}><Badge style={{ width: "100%", border: "none" }} color="light">&nbsp;&nbsp;&nbsp;&nbsp;</Badge></td>
                                        <td style={{ padding: 0 }}><Badge style={{ width: "100%", border: "none" }} color="light">&nbsp;&nbsp;&nbsp;&nbsp;</Badge></td>
                                        <td style={{ padding: 0 }}><Badge style={{ width: "100%", border: "none" }} color="light">&nbsp;&nbsp;&nbsp;&nbsp;</Badge></td>
                                        <td style={{ padding: 0 }}><Badge style={{ width: "100%", border: "none" }} color="light">&nbsp;&nbsp;&nbsp;&nbsp;</Badge></td>
                                        <td style={{ padding: 0 }}><Badge style={{ width: "100%", border: "none" }} color="light">&nbsp;&nbsp;&nbsp;&nbsp;</Badge></td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </Table>
            </Container>
        );
    }
}

export const FunctionalComponentToPrint = forwardRef((props, ref) => {
    return (
        <ComponentToPrint ref={ref} reportData={props.reportData} />
    )
});
