import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const putCall = (route, rqModel, values) => {
    let url;
    if (values instanceof FormData) {
        url = values.get('_id')
    } else {
        url = values._id
    }

    return axios.put(`${route}/${url}`, values, values.fileRequest ? {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    } : null).then((res) => res.data);
};

// IF fullRoute dont apply id do request url

export default function usePut(route, rqModel, values, fullRoute) {
    const queryClient = useQueryClient();

    return useMutation([route, rqModel, values], () => putCall(route, rqModel, values), {
        onSuccess: (newModel) => {
            if (!newModel) queryClient.invalidateQueries(rqModel);
            queryClient.setQueryData([`${route}/${newModel._id}`, newModel._id, rqModel], newModel)

            if (queryClient.getQueryData(rqModel)) {
                queryClient.setQueryData(rqModel, old => {
                    if (old.results) {
                        const newModels = old.results.map(d => {
                            if (d._id === newModel._id) {
                                return newModel
                            }
                            return d
                        });
                        old.results = newModels;
                        return old;
                    } else {
                        return old.map(d => {
                            if (d._id === newModel._id) {
                                return newModel
                            }
                            return d
                        });
                    }
                });
            }
        },
        onSettled: () => {
            setTimeout(() => {
                queryClient.invalidateQueries(rqModel);
            }, 1500);
        },
    });
}