import React, { Fragment, useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Col, Row, CardHeader, Card, Table, Button } from 'reactstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import moment from 'moment';

import NoResults from "../../../ui-kits/noResults";

function comparator(a, b) {
    if (a.uploadDate < b.uploadDate) {
        return 1;
    }
    if (a.uploadDate > b.uploadDate) {
        return -1;
    }
    return 0;
}

const Documents = ({ data, transaction_sheets, refetchData }) => {
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        const newDocuments = [];

        if (transaction_sheets && transaction_sheets.data && documents === null) {
            transaction_sheets.data.results.forEach((sheet) => {
                sheet.transactions.forEach((element) => {
                    element.documents.forEach((doc) => {
                        if (!newDocuments.some(el  => el._id === doc._id)) {
                            newDocuments.push({ ...doc, ...element, file_id: doc._id, transaction_id: element._id });
                        }
                    })
                });
            });

            setDocuments(newDocuments);
        }

    }, [transaction_sheets, documents])


    const deleteRequest = (file, transaction_id) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende apagar o Documento?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.delete(`/api/uploads/transactions/${transaction_id}/${file}`)
                        .then((result) => {
                            // const newDocuments = transaction.documents.filter((doc) => doc._id !== file);
                            // transaction.documents = newDocuments;

                            refetchData();

                            toast.success("Documento apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                        .catch((error) => {
                            console.error(error);

                            refetchData();

                            toast.error("Ocorreu um erro ao apagar o documento", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });
                }
            })
    }

    return data ? (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="cardHeader reduce-padding">
                            <h5>{'Documentos das Transações'}</h5>
                            <span style={{ position: 'absolute', right: 15, top: 18 }}><a className="btn btn-outline btn-xs btn-primary" href={`${process.env.PUBLIC_URL}/contrato/${data.contract._id}?tab=documentos`}>Ver documentos do contrato</a></span>
                        </CardHeader>
                        <div className="table-responsive" style={{ minHeight: 250 }}>
                            {documents && documents.length > 0 ? (
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Uploaded"}</th>
                                            <th scope="col">{"Name"}</th>
                                            <th scope="col">{"Options"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documents && documents.sort(comparator).map((file) => (
                                            <tr key={file._id}>
                                                <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                <td style={{ maxWidth: 500 }}>{file.origName}</td>
                                                <td>
                                                    <Button color="primary" size="xs" className="ml-1" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-1" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                    <Button color="primary" size="xs" className="ml-1" onClick={() => deleteRequest(file.file_id, file.transaction_id)}><i className="fa fa-trash"></i></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <NoResults />
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="cardHeader reduce-padding">
                                <h5>{'Documentos dos Contratos'}</h5>
                            </CardHeader>
                            <div className="table-responsive" style={{ minHeight: 250 }}>
                                {data.contract.documents.length > 0 ? (
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="col">{"Uploaded"}</th>
                                                <th scope="col">{"Name"}</th>
                                                <th scope="col">{"Type"}</th>
                                                <th scope="col">{"Extension"}</th>
                                                <th scope="col">{"Options"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.contract.documents.map((file) => (
                                                <tr key={file._id}>
                                                    <th scope="row">{moment(file.uploadDate).format('LL')}</th>
                                                    <td>{file.origName}</td>
                                                    <td>{file.classification}</td>
                                                    <td>{file.origName.split('.').pop()}</td>
                                                    <td>
                                                        <ButtonDropdown
                                                            isOpen={dropdownActive === file._id}
                                                            toggle={() => dropdownActive ? setDropdown(null) : setDropdown(file._id)}
                                                        >
                                                            <DropdownToggle caret color="primary" size="xs">
                                                                Associar
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => associateFile(file, 'contrato')}>
                                                                    Contrato
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => associateFile(file, 'registo')}>
                                                                    Registo AT
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => associateFile(file, 'orcamento')}>
                                                                    Orçamentos
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => associateFile(file, 'adenda')}>
                                                                    Adenda
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </ButtonDropdown>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-download"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => window.open('https://property360.dokky2.cottonhat.net' + file.path)}><i className="fa fa-eye"></i></Button>
                                                        <Button color="primary" size="xs" className="ml-2" onClick={() => deleteRequest(file)}><i className="fa fa-trash"></i></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <NoResults />
                                )}
                            </div>
                        </Card>
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="cardHeader reduce-padding">
                                <h5>{'Upload de Ficheiros'}</h5>
                            </CardHeader>
                            <CardBody>
                                <Filepond
                                    name={"mass_upload"}
                                    label={"Carregar Ficheiro"}
                                    multi={false}
                                    files={files}
                                    setFiles={setCustomFiles}
                                />
                            </CardBody>
                            <CardFooter>
                                {files && (
                                    <>
                                        <Button color="primary" onClick={uploadDocument}>
                                            {fileLoading ? (
                                                <i className="fa fa-spin fa-spinner" />
                                            ) : fileError ? (
                                                fileError
                                            ) : (
                                                "Upload"
                                            )}
                                        </Button>
                                        <Button className="ml-2" color="secondary" onClick={() => setCustomFiles([])}>
                                            {"Cancelar"}
                                        </Button>
                                    </>
                                )}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row> */}
        </Fragment >
    ) : null;
};

export default Documents;