export const apiUrl = "/api/clients";
export const rqModel = "clients";
export const pathname = `${process.env.PUBLIC_URL}/clientes`;

export const columns = [
    {
        name: "Tipo",
        selector: "type",
        sortable: true,
        center: true,
    },
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        center: true,
    },
    {
        name: "Telefone",
        selector: "phone",
        sortable: true,
        center: true,
    },
    {
        name: "Ações",
        selector: "action",
        sortable: false,
        center: true,
        grow: 2,
    },
];

export const schema = [

    {
        colSize: "6",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },

    {
        hidden: true,
        colSize: "6",
        label: "Identificação",
        name: "idType",
        id: "idType",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "passport",
                label: "Passaporte",
            },
            {
                value: "cc",
                label: "Cartão Cidadão",
            },
            {
                value: "bi",
                label: "Número BI",
            },
            {
                value: "cr",
                label: "Cartão Residência",
            },
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Número",
        name: "identification.nationalId",
        id: "identificationNationalId",
        type: "text",
    },
    {
        hidden: true,
        colSize: "6",
        label: "Emitido por",
        name: "identification.issuedBy",
        id: "identificationIssuedBy",
        type: "text",
    },
    {
        hidden: true,
        colSize: "6",
        label: "Local de Emissão",
        name: "localdeemissao",
        id: "localdeemissao",
        type: "text",
    },
    {
        hidden: true,
        colSize: "6",
        label: "Emitido Em",
        name: "passport.issuedOn",
        id: "passportIssuedOn",
        type: "datepicker",
        showYears: true,
    },
    {
        hidden: true,
        colSize: "6",
        label: "Emitido Até",
        name: "passport.validity",
        id: "passportValidity",
        type: "datepicker",
        showYears: true,
    },
    {
        hidden: true,
        colSize: "6",
        label: "Sexo",
        name: "gender",
        id: "gender",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "M",
                label: "Masculino",
            },
            {
                value: "F",
                label: "Feminino",
            }
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Data de Nascimento",
        name: "birthdate",
        id: "birthdate",
        type: "datepicker",
        showYears: true,
    },
    {
        hidden: true,
        colSize: "6",
        label: "Local de Nascimento",
        name: "placeOfBirth",
        id: "placeOfBirth",
        type: "text",
    },
    {
        hidden: true,
        colSize: "6",
        label: "Estado Civil",
        name: "maritalStatus.status",
        id: "maritalStatusStatus",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "single",
                label: "Solteiro",
            },
            {
                value: "married",
                label: "Casado",
            },
            {
                value: "widow",
                label: "Viúvo",
            },
            {
                value: "divorced",
                label: "Divorciado",
            }
        ],
    },
    {
        hidden: true,
        colSize: "6",
        label: "Regime de Bens",
        name: "maritalStatus.assets",
        id: "maritalStatus.assets",
        type: "select",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "acquired",
                label: "Comunhão de Adquiridos",
            },
            {
                value: "general",
                label: "Comunhão Geral de Bens",
            },
            {
                value: "separated",
                label: "Separação de bens",
            },
            {
                value: "unionOfFact",
                label: "União de Facto",
            },
        ],
    },
    // {
    //     colSize: "6",
    //     label: "Conjuge",
    //     name: "maritalStatus.spouse",
    //     id: "maritalStatus.spouse",
    //     type: "text",
    //     required: true,
    //     requiredMessage: "Este campo é obrigatório",
    // },
    {
        hidden: true,
        colSize: "6",
        label: "NIPC",
        name: "nipc",
        id: "nipc",
        type: "text",
    },
    {
        colSize: "6",
        label: "Morada / Sede",
        name: "address",
        id: "address",
        type: "text",
    },
    {
        colSize: "6",
        label: "Email",
        name: "contacts.email",
        id: "contacts.email",
        type: "email",
    },
    {
        colSize: "6",
        label: "Telefone",
        name: "contacts.phone",
        id: "contacts.phone",
        type: "text",
    },
    {
        colSize: "6",
        label: "Outros Contactos / Informações",
        name: "contacts.other",
        id: "contacts.other",
        type: "textarea",
    },
];
