import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import axios from "axios";

import { MyProfile, Save, EditProfile, Username, AboutMe, UpdateProfile, FirstName, LastName, Address, EmailAddress, PostalCode, City } from "../../constant";

import Filepond from "../filepond/";

import { setUser } from "../../redux/actionTypes";

const MyAccount = () => {
    const dispatch = useDispatch();
    const user = useSelector((content) => content.User.user);
    const _mainRole = useSelector((content) => content.User._mainRole);
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            ...user,
        },
    });
    const {
        register: registerDetails,
        handleSubmit: handleSubmitDetails,
        errors: detailsErrors,
    } = useForm({
        defaultValues: {
            ...user,
        },
    });

    const [loading, setLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState(null);

    const [loadingDetails, setLoadingDetails] = useState(false);
    const [requestMessageDetails, setRequestMessageDetails] = useState(null);

    const [output, setOutput] = useState(null); //* FILEPOND

    const [modal, setModal] = useState(false);
    const [photoLoading, setPhotoLoading] = useState(false);
    const [photoError, setPhotoError] = useState(null);

    const closeModal = () => {
        setModal(false);
    }

    const setCustomOutput = (image) => {
        setOutput(image);
    }

    const submitPhoto = () => {
        setPhotoLoading(true);

        let submitValues = new FormData();
        submitValues.append('profilePhoto[name]', output.name);
        submitValues.append('profilePhoto', output);
        axios.post(`/api/v1/accounts/${user._id}/update_profile`, submitValues, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((result) => {
                if (result.status === 200) {
                    setPhotoLoading(false);
                    closeModal();
                } else {
                    setPhotoLoading(false);
                    setPhotoError("Ocorreu um erro, tente mais tarde.");
                    setTimeout(() => {
                        setPhotoError(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                setPhotoLoading(false);
                setPhotoError("Ocorreu um erro, tente mais tarde.");
                setTimeout(() => {
                    setPhotoError(null);
                }, 3000);
            });
    }

    const updateAccount = (values) => {
        setLoading(true);

        let submitValues = {
            ...values,
            password: values.password ? values.password : null,
            new_password: values.new_password ? values.new_password : null,
        };
        axios
            .put(`/api/v1/accounts/${user._id}`, submitValues)
            .then((result) => {
                if (result.status === 200) {
                    let payload = {
                        loaded: true,
                        authenticated: true,
                        kpiInfo: null,
                        user: result.data,
                    };
                    dispatch(setUser(payload));
                    setLoading(false);
                    setRequestMessage("Guardado com sucesso");
                    setTimeout(() => {
                        setRequestMessage(null);
                    }, 3000);
                } else if (result.status === 304) {
                    setLoading(false);
                    setRequestMessage("Sem alterações");
                    setTimeout(() => {
                        setRequestMessage(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 304) {
                    setLoading(false);
                    setRequestMessage("Sem alterações");
                    setTimeout(() => {
                        setRequestMessage(null);
                    }, 3000);
                } else {
                    setLoading(false);
                    setRequestMessage("Ocorreu um erro");
                    setTimeout(() => {
                        setRequestMessage(null);
                    }, 3000);
                }
            });
    };

    const updateAccountDetails = (values) => {
        setLoadingDetails(true);

        let submitValues = {
            ...values,
        };
        axios
            .put(`/api/v1/accounts/${user._id}`, submitValues)
            .then((result) => {
                if (result.status === 200) {
                    let payload = {
                        loaded: true,
                        authenticated: true,
                        kpiInfo: null,
                        user: result.data,
                    };
                    dispatch(setUser(payload));
                    setLoadingDetails(false);
                    setRequestMessageDetails("Guardado com sucesso");
                    setTimeout(() => {
                        setRequestMessageDetails(null);
                    }, 3000);
                } else if (result.status === 304) {
                    setLoadingDetails(false);
                    setRequestMessageDetails("Sem alterações");
                    setTimeout(() => {
                        setRequestMessageDetails(null);
                    }, 3000);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 304) {
                    setLoadingDetails(false);
                    setRequestMessageDetails("Sem alterações");
                    setTimeout(() => {
                        setRequestMessageDetails(null);
                    }, 3000);
                } else {
                    setLoadingDetails(false);
                    setRequestMessageDetails("Ocorreu um erro");
                    setTimeout(() => {
                        setRequestMessageDetails(null);
                    }, 3000);
                }
            });
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="4" className="mt-4">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">{MyProfile}</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="#javascript">
                                            <i className="fe fe-chevron-up"></i>
                                        </a>
                                        <a className="card-options-remove" href="#javascript">
                                            <i className="fe fe-x"></i>
                                        </a>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        className="theme-form needs-validation"
                                        noValidate=""
                                        onSubmit={handleSubmit(updateAccount)}
                                    >
                                        <Row className="mb-2">
                                            <div className="col-auto">
                                                <div className="user-image">
                                                    <div className="avatar">
                                                        <Media
                                                            className="img-70 rounded-circle"
                                                            src={user.profilePhoto && user.profilePhoto !== "" ? user.profilePhoto : require("../../assets/images/user/7.jpg")}
                                                            data-intro="profile-pic"
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => setModal(true)}
                                                        className="edit-img icon-wrapper"
                                                        data-intro="Change Profile image here"
                                                        style={{ bottom: _mainRole === 'Farmácia' ? 25 : 0 }}
                                                    >
                                                        <i className="icofont icofont-pencil-alt-5">

                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <Col>
                                                <h3 className="mb-1">
                                                    {user && `${user.name.first} ${user.name.last}`}
                                                </h3>
                                                {user && user.anf_code && `ANF: ${user.anf_code}`}
                                                <p className="mb-4">{user && _mainRole}</p>
                                            </Col>
                                        </Row>
                                        {/* <FormGroup>
                                            <h6 className="form-label">{Bio}</h6>
                                            <Input type="textarea" className="form-control" rows="5" defaultValue="On the other hand, we denounce with righteous indignation" />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Label className="form-label">{EmailAddress}</Label>
                                            <Input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                placeholder="email@dominio.com"
                                                innerRef={register({ required: true })}
                                            />
                                            <span className="form-error">
                                                {errors.email && "Este campo é obrigatório"}
                                            </span>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label">{Username}</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="username"
                                                disabled
                                                innerRef={register()}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label">{"Telegram"}</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="telegram_username"
                                                innerRef={register()}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label">{"Password Atual"}</Label>
                                            <Input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                innerRef={register()}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="form-label">{"Nova Password"}</Label>
                                            <Input
                                                className="form-control"
                                                type="password"
                                                name="new_password"
                                                innerRef={register()}
                                            />
                                        </FormGroup>
                                        <div className="form-footer">
                                            <button
                                                className="btn btn-primary btn-block"
                                                type="submit"
                                                disabled={loading || requestMessage}
                                            >
                                                {loading ? (
                                                    <i className="fa fa-spin fa-spinner" />
                                                ) : requestMessage ? (
                                                    requestMessage
                                                ) : (
                                                    Save
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8" className="mt-4">
                            <Form
                                className="card theme-form needs-validation"
                                noValidate=""
                                onSubmit={handleSubmitDetails(updateAccountDetails)}
                            >
                                <CardHeader>
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="#javascript">
                                            <i className="fe fe-chevron-up"></i>
                                        </a>
                                        <a className="card-options-remove" href="#javascript">
                                            <i className="fe fe-x"></i>
                                        </a>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm="6" md="6">
                                            <FormGroup>
                                                <Label className="form-label">{FirstName}</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    name="name.first"
                                                    placeholder="Primeiro Nome"
                                                    innerRef={registerDetails({ required: true })}
                                                />
                                                <span className="form-error">
                                                    {detailsErrors.name &&
                                                        detailsErrors.name.first &&
                                                        "Este campo é obrigatório"}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="6">
                                            <FormGroup>
                                                <Label className="form-label">{LastName}</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    name="name.last"
                                                    placeholder="Último Nome"
                                                    innerRef={registerDetails({ required: true })}
                                                />
                                                <span className="form-error">
                                                    {detailsErrors.name &&
                                                        detailsErrors.name.last &&
                                                        "Este campo é obrigatório"}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="6">
                                            <FormGroup>
                                                <Label className="form-label">{City}</Label>
                                                <Input className="form-control" type="text" name="city" innerRef={registerDetails()} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="6">
                                            <FormGroup>
                                                <Label className="form-label">{PostalCode}</Label>
                                                <Input className="form-control" type="text" name="postalCode" innerRef={registerDetails()} placeholder="****-***" />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-0">
                                                <Label className="form-label">{AboutMe}</Label>
                                                <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    rows="5"
                                                    name="about" 
                                                    innerRef={registerDetails()} 
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className="text-right">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={loadingDetails || requestMessageDetails}
                                    >
                                        {loadingDetails ? (
                                            <i className="fa fa-spin fa-spinner" />
                                        ) : requestMessageDetails ? (
                                            requestMessageDetails
                                        ) : (
                                            UpdateProfile
                                        )}
                                    </button>
                                </CardFooter>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Modal isOpen={modal} toggle={() => closeModal}>
                <ModalHeader toggle={() => closeModal}>
                    {"Upload da foto de perfil"}
                </ModalHeader>
                <ModalBody>
                    <Filepond
                        name={"profilePhoto"}
                        label={"Carregar Imagem"}
                        multi={false}
                        setOutput={setCustomOutput}
                        content={`image`}
                        imageEditInstantEdit
                        imageCropAspectRatio={1}
                        imageResizeTargetWidth={400}
                        cropAspectRatio={"1:1"}
                        cropAspectRatioOptions={[
                            {
                                label: "Square",
                                value: "1:1",
                            }
                        ]}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitPhoto}>
                        {photoLoading ? (
                            <i className="fa fa-spin fa-spinner" />
                        ) : photoError ? (
                            photoError
                        ) : (
                            "Selecionar"
                        )}
                    </Button>
                    <Button color="secondary" onClick={() => closeModal()}>
                        {"Fechar"}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default MyAccount;
