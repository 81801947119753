import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';

const TransanctionRow = ({ type, transactions, contract, thisSheet, toggleModal, toggleModal2, deleteTransaction, refetchData, openReport }) => {
    const getTotalPayments = (thisSheet) => {
        let totalPayments = 0;

        thisSheet.payments.forEach((payment) => {
            if (payment && payment.value) totalPayments += payment.value;
        });

        return totalPayments
    }

    let totalCaptivations = 0;
    let totalDespesas = 0;
    let totalGuarantee = 0;
    let totalPagProp = 0;
    let totalFee = 0;

    let realRentToBePaid = thisSheet.paid.value;
    if (thisSheet.paid.paidInAdvance) realRentToBePaid = 0;

    const closeMonth = (thisSheet) => {
        SweetAlert.fire({
            title: 'Atenção!',
            text: 'Confirma que pretende fechar este mês?',
            type: 'warning',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        })
            .then(async (result) => {
                if (result.value) {
                    axios.patch(`/api/transaction_sheets/${thisSheet._id}/close`, { closed: !thisSheet.closed })
                        .then((result) => {
                            console.log(result);
                            refetchData();
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }
            })
    }

    return (
        <>
            {transactions && transactions.map((thisTransaction) => {
                if (thisTransaction.tag !== 'rent' &&
                    thisTransaction.tag !== 'guarantee' &&
                    thisTransaction.tag !== 'captivation' &&
                    thisTransaction.tag !== 'pp' &&
                    thisTransaction.tag !== 'fee'
                ) {
                    totalDespesas += -(thisTransaction.value)
                }

                if (thisTransaction.tag === 'captivation') {
                    totalCaptivations += Math.abs(thisTransaction.value)
                }

                if (thisTransaction.tag === 'guarantee') {
                    totalGuarantee += thisTransaction.value
                }

                if (thisTransaction.tag === 'pp') {
                    totalPagProp += Math.abs(thisTransaction.value)
                }

                const thisRent = transactions.find((el) => el.tag === 'rent');
                if (thisTransaction.tag === 'fee' && realRentToBePaid >= thisRent?.value) {
                    //! FAZER CONTAS DA FEE APENAS QUANDO A RENT Tá paga
                    totalFee += Math.abs(thisTransaction.value)
                }

                return thisTransaction.tag !== 'rent' ? (
                    <tr key={thisTransaction._id}>
                        <th scope="row">{thisSheet.monthAndYear}</th>
                        <td>{thisTransaction.tag !== 'pp' ? moment(thisTransaction.date).format('DD/MM/YYYY') : null}</td>
                        <td>
                            {thisTransaction.type === "repair" ? (
                                <Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/propriedade/${contract.property._id}?tab=manutencao`,
                                    state: { fromUrl: `/balanco/${contract._id}` }
                                }}>
                                    {thisTransaction.name}
                                </Link>
                            ) : thisTransaction.type === "seguro" ? (
                                <Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/propriedade/${contract.property._id}?tab=seguro`,
                                    state: { fromUrl: `/balanco/${contract._id}` }
                                }}>
                                    {thisTransaction.name}
                                </Link>
                            ) : (
                                `${thisTransaction.name}`
                            )}
                        </td>
                        <td>
                            {thisTransaction.tag === 'captivation' ? (
                                <Badge color="light">
                                    Cativação
                                </Badge>
                            ) : thisTransaction.tag === 'fee' ? (
                                <>
                                    <Badge color="warning">
                                        Fee
                                    </Badge>
                                    {thisTransaction.paymentDate ? (
                                        <Badge color="success">Pago</Badge>
                                    ) : toggleModal && (
                                        <Button className="ml-1" outline color="success" size="xs" onClick={() => toggleModal('pay', thisTransaction._id, thisTransaction, thisSheet)}>Pagar</Button>
                                    )}
                                </>
                            ) : thisTransaction.tag === 'guarantee' ? (
                                <Badge color="primary">
                                    Caução
                                </Badge>
                            ) : thisTransaction.tag === 'pp' ? (
                                <>
                                    <Badge color="dark">
                                        Crédito
                                    </Badge>
                                </>

                            ) : (
                                <>
                                    <Badge color="info">
                                        Despesa
                                    </Badge>
                                    {thisTransaction.paymentDate ? (
                                        <Badge color="success">Pago</Badge>
                                    ) : toggleModal && (
                                        <Button className="ml-1" outline color="success" size="xs" onClick={() => toggleModal('pay', thisTransaction._id, thisTransaction, thisSheet)}>Pagar</Button>
                                    )}
                                </>
                            )}
                        </td>
                        <td>{Math.abs(thisTransaction.value).toFixed(2)} €</td>
                        {!thisSheet.closed && toggleModal && deleteTransaction ? (
                            <td>
                                <Button className="ml-1" outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-eye"></i></Button>
                                <Button className="ml-1" outline color="primary" size="xs" onClick={() => toggleModal('edit', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-pencil"></i></Button>
                                <Button className="ml-1" outline color="danger" size="xs" onClick={() => deleteTransaction('delete', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-trash"></i></Button>
                            </td>
                        ) : (
                            <td>
                            </td>
                        )}
                    </tr>
                ) : (
                    <tr key={thisTransaction._id}>
                        <th scope="row">{thisSheet.monthAndYear}</th>
                        <td>{moment(thisTransaction.date).format('DD/MM/YYYY')}</td>
                        <td>{thisTransaction.name}</td>
                        <td><Badge color="warning">Pagamento</Badge></td>
                        <td>{Math.abs(thisTransaction.value).toFixed(2)} € <br /> <span style={{ color: '#48b0f7' }}>{thisSheet ? Math.abs(getTotalPayments(thisSheet)).toFixed(2) : "00.00"} €</span></td>
                        <td>
                            {toggleModal && (
                                <Button outline color="primary" size="xs" onClick={() => toggleModal('view', thisTransaction._id, thisTransaction, thisSheet)}><i className="fa fa-eye"></i></Button>
                            )}
                        </td>
                    </tr>
                )
            })}
            <tr>
                <th scope="row">{thisSheet.monthAndYear}</th>
                <td></td>
                <td>Total</td>
                <td></td>
                {type === "despesas" ? (
                    <td><span>{(totalDespesas + totalFee).toFixed(2)} €</span></td>
                ) : (
                    <td><span>{((realRentToBePaid + thisSheet.remainder) - totalDespesas - totalCaptivations - totalFee + totalGuarantee + totalPagProp).toFixed(2)} €</span></td>
                )}
                <td></td>
            </tr>
            {type !== "despesas" && (
                <tr>
                    <th scope="row">{thisSheet.monthAndYear}</th>
                    <td></td>
                    <td>Valor Transferido para o Proprietário</td>
                    <td></td>
                    <td><span>{thisSheet.proprietaryPaid.value.toFixed(2)} €</span></td>
                    <td>
                        {!thisSheet.closed && (
                            <Button className="ml-1" color="primary" size="xs" onClick={() => toggleModal2('propertyPay', contract._id, thisSheet.monthAndYear)}><i className="fa fa-level-up"></i></Button>
                        )}
                    </td>
                </tr>
            )}
            <tr style={{ background: '#f1f1f1' }}>
                <th scope="row">{thisSheet.monthAndYear}</th>
                {thisSheet.closed && type !== "despesas" ? (
                    <td>
                        <Button className="ml-1" color="primary" size="xs" onClick={() => closeMonth(thisSheet)}><i className="fa fa-reply-all"></i> Abrir mês de {moment(thisSheet.monthAndYear).format('MMMM')}</Button>
                    </td>
                ) : type !== "despesas" ? (
                    <td>
                        <Button className="ml-1" color="danger" size="xs" onClick={() => closeMonth(thisSheet)}><i className="fa fa-ban"></i> Fechar mês de {moment(thisSheet.monthAndYear).format('MMMM')}</Button>
                    </td>
                ) : (
                    <td>
                    </td>
                )}
                <td></td>
                <td>
                    {openReport && (
                        <Button className="ml-1" color="primary" size="xs" onClick={() => openReport(true)}>Gerar Report</Button>
                    )}
                </td>
                <td></td>
                <td>
                    {toggleModal2 && !thisSheet.closed && (
                        <>
                            <Button className="ml-1" color="primary" size="xs" onClick={() => toggleModal2('expenses', contract._id, thisSheet.monthAndYear)}><i className="fa fa-wrench"></i></Button>
                            <Button className="ml-1" color="primary" size="xs" onClick={() => toggleModal2('monthlyPay', contract._id, thisSheet.monthAndYear)}><i className="fa fa-level-down"></i></Button>
                            <Button className="ml-1" color="primary" size="xs" onClick={() => toggleModal2('propertyPay', contract._id, thisSheet.monthAndYear)}><i className="fa fa-level-up"></i></Button>
                        </>
                    )}
                </td>
            </tr>
        </>
    )
}

export default TransanctionRow