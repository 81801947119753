//! Property Management
import Clients from "../components/clients";
import Suppliers from "../components/suppliers";
import Patrimonio from "../components/patrimonio";
import Contracts from "../components/contracts";
import Balances from "../components/balances";
import MyAccount from "../components/account/my-account";
import ClientsProfile from "../components/clients/profile";
import SuppliersProfile from "../components/suppliers/profile";
import PropertyProfile from "../components/patrimonio/profile";
import ContractProfile from "../components/contracts/profile";
import BalancesProfile from "../components/balances/profile";

import DynamicQueries from "../components/dynamicQueries/index";
//! Property Management

export const routes = [
    //! Property Management
    { path: "/clientes", Component: Clients },
    { path: "/cliente/:id", Component: ClientsProfile },
    { path: "/fornecedores", Component: Suppliers },
    { path: "/fornecedor/:id", Component: SuppliersProfile },
    { path: "/patrimonio", Component: Patrimonio },
    { path: "/propriedade/:id", Component: PropertyProfile },
    { path: "/contratos", Component: Contracts },
    { path: "/contrato/:id", Component: ContractProfile },
    { path: "/balancos", Component: Balances },
    { path: "/balanco/:id", Component: BalancesProfile },
    { path: "/minha-conta", Component: MyAccount },

    { path: "/pesquisar", Component: DynamicQueries },
    //! Property Management
]