import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const ForgotPassword = (props) => {
    const { register, handleSubmit, errors } = useForm();

    const [loading, setLoading] = useState(false);
    const [requestMessage, setRequestMessage] = useState(null);

    const forgotPwd = (values) => {
        setLoading(true);

        const formOptions = { username: values.username }

        return axios.post('/api/accounts/sendlostpass', formOptions)
            .then(user => {
                setLoading(false);
                setRequestMessage("Email enviado com indicações");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 3000);

            })
            .catch(error => {
                setLoading(false);
                setRequestMessage("Username inválido");
                setTimeout(() => {
                    setRequestMessage(null);
                }, 3000);
            });
    }

    return (
        <Container fluid={true} className="p-0">
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div className="login-main login-tab">
                                <TabContent activeTab={"jwt"} className="content-login">
                                    <TabPane className="fade show" tabId={"jwt"}>
                                        <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(forgotPwd)}>
                                            <h2>Recuperar Password</h2>
                                            <p>{"Bem-vindo"} <br /> {""}</p>
                                            <div className="form-row">
                                                <Label className="col-form-label">Username ou Email</Label>
                                                <Input className="form-control" type="text" name="username" innerRef={register({ required: true })} />
                                                <span className="form-error">{errors.username && 'Este campo é obrigatório'}</span>
                                            </div>
                                            <div className="form-row mb-0 mt-4">
                                                <Button color="primary" className="btn-block" type="submit" disabled={loading || requestMessage}>
                                                    {loading ? (<i className="fa fa-spin fa-spinner" />) : requestMessage ? (requestMessage) : ("Recuperar")}
                                                </Button>
                                            </div>
                                            <div className="form-row mb-0 mt-4">
                                                <NavLink to={"/login"}>{"Login ?"}</NavLink>
                                            </div>
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(ForgotPassword);