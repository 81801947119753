import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';

import Geral from './tabs/geral';
// import Notes from './tabs/notes';
import Transactions from './tabs/transactions';
// import History from './tabs/history';
import Documents from './tabs/documents';

import ProfileTitle from '../../../layout/profileTitle';

import FinishContract from '../FinishContractModal';

import { useGetOne, useDelete } from '../../../hooks';

const ContractProfile = (props) => {
    const history = useHistory();

    const { data, refetch, status } = useGetOne(`/api/contracts/${props.match.params && props.match.params.id && props.match.params.id}`, props.match.params && props.match.params.id && props.match.params.id, 'contracts');
    const transaction_sheets = useGetOne(`/api/transaction_sheets/?contract=${props.match.params && props.match.params.id && props.match.params.id}`, props.match.params && props.match.params.id && props.match.params.id, 'transaction_sheets');

    const [transactionSheets, setTS] = useState(null);

    useEffect(() => {
        if (transaction_sheets && transaction_sheets.data && transaction_sheets.data.results && !transactionSheets) {
            let newTS = [];
            transaction_sheets.data.results.forEach((el) => {
                newTS = [...newTS, ...el.transactions]
            });

            setTS(newTS);
        }

    }, [transaction_sheets, transactionSheets])


    const [contractId, setContractId] = useState(null);
    const deleteData = useDelete('api/contracts', 'contracts', contractId)

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [activeTab, setActiveTab] = useState('geral');
    const [modal, setModal] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        if (urlParams.get('tab')) setActiveTab(urlParams.get('tab'));
    }, [history.location.search])

    const deleteRequest = async (_id) => {
        if (_id) {
            await setContractId(`${_id}`);

            SweetAlert.fire({
                title: 'Atenção!',
                text: 'Confirma que pretende apagar o Contrato?',
                type: 'warning',
                confirmButtonText: 'Sim',
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
            })
                .then(async (result) => {
                    if (result.value) {
                        await deleteData.mutate();
                        if (deleteData.isIdle) {
                            toast.success("Contrato apagado!", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            history.push(`${process.env.PUBLIC_URL}/contratos`);
                        }
                    }
                })
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <ProfileTitle route={history.location.state && history.location.state.fromUrl ? `${process.env.PUBLIC_URL}${history.location.state.fromUrl}` : `${process.env.PUBLIC_URL}/contratos`} title={data.contract.rentalNumber} />
                        <Container fluid={true}>
                            <div className="todo">
                                <div className="todo-list-wrapper">
                                    <div className="todo-list-container">
                                        <div className="mark-all-tasks">
                                            <div className="mark-all-tasks-container">
                                                <Button className="ml-1" color="primary" onClick={() => setModal(true)}>Terminar Contrato</Button>
                                                <Button className="ml-1" color="danger" onClick={() => deleteRequest(data.contract._id)}>Apagar Contrato</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-profile social-app-profile">
                                <Col md="12" className="project-list" style={{ padding: 0 }}>
                                    <Card>
                                        <Row>
                                            <Col sm="12">
                                                <Nav tabs className="border-tab">

                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'geral' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=geral`)
                                                                setActiveTab('geral')
                                                            }}
                                                        >
                                                            {"Geral"}
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'historico' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=historico`)
                                                                setActiveTab('historico')
                                                            }}
                                                        >
                                                            {"Histórico"}
                                                        </NavLink>
                                                    </NavItem> */}
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'documentos' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=documentos`)
                                                                setActiveTab('documentos')
                                                            }}
                                                        >
                                                            {"Documentos"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        {/* <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'notas' ? 'active' : ''}
                                                              onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=notas`)
                                                                setActiveTab('notas')
                                                            }}
                                                        >
                                                            {"Notas"}
                                                        </NavLink> */}
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'repair' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=repair`)
                                                                setActiveTab('repair')
                                                            }}
                                                        >
                                                            {"Manutenção / Reparação"}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'condominio' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=condominio`)
                                                                setActiveTab('condominio')
                                                            }}
                                                        >
                                                            {"Condominio"}
                                                        </NavLink>
                                                    </NavItem>
                                                    {/* <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'seguro' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=seguro`)
                                                                setActiveTab('seguro')
                                                            }}
                                                        >
                                                            {"Seguro"}
                                                        </NavLink>
                                                    </NavItem> */}
                                                    <NavItem className="nav" id="myTab" role="tablist">
                                                        <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'impostos' ? 'active' : ''}
                                                            onClick={() => {
                                                                window.history.pushState(null, null, `${process.env.PUBLIC_URL}/contrato/${props.match.params.id}?tab=impostos`)
                                                                setActiveTab('impostos')
                                                            }}
                                                        >
                                                            {"Impostos"}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <TabContent activeTab={activeTab} className="tab-content">
                                    <TabPane tabId="geral">
                                        <Geral data={data} contractId={props.match.params.id} />
                                    </TabPane>
                                    {/* <TabPane tabId="notas">
                                        <Notes data={data} contractId={props.match.params.id} />
                                    </TabPane> */}
                                    {/* <TabPane tabId="historico">
                                        <History data={data} contractId={props.match.params.id} />
                                    </TabPane> */}

                                    {data && transactionSheets && (
                                        <>
                                            <TabPane tabId="documentos">
                                                <Documents data={data} refetch={refetch} contractId={props.match.params.id} />
                                            </TabPane>
                                            <TabPane tabId="repair">
                                                <Transactions data={{ contract: data.contract, sheet: data.sheet, list: transactionSheets.filter((transaction) => transaction.type === 'repair') }} activeTab={activeTab} />
                                            </TabPane>
                                            <TabPane tabId="condominio">
                                                <Transactions data={{ contract: data.contract, sheet: data.sheet, list: transactionSheets.filter((transaction) => transaction.type === 'condominio') }} activeTab={activeTab} />
                                            </TabPane>
                                            <TabPane tabId="impostos">
                                                <Transactions data={{ contract: data.contract, sheet: data.sheet, list: transactionSheets.filter((transaction) => transaction.type === 'impostos') }} activeTab={activeTab} />
                                            </TabPane>
                                        </>
                                    )}

                                    {/* <TabPane tabId="seguro">
                                        <Transactions data={{ contract: data.contract, sheet: data.sheet, list: data.sheet.transactions.filter((transaction) => transaction.type === 'seguro') }} activeTab={activeTab} />
                                    </TabPane> */}
                                </TabContent>
                            </div>
                        </Container>

                        <FinishContract _id={data.contract._id} isOpen={modal} toggle={() => setModal(!modal)} />
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default ContractProfile;