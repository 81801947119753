export const apiUrl = "/api/suppliers";
export const rqModel = "suppliers";
export const pathname = `${process.env.PUBLIC_URL}/fornecedores`;

export const columns = [
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        center: true,
    },
    {
        name: "NIF",
        selector: "NIF",
        sortable: true,
        center: true,
    },
    {
        name: "Ações",
        selector: "action",
        sortable: false,
        center: true,
        grow: 2,
    },
];

export const schema = [
    {
        colSize: "6",
        label: "Nome",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "NIF/NIPC",
        name: "NIF",
        id: "NIF",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    // {
    //     colSize: "6",
    //     label: "NIPC",
    //     name: "NIPC",
    //     id: "NIPC",
    //     type: "text",
    //     required: true,
    //     requiredMessage: "Este campo é obrigatório",
    // },
    {
        colSize: "6",
        label: "IBAN",
        name: "IBAN",
        id: "IBAN",
        type: "text",
    },
    {
        colSize: "6",
        label: "Email",
        name: "contacts.email",
        id: "contacts.email",
        type: "email",
    },
    {
        colSize: "6",
        label: "Telefone",
        name: "contacts.phone",
        id: "contacts.phone",
        type: "text",
    },
    {
        colSize: "6",
        label: "Outros Contactos / Informações",
        name: "contacts.other",
        id: "contacts.other",
        type: "textarea",
    },
];
