import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import Geral from './tabs/geral';
import Documents from './tabs/documents';
import Imoveis from './tabs/imoveis';

import ProfileTitle from '../../../layout/profileTitle';

import { useGetOne } from '../../../hooks';

const ClientProfile = (props) => {
    const history = useHistory();

    const { data, refetch, status } = useGetOne(`/api/clients/${props.match.params && props.match.params.id && props.match.params.id}`, props.match.params && props.match.params.id && props.match.params.id, 'clients');

    if (status === "error") history.push(`${process.env.PUBLIC_URL}/pages/errors/error404`);

    const [activeTab, setActiveTab] = useState('geral');

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        if (urlParams.get('tab')) setActiveTab(urlParams.get('tab'));
    }, [history.location.search])

    return (
        <Fragment>
            <Container fluid={true}>
                {data ? (
                    <Fragment>
                        <ProfileTitle route={`${process.env.PUBLIC_URL}/clientes`} title={data.name} />
                        <div className="user-profile social-app-profile">
                            <Col md="12" className="project-list" style={{ padding: 0 }}>
                                <Card>
                                    <Row>
                                        <Col sm="12">
                                            <Nav tabs className="border-tab">
                                                <NavItem className="nav" id="myTab" role="tablist">
                                                    <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'geral' ? 'active' : ''}
                                                        onClick={() => {
                                                            window.history.pushState(null, null, `${process.env.PUBLIC_URL}/cliente/${props.match.params.id}?tab=geral`)
                                                            setActiveTab('geral')
                                                        }}
                                                    >
                                                        {"Geral"}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem className="nav" id="myTab" role="tablist">
                                                    <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'documentos' ? 'active' : ''}
                                                        onClick={() => {
                                                            window.history.pushState(null, null, `${process.env.PUBLIC_URL}/cliente/${props.match.params.id}?tab=documentos`)
                                                            setActiveTab('documentos')
                                                        }}
                                                    >
                                                        {"Documentos"}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem className="nav" id="myTab" role="tablist">
                                                    <NavLink style={{ cursor: 'pointer' }} tag="a" className={activeTab === 'imoveis' ? 'active' : ''}
                                                        onClick={() => {
                                                            window.history.pushState(null, null, `${process.env.PUBLIC_URL}/cliente/${props.match.params.id}?tab=imoveis`)
                                                            setActiveTab('imoveis')
                                                        }}
                                                    >
                                                        {"Imóveis"}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <TabContent activeTab={activeTab} className="tab-content">
                                <TabPane tabId="geral">
                                    <Geral data={data} />
                                </TabPane>
                                <TabPane tabId="documentos">
                                    <Documents data={data} clientId={props.match.params.id} refetch={refetch} />
                                </TabPane>
                                <TabPane tabId="imoveis">
                                    <Imoveis data={data} clientId={props.match.params.id} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Fragment>
                ) : (
                    <Row style={{ minHeight: 500 }}>
                        <Col sm="12">
                            <div className="loader-box loader-abs">
                                <div className="loader-7" />
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </Fragment>
    );
}

export default ClientProfile;