export const apiUrl = "/api/";
export const rqModel = "repairs";
export const pathname = `${process.env.PUBLIC_URL}/patrimonio`;

export const repairModalForm = [
  {
    colSize: "6",
    label: "Nome",
    name: "name",
    id: "name",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Fornecedor",
    name: "supplier",
    id: "supplier",
    type: "select",
    options: null,
    optionLabel: "name",
    optionValue: "_id",
    backendPopulated: true,
    api: {
      route: "/api/suppliers",
      redux: null,
    },
  },
  {
    colSize: "6",
    label: "Ticket",
    name: "ticket",
    id: "ticket",
    type: "select",
    multi: false,
    optionLabel: "subject",
    optionValue: "id",
    options: null,
    api: {
      route: "/api/v2/tickets",
      redux: null,
      type: "freshdesk",
    },
  },
  {
    colSize: "12",
    label: "Valor",
    name: "price",
    id: "price",
    type: "number",
    step: 0.01,
  },
  {
    colSize: "6",
    label: "Data de Inicio",
    name: "startDate",
    id: "startDate",
    type: "datepicker",
    showYears: true,
  },
  {
    colSize: "6",
    label: "Data de Fim",
    name: "endDate",
    id: "endDate",
    type: "datepicker",
    showYears: true,
  },
];
