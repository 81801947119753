import React, { Fragment, useState, useEffect } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { Container, Row, Col, Form, Label, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/pt';

import { Submit, Cancel } from "../../../constant";

import { usePost } from "../../../hooks";

registerLocale("pt", pt);

const ClientModal = ({ isOpen, toggle, route, rqModel, createSuccess, clients }) => {
    const [type, setType] = useState('individual');
    const [idType, setIdtype] = useState('cc');
    const [maritalStatus, setMaritalStatus] = useState('single');

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm({});

    useEffect(() => {
        register('company', false);
    }, [register]);

    const [submitValues, setSubmitValues] = useState({});

    const createData = usePost(route, rqModel, submitValues);

    const closeForm = () => {
        toggle();
    }

    const onSubmit = async (values) => {
        if (values) {
            await setSubmitValues(values);
            await createData.mutate();

            toggle();

            setTimeout(async () => {
                createData.reset();
            }, 2500);
        } else {
            errors.showMessages();
        }
    };

    const getDefaultValue = (field, value) => {
        if (!getValues(field)) {
            setValue(field, value.value)
        }
        return value;
    }

    //* FORM SUBMIT HANDLERS
    if (!createData.isLoading && createData.isSuccess) {
        if (createSuccess) createSuccess(createData.data);

        createData.reset();
    }
    //* FORM SUBMIT HANDLERS

    return (
        <Modal size={'lg'} isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {"Criar Conjuge"}
            </ModalHeader>
            <ModalBody>
                <Fragment>
                    <Container fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Form className="theme-form needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <Col md={`6 mb-3`}>
                                            <Label className="col-form-label">{'Nome'}</Label>
                                            <Input className="form-control" type="text" name="name" innerRef={register({ required: true })} />
                                            <span className="form-error">{errors.name && 'Este campo é obrigatório'}</span>
                                        </Col>
                                        <Col md={`6 mb-3`}>
                                            <Label className="col-form-label">{'Tipo'}</Label>
                                            <Controller
                                                name={'type'}
                                                control={control}
                                                // rules={{ required: true }}
                                                render={({ onChange, onBlur, name, ref }) => (
                                                    <Select
                                                        ref={ref}
                                                        placeholder="Selecionar ..."
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        options={[
                                                            {
                                                                value: "individual",
                                                                label: "Individual",
                                                            },
                                                            {
                                                                value: "empresa",
                                                                label: "Empresa",
                                                            }
                                                        ]}
                                                        defaultValue={getDefaultValue('type', {
                                                            value: "individual",
                                                            label: "Individual",
                                                        })}
                                                        name={name}
                                                        onBlur={onBlur}
                                                        onChange={(values) => {
                                                            onChange(values)
                                                            setValue('company', values.value === 'empresa' ? true : false)
                                                            setValue('type', values.value)
                                                            setType(values.value)
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <span className="form-error">{errors.type && 'Este campo é obrigatório'}</span> */}
                                        </Col>
                                        {type === 'individual' ? (
                                            <>
                                                <Col md={`4 mb-3`}>
                                                    <Label className="col-form-label">{'Identificação'}</Label>
                                                    <Controller
                                                        // rules={{ required: true }}
                                                        control={control}
                                                        name={'idType'}
                                                        render={({ onChange, onBlur, name, ref }) => (
                                                            <Select
                                                                ref={ref}
                                                                placeholder="Selecionar ..."
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                options={[
                                                                    {
                                                                        value: "cc",
                                                                        label: "Cartão Cidadão",
                                                                    },
                                                                    {
                                                                        value: "passport",
                                                                        label: "Passaporte",
                                                                    },
                                                                    {
                                                                        value: "bi",
                                                                        label: "Número BI",
                                                                    },
                                                                    {
                                                                        value: "cr",
                                                                        label: "Cartão Residência",
                                                                    },
                                                                ]}
                                                                defaultValue={
                                                                    getDefaultValue('idType', {
                                                                        value: "cc",
                                                                        label: "Cartão Cidadão",
                                                                    })
                                                                }
                                                                name={name}
                                                                onBlur={onBlur}
                                                                onChange={(values) => {
                                                                    onChange(values);
                                                                    setValue('idType', values.value);
                                                                    setIdtype(values.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {/* <span className="form-error">{errors.idType && 'Este campo é obrigatório'}</span> */}
                                                </Col>
                                                <Col md={`4 mb-3`}>
                                                    <Label className="col-form-label">{'Número'}</Label>
                                                    <Input className="form-control" type="text" name="identification.nationalId" innerRef={register({ required: false })} />
                                                </Col>
                                                <Col md={`4 mb-3`}>
                                                    <Label className="col-form-label">{'NIF'}</Label>
                                                    <Input className="form-control" type="text" name="IF" innerRef={register({ required: true })} />
                                                    <span className="form-error">{errors.IF && 'Este campo é obrigatório'}</span>
                                                </Col>
                                                {idType === 'cc' ? (
                                                    <>
                                                        {/* ==== CARTÃO DE CIDADÃO ==== */}
                                                        {/* <Col md={`6 mb-3`}>
                                                        <Label className="col-form-label">{'NIF'}</Label>
                                                        <Input className="form-control" type="text" name="IF" innerRef={register({ required: true })} />
                                                        <span className="form-error">{errors.IF && 'Este campo é obrigatório'}</span>
                                                    </Col> */}
                                                        {/* <Col md={`6 mb-3`} /> */}
                                                        <Col md={`4 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Validade'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.validity'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.validity', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.validity', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.validity && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`4 mb-3`}>
                                                            <Label className="col-form-label">{'Emitido Por'}</Label>
                                                            <Input className="form-control" type="text" name="identification.IssuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                            <span className="form-error">{errors.identification && errors.identification.IssuedBy && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        {/* =//==//= */}
                                                    </>

                                                ) : idType === 'passport' ? (
                                                    <>
                                                        {/* Passaporte */}
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.issuedOn'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.issuedOn', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.issuedOn', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.issuedOn && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Validade'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.validity'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.validity', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.validity', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.validity && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Emitido Por'}</Label>
                                                            <Input className="form-control" type="text" name="identification.IssuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                            <span className="form-error">{errors.identification && errors.identification.IssuedBy && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        {/* =//==//= */}
                                                    </>
                                                ) : idType === 'bi' ? (
                                                    <>
                                                        {/* BI */}
                                                        <Col md={`4 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.issuedOn'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.issuedOn', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.issuedOn', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.issuedOn && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`4 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Validade'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.validity'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.validity', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.validity', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.validity && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        {/* =//==//= */}
                                                    </>
                                                ) : idType === 'cr' && (
                                                    <>
                                                        {/* Cartão de Residência */}
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Emissão'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.issuedOn'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.issuedOn', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.issuedOn', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.issuedOn && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Data de Validade'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'passport.validity'}
                                                                defaultValue=""
                                                                render={({ onChange, onBlur, name, value }) => {
                                                                    return (
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            locale="pt"
                                                                            utcOffset={1}
                                                                            autoComplete="off"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="form-control digits"
                                                                            selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                            onCalendarOpen={() => setValue('passport.validity', "")}
                                                                            name={name}
                                                                            onBlur={onBlur}
                                                                            onChange={(date) => {
                                                                                if (date) {
                                                                                    onChange(moment(date));
                                                                                    setValue('passport.validity', moment(date));
                                                                                }
                                                                            }}
                                                                        />
                                                                    )

                                                                }}
                                                            />
                                                            <span className="form-error">{errors.passport && errors.passport.validity && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        <Col md={`3 mb-3`}>
                                                            <Label className="col-form-label">{'Emitido Por'}</Label>
                                                            <Input className="form-control" type="text" name="identification.IssuedBy" defaultValue="Portugal" innerRef={register({ required: false })} />
                                                            <span className="form-error">{errors.identification && errors.identification.IssuedBy && 'Este campo é obrigatório'}</span>
                                                        </Col>
                                                        {/* =//==//= */}
                                                    </>
                                                )}
                                                <Col md={`3 mb-3`}>
                                                    <Label className="col-form-label">{'Sexo'}</Label>
                                                    <Controller
                                                        // rules={{ required: false }}
                                                        control={control}
                                                        name={'gender'}
                                                        render={({ onChange, onBlur, name, ref }) => (
                                                            <Select
                                                                ref={ref}
                                                                placeholder="Selecionar ..."
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                options={[
                                                                    {
                                                                        value: "M",
                                                                        label: "Masculino",
                                                                    },
                                                                    {
                                                                        value: "F",
                                                                        label: "Feminino",
                                                                    }
                                                                ]}
                                                                defaultValue={
                                                                    getDefaultValue('gender', {
                                                                        value: "M",
                                                                        label: "Masculino",
                                                                    })
                                                                }
                                                                name={name}
                                                                onBlur={onBlur}
                                                                onChange={(values) => {
                                                                    onChange(values);
                                                                    setValue('gender', values.value);
                                                                    setIdtype(values.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <span className="form-error">{errors.gender && 'Este campo é obrigatório'}</span>
                                                </Col>
                                                <Col md={`4 mb-3`}>
                                                    <Label className="col-form-label">{'Data de Nascimento'}</Label>
                                                    <Controller
                                                        rules={{ required: false }}
                                                        control={control}
                                                        name={'birthDate'}
                                                        defaultValue=""
                                                        render={({ onChange, onBlur, name, value }) => {
                                                            return (
                                                                <DatePicker
                                                                    showYearDropdown
                                                                    locale="pt"
                                                                    utcOffset={1}
                                                                    autoComplete="off"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    className="form-control digits"
                                                                    selected={value !== null && value !== "" ? moment(value)._d : value ? value : ""}
                                                                    onCalendarOpen={() => setValue('birthDate', "")}
                                                                    name={name}
                                                                    onBlur={onBlur}
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            onChange(moment(date));
                                                                            setValue('birthDate', moment(date));
                                                                        }
                                                                    }}
                                                                />
                                                            )

                                                        }}
                                                    />
                                                    <span className="form-error">{errors.birthdate && 'Este campo é obrigatório'}</span>
                                                </Col>
                                                <Col md={`4 mb-3`}>
                                                    <Label className="col-form-label">{'Local de Nascimento'}</Label>
                                                    <Input className="form-control" type="text" name="placeOfBirth" innerRef={register({ required: false })} />
                                                    <span className="form-error">{errors.placeOfBirth && 'Este campo é obrigatório'}</span>
                                                </Col>
                                                <Col md={`12 mb-3`}>
                                                    <hr className={'mb-4'} />
                                                </Col>
                                                {/* <Col md={`6 mb-3`}>
                                                    <Label className="col-form-label">{'Estado Civil'}</Label>
                                                    <Controller
                                                        control={control}
                                                        name={'maritalStatus.status'}
                                                        render={({ onBlur, name, ref }) => (
                                                            <Select
                                                                ref={ref}
                                                                placeholder="Selecionar ..."
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                options={[
                                                                    {
                                                                        value: "single",
                                                                        label: "Solteiro",
                                                                    },
                                                                    {
                                                                        value: "married",
                                                                        label: "Casado",
                                                                    },
                                                                    {
                                                                        value: "widow",
                                                                        label: "Viúvo",
                                                                    },
                                                                ]}
                                                                defaultValue={
                                                                    getDefaultValue('maritalStatus.status', {
                                                                        value: "single",
                                                                        label: "Solteiro",
                                                                    })
                                                                }
                                                                name={name}
                                                                onBlur={onBlur}
                                                                onChange={(values) => {
                                                                    setValue('maritalStatus.status', values.value);
                                                                    setMaritalStatus(values.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col> */}
                                                {/* <Col md={`6 mb-3`} /> */}
                                                {(maritalStatus === 'married' || maritalStatus === 'widow') && (
                                                    <>
                                                        {/* <Col md={`6 mb-3`}>
                                                            <Label className="col-form-label">{'Regime de Bens'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'maritalStatus.assets'}
                                                                render={({ onBlur, name, ref }) => (
                                                                    <Select
                                                                        ref={ref}
                                                                        placeholder="Selecionar ..."
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        options={[
                                                                            {
                                                                                value: "acquired",
                                                                                label: "Comunhão de Adquiridos",
                                                                            },
                                                                            {
                                                                                value: "general",
                                                                                label: "Comunhão Geral de Bens",
                                                                            },
                                                                            {
                                                                                value: "separated",
                                                                                label: "Separação de bens",
                                                                            },
                                                                            {
                                                                                value: "unionOfFact",
                                                                                label: "União de Facto",
                                                                            },
                                                                        ]}
                                                                        defaultValue={
                                                                            getDefaultValue('type', {
                                                                                value: "acquired",
                                                                                label: "Comunhão de Adquiridos",
                                                                            })
                                                                        }
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(values) => {
                                                                            setValue('maritalStatus.assets', values.value);
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <span className="form-error">{errors.maritalStatus && errors.maritalStatus.assets && 'Este campo é obrigatório'}</span>
                                                        </Col> */}
                                                        {/* <Col md={`6 mb-3`}>
                                                            <Label className="col-form-label">{'Conjuge'}</Label>
                                                            <Controller
                                                                rules={{ required: false }}
                                                                control={control}
                                                                name={'maritalStatus.spouse'}
                                                                render={({ onBlur, name, ref }) => (
                                                                    <Select
                                                                        ref={ref}
                                                                        placeholder="Selecionar ..."
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        isClearable
                                                                        isSearchable
                                                                        options={clients}
                                                                        name={name}
                                                                        onBlur={onBlur}
                                                                        onChange={(values) => {
                                                                            if (values && values.value) {
                                                                                setValue('maritalStatus.spouse', values.value);
                                                                            } else {
                                                                                setValue('maritalStatus.spouse', null);
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(opt) => opt['name']}
                                                                        getOptionValue={(opt) => opt['_id']}
                                                                    />
                                                                )}
                                                            />
                                                            <span className="form-error">{errors.maritalStatus && errors.maritalStatus.spouse && 'Este campo é obrigatório'}</span>
                                                        </Col> */}
                                                    </>
                                                )}
                                            </>
                                        ) : type === 'empresa' && (
                                            <>
                                                <Col md={`6 mb-3`}>
                                                    <Label className="col-form-label">{'NIPC / NIF'}</Label>
                                                    <Input className="form-control" type="text" name="nipc" innerRef={register({ required: false })} />
                                                    <span className="form-error">{errors.nipc && 'Este campo é obrigatório'}</span>
                                                </Col>
                                            </>
                                        )}
                                        <Col md={`12 mb-3`}>
                                            <h6>Morada e Contactos</h6>
                                        </Col>
                                        <Col md={`12 mb-3`}>
                                            <Label className="col-form-label">{'Morada / Sede'}</Label>
                                            <Input className="form-control" type="text" name="address" innerRef={register({ required: false })} />
                                            <span className="form-error">{errors.address && 'Este campo é obrigatório'}</span>
                                        </Col>
                                        <Col md={`6 mb-3`}>
                                            <Label className="col-form-label">{'Email'}</Label>
                                            <Input className="form-control" type="text" name="contacts.email" innerRef={register({ required: false })} />
                                            <span className="form-error">{errors.contacts && errors.contacts.email && 'Este campo é obrigatório'}</span>
                                        </Col>
                                        <Col md={`6 mb-3`}>
                                            <Label className="col-form-label">{'Telefone'}</Label>
                                            <Input className="form-control" type="text" name="contacts.phone" innerRef={register({ required: false })} />
                                            <span className="form-error">{errors.contacts && errors.contacts.phone && 'Este campo é obrigatório'}</span>
                                        </Col>
                                        <Col md={`12 mb-3`}>
                                            <Label className="col-form-label">{'Outros Contactos / Informações'}</Label>
                                            <Input className="form-control" type="text" name="contacts.other" innerRef={register({ required: false })} />
                                            <span className="form-error">{errors.contacts && errors.contacts.other && 'Este campo é obrigatório'}</span>
                                        </Col>
                                    </div>
                                    <div className="button-wrapper mt-4">
                                        <Button color="primary" type="submit" className="mr-1" disabled={createData.isLoading || createData.isError}>
                                            {createData.isLoading ? <i className="fa fa-spinner fa-spin" /> : createData.isError ? "Erro, tente mais tarde!" : createData.isSuccess ? "Criado" : Submit}
                                        </Button>
                                        <Button color="secondary" onClick={() => closeForm()}>{Cancel}</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
            </ModalBody>
        </Modal>
    )
}

export default ClientModal