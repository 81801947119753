export const apiUrl = "/api/";
export const rqModel = "seguros";
export const pathname = `${process.env.PUBLIC_URL}/patrimonio`;

export const seguroModalForm = [
  {
    colSize: "6",
    label: "Seguradora",
    name: "insurer",
    id: "insurer",
    type: "text",
    required: true,
    requiredMessage: "Este campo é obrigatório",
  },
  {
    colSize: "6",
    label: "Número da Apólice",
    name: "number",
    id: "number",
    type: "text",
  },
  {
    colSize: "6",
    label: "Ticket",
    name: "ticket",
    id: "ticket",
    type: "select",
    multi: false,
    optionLabel: "subject",
    optionValue: "id",
    options: null,
    api: {
      route: "/api/v2/tickets",
      redux: null,
      type: "freshdesk",
    },
  },
  {
    colSize: "6",
    label: "Valor",
    name: "annualValue",
    id: "annualValue",
    type: "number",
    step: 0.01,
  },
  {
    colSize: "6",
    label: "Emitido Em",
    name: "emissionDate",
    id: "emissionDate",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    showYears: true,
  },
  {
    colSize: "6",
    label: "Renova Em",
    name: "renewalDate",
    id: "renewalDate",
    type: "datepicker",
    required: true,
    requiredMessage: "Este campo é obrigatório",
    showYears: true,
  },
];
